import { Theme, Typography } from '@mui/material';
import { InventoryBatchTypeEnum } from 'api/resources';
import React from 'react';

export type UpdateAmountCellProps = {
  type: InventoryBatchTypeEnum;
  value?: number;
  isFinal?: boolean;
};

export const UpdateAmountCell: React.FC<UpdateAmountCellProps> = ({ type, value, isFinal }) => {
  if (value === undefined) {
    return null;
  }

  const isPositive = type !== InventoryBatchTypeEnum.overstockPicking && value >= 0;
  const isZero = value === 0;
  const isColorDisabled = !isFinal;

  const colorFunc: ((theme: Theme) => string | undefined) = (theme) => {
    if (isColorDisabled) {
      return theme.palette.text.disabled;
    }

    if (isZero) {
      return undefined;
    }

    return theme.palette[isPositive ? 'success' : 'error'].main;
  };

  return (
    <Typography sx={{ color: colorFunc }}>
      {!isZero && (isPositive ? '+' : '-')}{Math.abs(value)}
    </Typography>
  );
};
