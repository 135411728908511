import { ProductProductionStockQuantityUpdateLogTypeEnum, ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { EnumHelpers } from './types';

const stockQuantityUpdateLogType: EnumHelpers<ProductStockQuantityUpdateLogTypeEnum> = {
  enumValues: [
    ProductStockQuantityUpdateLogTypeEnum.set,
    ProductStockQuantityUpdateLogTypeEnum.unary,
    ProductStockQuantityUpdateLogTypeEnum.damaged,
    ProductStockQuantityUpdateLogTypeEnum.missing,
    ProductStockQuantityUpdateLogTypeEnum.transfer,
    ProductStockQuantityUpdateLogTypeEnum.sale,
    ProductStockQuantityUpdateLogTypeEnum.deleteSale,
    ProductStockQuantityUpdateLogTypeEnum.saleProductChangeTransfer,
    ProductStockQuantityUpdateLogTypeEnum.eventTeamChangeTransfer,
    ProductStockQuantityUpdateLogTypeEnum.productionBatched,
  ],
  getLabel: value => {
    switch (value) {
      case ProductStockQuantityUpdateLogTypeEnum.set:
        return 'Set';
      case ProductStockQuantityUpdateLogTypeEnum.unary:
        return 'Unary';
      case ProductStockQuantityUpdateLogTypeEnum.damaged:
        return 'Damaged';
      case ProductStockQuantityUpdateLogTypeEnum.missing:
        return 'Missing';
      case ProductStockQuantityUpdateLogTypeEnum.transfer:
        return 'Transfer';
      case ProductStockQuantityUpdateLogTypeEnum.sale:
        return 'Sale';
      case ProductStockQuantityUpdateLogTypeEnum.deleteSale:
        return 'Delete Sale';
      case ProductStockQuantityUpdateLogTypeEnum.saleProductChangeTransfer:
        return 'Sale Product Change Transfer';
      case ProductStockQuantityUpdateLogTypeEnum.eventTeamChangeTransfer:
        return 'Event Team Change Transfer';
      case ProductStockQuantityUpdateLogTypeEnum.productionBatched:
        return 'Production Batched';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown',
};

const productionStockQuantityUpdateLogType: EnumHelpers<ProductProductionStockQuantityUpdateLogTypeEnum> = {
  enumValues: [
    ProductProductionStockQuantityUpdateLogTypeEnum.set,
    ProductProductionStockQuantityUpdateLogTypeEnum.unary,
    ProductProductionStockQuantityUpdateLogTypeEnum.production,
    ProductProductionStockQuantityUpdateLogTypeEnum.batched,
    ProductProductionStockQuantityUpdateLogTypeEnum.damaged,
    ProductProductionStockQuantityUpdateLogTypeEnum.missing,
  ],
  getLabel: value => {
    switch (value) {
      case ProductProductionStockQuantityUpdateLogTypeEnum.set:
        return 'Set';
      case ProductProductionStockQuantityUpdateLogTypeEnum.unary:
        return 'Unary';
      case ProductProductionStockQuantityUpdateLogTypeEnum.production:
        return 'Production';
      case ProductProductionStockQuantityUpdateLogTypeEnum.batched:
        return 'Batched';
      case ProductProductionStockQuantityUpdateLogTypeEnum.damaged:
        return 'Damaged';
      case ProductProductionStockQuantityUpdateLogTypeEnum.missing:
        return 'Missing';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown',
};

export const productStockUpdateLogEnumHelpers = {
  stockQuantityUpdateLogType,
  productionStockQuantityUpdateLogType
};