import { Box, Typography } from '@mui/material';
import { Team } from 'api/resources';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';

export type TeamCellProps = {
  team: Pick<Team, 'color' | 'name'> | null;
  secondary?: string | null;
  label?: string;
};

export const TeamCell: React.FC<TeamCellProps> = ({ team, secondary, label }) => {
  if (!team) {
    return <Typography component="i" variant="body2" color="text.secondary">Unassigned</Typography>;
  }

  return (
    <WrapWithStatusIndicator status={team.color}>
      <Box display="flex" alignItems="center" gap={0.5}>
        {label ?? team.name}
        {secondary && <Typography variant="caption" color="text.secondary"> ({secondary})</Typography>}
      </Box>
    </WrapWithStatusIndicator>
  );
};