import { DatesStatusChip, StaffStatusChip } from 'components';
import { Box, Button, Card, Chip, Divider, Typography } from '@mui/material';
import { getEventDatesStaffList, getEventDatesStatus, getEventStaffStatus, userEnumHelpers } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { EventDatesList, EventDatesPreviewCalendars, EventDatesTimelines, EventSectionCardRowEditable } from '../components';
import { useEventContext } from '../event.context';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const DatesSection: React.FC = () => {
  const navigate = useNavigate();
  const { event } = useEventContext();

  return (
    <Box id={EventPageSection.DATES} mt={5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6" fontWeight={400}>Dates / Staff</Typography>
        <Box display="flex" gap={1}>
          <Button size="small" variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.eventScheduling.createLink({ eventId: event._id }))}>Manage Scheduling</Button>
          <Button size="small" variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.eventDates.createLink({ eventId: event._id }))}>Manage Dates</Button>
        </Box>
      </Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        <Box flex={1} maxWidth="800px">
          <Card variant="outlined" sx={{ borderRadius: 0, '& > [role="row"]:not(:last-of-type)': { borderBottom: theme => `1px solid ${theme.palette.divider}` } }}>
            <Box display="flex">
              <Box flex={1} borderRight={theme => `1px solid ${theme.palette.divider}`}>
                <EventSectionCardRowEditable title="Dates Status" rowId={EventPageSectionRowId.DATES_STATUS} form={null} formikProps={{ initialValues: {}, onSubmit: () => {} }} disableEditable>
                  <DatesStatusChip value={getEventDatesStatus(event.dates)} />
                </EventSectionCardRowEditable>
                <Divider />
                <EventSectionCardRowEditable title="Staff Status" rowId={EventPageSectionRowId.STAFF_STATUS} form={null} formikProps={{ initialValues: {}, onSubmit: () => {} }} disableEditable>
                  <StaffStatusChip value={getEventStaffStatus(event.dates)} />
                </EventSectionCardRowEditable>
                <Divider />
                <EventDatesList
                  event={event}
                  onEventDateClick={(eventDateId) => navigate(DYNAMIC_ROUTES.eventDate.createLink({ eventId: event._id, eventDateId }))}
                />
                <Divider />
              </Box>
              {!!event.dates.length && (
                <Box p={2} display={{ xs: 'none', md: 'flex' }} flexDirection="column" gap={2} flex={0}>
                  <EventDatesPreviewCalendars />
                  {event.dates.some(d => !!d.staff.length) && (
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
                      <Typography variant="body2">All Scheduled Staff</Typography>
                      <Box display="flex" gap={0.5} flexWrap="wrap" justifyContent="center">
                        {getEventDatesStaffList(event.dates).map((staff) => {
                          return (
                            <Box key={staff._id} display="flex">
                              <Chip
                                size="small"
                                color={userEnumHelpers.staffStatus.getColor(staff.status)}
                                variant="outlined"
                                label={staff.user.name}
                                sx={{ fontWeight: 500, borderRadius: '4px' }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Box minHeight={300} display={{ xs: 'none', md: 'block' }}>
          <EventDatesTimelines />
        </Box>
      </Box>
    </Box>
  );
};