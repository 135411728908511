import { CloseOutlined, OpenInNew } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Typography, lighten } from '@mui/material';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventsResponse, getEvent, updateEvent } from 'api/actions';
import { ApplicationSection, AssignmentsSection, DatesSection, EditableImage, EventContextProvider, EventPageSection, EventSchedulingTable, FilesSection, InsuranceSection, JumpNav, LinksSection, LocationSection, LodgingSection, OverviewSection, PaymentsSection, PhotosSection, PlaceDistanceAndDurationContent, PreviewDrawer, SalesReport, SectionCard, SectionCardRow, SectionCardsEditContextProvider, StickyStackItem, ToDosSection, WrapWithStatusIndicator, eventPageSectionEnumHelpers, getInitialAnalyticsTimelineChartTimePeriodForDateRange, useAlertSnackbar } from 'components';
import { CALENDAR_FULL_SCREEN_MODAL_ZINDEX } from 'constants/full-screen';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { QUERY_KEY } from 'queries/query-keys';
import React, { createContext, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateService } from 'services';
import { EventDaysSummary } from './EventDaysSummary.component';
import { PlaceDefaultEnum } from 'api/resources';
import { useEventTeamChangeOptions } from 'queries';

type EventPreviewType = 'main' | 'scheduling' | 'teamScheduling';

type EventPreviewDrawerContextType = {
  openEventId: string | null;
  setOpenEvent: (eventId: string, variant: EventPreviewType) => void;
};

const EventPreviewDrawerContext = createContext<EventPreviewDrawerContextType>({
  openEventId: null,
  setOpenEvent: () => {},
});

export const useEventPreviewDrawerContext = () => useContext(EventPreviewDrawerContext);

export type EventPreviewContainerProps = {
  children: React.ReactNode;
  events: GetEventsResponse['data'];
  loading?: boolean;
  invalidateQueriesHandler: (queryClient: QueryClient, eventId: string | null) => void | Promise<void>;
  userId?: string;
};

export const EventPreviewContainer: React.FC<EventPreviewContainerProps> = ({ children, events, invalidateQueriesHandler, userId }) => {
  const [ openEventId, setOpenEventId ] = useState<string | null>(null);
  const [ variant, setVariant ] = useState<EventPreviewType>('main');

  const setOpenEvent = (eventId: string, variant: EventPreviewType) => {
    setOpenEventId(eventId);
    setVariant(variant);
  };

  const event = events.find(event => event._id === openEventId)!;

  return (
    <Box width={openEventId ? { xs: '100%', md: '50%', lg: '60%' } : '100%'} pr={openEventId ? 2 : 0}>
      <EventPreviewDrawer
        event={event}
        userId={userId}
        variant={variant}
        onClose={() => setOpenEventId(null)}
        invalidateQueriesHandler={(queryClient) => invalidateQueriesHandler(queryClient, event._id)}
      />

      <EventPreviewDrawerContext.Provider value={{ setOpenEvent, openEventId }}>
        {children}
      </EventPreviewDrawerContext.Provider>
    </Box>
  );
};

export type EventPreviewDrawerProps = {
  event: GetEventsResponse['data'][number] | null;
  invalidateQueriesHandler: (queryClient: QueryClient) => void | Promise<void>;
  onClose: () => void;
  variant?: EventPreviewType;
  userId?: string;
};

export const EventPreviewDrawer: React.FC<EventPreviewDrawerProps> = ({ event, variant, ...props }) => {
  if (!event || !variant) {
    return null;
  }

  return (
    <PreviewDrawer open={!!event}>
      {{
        'main': <EventPreviewDrawerMain {...props} event={event} />,
        'scheduling': <EventPreviewDrawerScheduling {...props} event={event} />,
        'teamScheduling': <EventPreviewDrawerTeamScheduling {...props} event={event} />,
      }[variant]}
    </PreviewDrawer>
  );
};

type EventPreviewDrawerMainProps = Pick<EventPreviewDrawerProps, 'invalidateQueriesHandler' | 'onClose'> & {
  event: GetEventsResponse['data'][number];
};

const EventPreviewDrawerMain: React.FC<EventPreviewDrawerMainProps> = ({ event, invalidateQueriesHandler, onClose }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  return (
    <EventContextProvider event={event} loading={false} invalidateQueriesHandler={invalidateQueriesHandler}>
      {context => {
        const getBadgeContent = (sectionId: EventPageSection) => context.warningItems.find(warningItem => warningItem.section === sectionId)?.reasonItems.length;

        const tabs = eventPageSectionEnumHelpers.enumValues.map(sectionId => ({
          label: eventPageSectionEnumHelpers.getLabel(sectionId),
          sectionId,
          badgeContent: getBadgeContent(sectionId),
          hidden: eventPageSectionEnumHelpers.getIsHidden(sectionId, event, currentUser),
        }));

        return (
          <Box bgcolor={theme => lighten(theme.palette.background.default, 0.5)}>
            <StickyStackItem order={0} placement="top">
              <Box zIndex={CALENDAR_FULL_SCREEN_MODAL_ZINDEX + 100} bgcolor={theme => theme.palette.background.paper}>
                <EventPreviewDrawerHeader
                  event={event}
                  onClose={onClose}
                  actions={(
                    <>
                      <Button onClick={() => navigate(DYNAMIC_ROUTES.eventDates.createLink({ eventId: event._id }))}>Dates</Button>
                      <Button onClick={() => navigate(DYNAMIC_ROUTES.eventScheduling.createLink({ eventId: event._id }))}>Scheduling</Button>
                      <Button onClick={() => navigate(DYNAMIC_ROUTES.eventSales.createLink({ eventId: event._id }))}>Sales</Button>
                      <Button onClick={() => navigate(DYNAMIC_ROUTES.eventFeedback.createLink({ eventId: event._id }))}>Feedback</Button>
                    </>
                  )}
                  tabs={(
                    <JumpNav
                      initialValue={EventPageSection.OVERVIEW}
                      offsetTop={156}
                      tabs={tabs}
                    />
                  )}
                />
              </Box>
            </StickyStackItem>
            <SectionCardsEditContextProvider>
              <Box display="flex" flexDirection="column" gap={5} mx={2} my={5}>
                <OverviewSection />
                <AssignmentsSection />
                <DatesSection />
                <ToDosSection />
                <Box mx={-2} bgcolor="background.paper">
                  <Box bgcolor="primary.background" id={EventPageSection.SALES} mx={-2}>
                    <Divider />
                    <Box py={5} px={2} mx={2}>
                      <SalesReport eventId={event._id} timePeriod={getInitialAnalyticsTimelineChartTimePeriodForDateRange({ start: DateService.dayjsTz(event.startDate?.dateAsUtc), end: DateService.dayjsTz(event.endDate?.dateAsUtc) })} />
                    </Box>
                    <Divider />
                  </Box>
                </Box>
                <PhotosSection />
                <ApplicationSection />
                <LocationSection />
                <LodgingSection />
                <PaymentsSection />
                <InsuranceSection />
                <LinksSection />
                <FilesSection />
              </Box>
            </SectionCardsEditContextProvider>
          </Box>
        );
      }}
    </EventContextProvider>
  );
};

type EventPreviewDrawerSchedulingProps = Pick<EventPreviewDrawerProps, 'invalidateQueriesHandler' | 'onClose' | 'userId'> & {
  event: GetEventsResponse['data'][number];
};

const EventPreviewDrawerScheduling: React.FC<EventPreviewDrawerSchedulingProps> = ({ event, invalidateQueriesHandler, onClose, userId }) => {
  const containerRef = useRef<HTMLElement>(null);
  const scheduligInvalidateQueriesHandler = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));

    const newEvent = await queryClient.fetchQuery(QUERY_KEY.EVENT(event._id), async () => {
      const response = await getEvent(event._id);

      return response.data;
    });

    queryClient.setQueryData<GetEventsResponse['data']>(QUERY_KEY.EVENTS, (events) => {
      return events?.map(_event =>
        _event._id === newEvent._id ? newEvent : _event
      );
    });
  };

  return (
    <EventContextProvider event={event} loading={false} invalidateQueriesHandler={invalidateQueriesHandler}>
      <StickyStackItem order={0} placement="top">
        <Box zIndex={CALENDAR_FULL_SCREEN_MODAL_ZINDEX + 100} bgcolor={theme => theme.palette.background.paper}>
          <EventPreviewDrawerHeader event={event} onClose={onClose} routeTo={DYNAMIC_ROUTES.eventScheduling.createLink({ eventId: event._id })} />
        </Box>
      </StickyStackItem>
      <Box ref={containerRef} mx={1}>
        <EventSchedulingTable invalidateQueriesHandler={scheduligInvalidateQueriesHandler} userId={userId} containerRef={containerRef} />
      </Box>
    </EventContextProvider>
  );
};

type EventPreviewDrawerTeamSchedulingProps = Pick<EventPreviewDrawerProps, 'invalidateQueriesHandler' | 'onClose' | 'userId'> & {
  event: GetEventsResponse['data'][number];
};

const EventPreviewDrawerTeamScheduling: React.FC<EventPreviewDrawerTeamSchedulingProps> = ({ event, invalidateQueriesHandler, onClose }) => {
  const { data: teamChangeOptions = [] } = useEventTeamChangeOptions(event._id);
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateTeamMutation = useMutation({
    mutationFn: async (value: string | null) => {
      return await updateEvent(event._id, { team: value });
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      snackbar.success('Event team updated');
    },
    onError: () => {
      snackbar.error('Unable to update event team');
    }
  });

  const onTeamChange = (value: string | null) => {
    if ((!value && !event.team) || event.team?._id === value) {
      return;
    }

    updateTeamMutation.mutate(value);
  };

  return (
    <EventContextProvider event={event} loading={false} invalidateQueriesHandler={invalidateQueriesHandler}>
      <StickyStackItem order={0} placement="top">
        <Box zIndex={CALENDAR_FULL_SCREEN_MODAL_ZINDEX + 100} bgcolor={theme => theme.palette.background.paper}>
          <EventPreviewDrawerHeader event={event} onClose={onClose} />
        </Box>
      </StickyStackItem>
      <Box display="flex" flexDirection="column" gap={5} mx={2} my={5}>
        <SectionCard title="">
          <SectionCardRow title="Space Size">{event.spaceSize}</SectionCardRow>
          <SectionCardRow title="Days Count"><EventDaysSummary event={event} /></SectionCardRow>
          <SectionCardRow title="Distance & Duration">
            <PlaceDistanceAndDurationContent
              fromLabel="Studio"
              toLabel="Event"
              routeDetails={{ fromAddress: PlaceDefaultEnum.studio, toAddress: event.place.address }}
              placeMatrix={event.place.fromStudioMatrix}
            />
          </SectionCardRow>
        </SectionCard>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography color="text.secondary">Assign team</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <ToggleButtonGroup
              value={event.team?._id}
              onChange={(_, value) => value && onTeamChange(value)}
              exclusive
              size="small"
              disabled={updateTeamMutation.isLoading}
            >
              {teamChangeOptions.map(option => {
                if (option.isWarehouse) {
                  return null;
                }

                return (
                  <Tooltip
                    title={option.eventDateConflicts?.map((eventDate) => {
                      return (
                        <Typography key={eventDate.eventDateId} variant="body2">
                          <Typography component="span" color="error" variant="inherit">CONFLICT:</Typography> {eventDate.eventName} - {DateService.getFormattedDate(eventDate.eventDate)}
                        </Typography>
                      );
                    })}
                    key={option.id}
                  >
                    <Box>
                      <ToggleButton value={option.id} disabled={!!option.eventDateConflicts}>
                        <WrapWithStatusIndicator status={option.color}>
                          {option.name}
                        </WrapWithStatusIndicator>
                      </ToggleButton>
                    </Box>
                  </Tooltip>
                );
              })}
            </ToggleButtonGroup>
            <Typography variant="body2" color="text.secondary">or</Typography>
            <ToggleButton
              value="bank"
              disabled={updateTeamMutation.isLoading}
              onClick={() => onTeamChange(null)}
              size="small"
            >
              Move to bank
            </ToggleButton>
          </Box>
        </Box>
      </Box>
    </EventContextProvider>
  );
};

type EventPreviewDrawerHeaderProps = {
  event: GetEventsResponse['data'][number];
  onClose: () => void;
  actions?: React.ReactNode;
  tabs?: React.ReactNode;
  routeTo?: string;
};

const EventPreviewDrawerHeader: React.FC<EventPreviewDrawerHeaderProps> = ({ event, actions, tabs, onClose, routeTo }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" mx={2} py={1} gap={2} alignItems="flex-start">
        <Box sx={{ position: 'absolute', top: 8, right: 16 }}>
          <IconButton onClick={() => navigate(routeTo ?? DYNAMIC_ROUTES.event.createLink({ eventId: event._id }))}>
            <OpenInNew />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <EditableImage
          width="90px"
          height="90px"
          onUpload={()=>{}}
          imageUrl={event.iconUrl}
          modalTitle=""
          disabledEditable
        />
        <Box width="100%">
          <Typography variant="h6" mt={1}>{event.name}&nbsp;<Typography component="span" fontSize="large">{event.year}</Typography></Typography>
          <Typography component="span">
            <WrapWithStatusIndicator status={event.team?.color}>{event.team?.name}
              {event.teamManager && ', '}
              {event.teamManager && <Typography mt={0.3} component="span" variant="body2" color="text.secondary">managed by</Typography>}
              {event.teamManager?.name}
            </WrapWithStatusIndicator>
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            {actions}
          </Box>
        </Box>
      </Box>
      {tabs && (
        <>
          <Box mx={2}><Divider /></Box>
          {tabs}
          <Divider />
        </>
      )}
    </>
  );
};