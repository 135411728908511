import React, { useEffect, useMemo, useState } from 'react';
import { ColorCodeAction } from './ColorCodeAction.component';
import { ColorCodeConfig } from './colorCode';
import { useLocalStorage } from 'hooks';
import { ColorCodeModal } from './ColorCodeModal.modal';
import { FullCalendarSidebarActionProps } from '../FullCalendarSidebarAction.component';

export type UseColorCodeProps<Option extends string, Arg extends unknown> = {
  id: string;
  defaultValue: Option;
  colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>;
  disabledColorCodeOptions?: Option[];
};

export type UseColorCodeOutput<Option extends string> = {
  colorCodeOption: Option | null;
  setColorCodeOption: React.Dispatch<React.SetStateAction<Option | null>>;
  colorCodeModal: React.ReactNode;
  colorCodeSidebarActionConfig: FullCalendarSidebarActionProps;
};

export const useColorCode = <Option extends string, Arg extends unknown>({ id, defaultValue, colorCodeConfigHash, disabledColorCodeOptions }: UseColorCodeProps<Option, Arg>): UseColorCodeOutput<Option> => {
  const colorCodeOptionLocalStorageService = useLocalStorage<Option | null>(`${id}ColorCodeOption`);
  const [ colorCodeOption, setColorCodeOption ] = useState<Option | null>(colorCodeOptionLocalStorageService.getResource(defaultValue));
  const [ showModal, setShowModal ] = useState<boolean>(false);

  const colorCodeModal = useMemo(() => {
    if (!showModal) {
      return null;
    }

    return (
      <ColorCodeModal
        colorCodeOption={colorCodeOption}
        onColorCodeOptionChange={(option) => setColorCodeOption(option)}
        disabledColorCodeOptions={disabledColorCodeOptions}
        colorCodeConfigHash={colorCodeConfigHash}
        onClose={() => setShowModal(false)}
      />
    );
  }, [ colorCodeConfigHash, colorCodeOption, disabledColorCodeOptions, showModal ]);

  const colorCodeSidebarActionConfig = useMemo(() => ({
    label: 'Color Code',
    content: <ColorCodeAction colorCodeOption={colorCodeOption} colorCodeConfigHash={colorCodeConfigHash} />,
    onClickMore: () => setShowModal(true)
  }), [ colorCodeConfigHash, colorCodeOption ]);

  useEffect(() => {
    return () =>  colorCodeOptionLocalStorageService.setResource(colorCodeOption);
  }, [ colorCodeOption, colorCodeOptionLocalStorageService ]);

  return {
    colorCodeOption,
    setColorCodeOption,
    colorCodeModal,
    colorCodeSidebarActionConfig,
  };
};