import { CancelOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridPreProcessEditCellProps, GridRowModes, GridRowModesModel } from '@mui/x-data-grid';
import { TEventsTableEditableRow, UnknownEnum } from 'types';
import {
  AcceptanceStatusCell,
  ApplicationPlatformCell,
  ApplicationStatusCell,
  AutocompleteCell,
  ParticipationStatusCell,
  SelectCell,
  TextFieldCell
} from '../Cells';
import { mixed, number, SchemaOf, string } from 'yup';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper } from 'helpers';
import { EventAcceptanceStatusEnum, EventApplicationPlatformEnum, EventApplicationStatusEnum, EventParticipationStatusEnum } from 'api/resources';


const spaceSizeOptions = [ '10x10', '10x15', '10x20', '20x20', '8x8', '8x16', 'Unknown' ];

const validateAndProcessCellProps = <T extends unknown>(
  schema: SchemaOf<T>,
  params: GridPreProcessEditCellProps<T>,
) => {
  let error = '';

  try {
    schema.validateSync(params.props.value);
  } catch (validationError: any) {
    error = validationError.message;
  }

  return {
    ...params.props,
    error: error,
  };
};

export const getEventsTableEditableColumns = (rowModesModel: GridRowModesModel, setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>): GridColDef<TEventsTableEditableRow>[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'eventTimeframe',
    headerName: 'Event Daterange',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'attendance',
    headerName: 'Attendance',
    flex: 1,
    editable: true,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
    renderEditCell: (params) => <TextFieldCell type="number" {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(number().min(0).max(1000000).default(0), params),
  },
  {
    field: 'spaceSize',
    headerName: 'Space Size',
    flex: 1,
    editable: true,
    type: 'string',
    minWidth: 200,
    renderEditCell: (params) => <AutocompleteCell options={spaceSizeOptions} {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(string().default(''), params),
  },
  {
    field: 'participationStatus',
    headerName: 'Participation Status',
    width: 220,
    editable: true,
    renderCell: ParticipationStatusCell,
    renderEditCell: (params) => <SelectCell options={getSelectOptionsFromEnumHelper(eventEnumHelpers.participationStatus)} {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(mixed<EventParticipationStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.participationStatus.enumValues).required(), params),
  },
  {
    field: 'applicationStatus',
    headerName: 'Application Status',
    width: 220,
    editable: true,
    renderCell: ApplicationStatusCell,
    renderEditCell: (params) => <SelectCell options={getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationStatus)} {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(mixed<EventApplicationStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.applicationStatus.enumValues).required(), params),
  },
  {
    field: 'acceptanceStatus',
    headerName: 'Acceptance Status',
    width: 220,
    editable: true,
    renderCell: AcceptanceStatusCell,
    renderEditCell: (params) => <SelectCell options={getSelectOptionsFromEnumHelper(eventEnumHelpers.acceptanceStatus)} {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(mixed<EventAcceptanceStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.acceptanceStatus.enumValues).required(), params),
  },
  {
    field: 'applicationPlatform',
    headerName: 'Application Platform',
    width: 220,
    editable: true,
    renderCell: ApplicationPlatformCell,
    renderEditCell: (params) => <SelectCell options={getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationPlatform)} {...params} />,
    preProcessEditCellProps: params => validateAndProcessCellProps(mixed<EventApplicationPlatformEnum | UnknownEnum>().oneOf(eventEnumHelpers.applicationPlatform.enumValues).required(), params),
  },
  // {
  //   field: 'applicationOpenTimeframe',
  //   headerName: 'App. Open Timeframe',
  //   flex: 1,
  //   minWidth: 270,
  //   resizable: false,
  //   editable: true,
  //   valueFormatter: ({ value }: GridValueFormatterParams<DateRange<string>>) => `${value[0] ? dayjs(value[0]).format('MM/DD/YYYY') : 'Unknown'} - ${value[1] ? dayjs(value[1]).format('MM/DD/YYYY') : 'Unknown'}`,
  //   renderEditCell: (params) => <DateRangePickerCell {...params} />,
  //   preProcessEditCellProps: params => validateAndProcessCellProps(object({
  //     applicationDeadlineDate: string().nullable().default(null),
  //     applicationOpenDate: string().nullable().default(null),
  //   }), params),
  // },

  //   {
  //     field: 'juryFee',
  //     headerName: 'Jury Fee',
  //     flex: 1,
  //     minWidth: 100,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'juryFeeAmount',
  //     headerName: 'Jury Fee $',
  //     type: 'number',
  //     editable: true,
  //     flex: 1,
  //     minWidth: 100,
  //   },
  //   {
  //     field: 'juryFeePaid',
  //     headerName: 'Is Jury fee paid?',
  //     flex: 1,
  //     editable: true,
  //     minWidth: 130,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'juryFeePaymentType',
  //     headerName: 'Jury fee payment',
  //     flex: 1,
  //     minWidth: 150,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: paymentTypeValues,
  //     valueFormatter: ({ value }) => paymentTypeValueToLabelHash[value as PaymentTypeEnum],
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(paymentTypeValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'lodgingRequired',
  //     headerName: 'Lodging Required',
  //     flex: 1,
  //     minWidth: 130,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'bookingStatus',
  //     headerName: 'Booking Status',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: lodgingStatusValues,
  //     renderCell: LodgingBookingStatusCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(bookingStatusValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceRequired',
  //     headerName: 'Insurance Required',
  //     flex: 1,
  //     minWidth: 150,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceStatus',
  //     headerName: 'Insurance Status',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: insuranceStatusValues,
  //     renderCell: InsuranceStatusCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(insuranceStatusValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceShared',
  //     headerName: 'Insurance Shared',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: insuranceSharedValues,
  //     renderCell: InsuranceSharedCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(insuranceSharedValueToLabelHash)} {...params} />)
  //   },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 100,
    cellClassName: 'actions',
    renderCell: ({ id }) => {
      if (rowModesModel[id]?.mode === GridRowModes.Edit) {
        return (
          <Box display="flex">
            <GridActionsCellItem
              icon={<SaveOutlined />}
              label="Save"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))}
              sx={{
                color: 'primary.main',
              }}
            />
            <GridActionsCellItem
              icon={<CancelOutlined />}
              label="Cancel"
              className="textPrimary"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }))}
              color="inherit"
            />
          </Box>
        );
      } else {
        return (
          <GridActionsCellItem
            icon={<EditOutlined />}
            label="Edit"
            className="textPrimary"
            onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))}
            color="inherit"
          />
        );
      }
    }
  }
];