import {
  Button, Grid, Typography,
} from '@mui/material';
import { FormFooter, CardContentContainer, useAlertSnackbar, PageContentContainer, PageContentResourceHeader,
} from 'components';
import { Formik } from 'formik';
import { ResourceForm, UnknownEnum } from 'types';
import { productNewValidationSchema } from './validation';
import { ProductBasicInfoForm } from './forms/ProductBasicInfo.form';
import { ProductAvailabilityForm } from './forms/ProductAvailability.form';
import { ProductPreperationForm } from './forms/ProductPreperation.form';
import { useMutation } from '@tanstack/react-query';
import { createProduct } from 'api/actions';
import { useNavigate } from 'react-router-dom';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

const initialValues: ResourceForm['product'] = {
  name: '',
  sku: '',
  price: 40,
  category: '12x12',
  notes: '',
  availability: {
    events: UnknownEnum.unknown,
    website: UnknownEnum.unknown,
    uploaded: UnknownEnum.unknown,
  },
  preperation: {
    rasterLayer: UnknownEnum.unknown,
    centerLabel: UnknownEnum.unknown,
    png: UnknownEnum.unknown,
    dmc: UnknownEnum.unknown,
    dwg: UnknownEnum.unknown,
    divider: UnknownEnum.unknown,
    websitePngs: UnknownEnum.unknown,
  },
  genres: [],
};

export const ProductNew = () => {
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const createMutation = useMutation({
    mutationFn: async (form: ResourceForm['product']) => {
      return createProduct({
        name: form.name.trim(),
        sku: form.sku.trim(),
        price: form.price,
        category: form.category.trim(),
        notes: form.notes.trim() || undefined,
        needsAttention: false,
        availability: {
          events: yesNoEnumHelpers.yesNo.getValue(form.availability.events),
          website: yesNoEnumHelpers.yesNo.getValue(form.availability.website),
          uploaded: yesNoEnumHelpers.yesNo.getValue(form.availability.uploaded),
        },
        preperation: {
          rasterLayer: yesNoEnumHelpers.yesNo.getValue(form.preperation.rasterLayer),
          centerLabel: yesNoEnumHelpers.yesNo.getValue(form.preperation.centerLabel),
          png: yesNoEnumHelpers.yesNo.getValue(form.preperation.png),
          dmc: yesNoEnumHelpers.yesNo.getValue(form.preperation.dmc),
          dwg: yesNoEnumHelpers.yesNo.getValue(form.preperation.dwg),
          divider: yesNoEnumHelpers.yesNo.getValue(form.preperation.divider),
          websitePngs: yesNoEnumHelpers.yesNo.getValue(form.preperation.websitePngs),
        },
        genres: [],
      });
    },
    onSuccess: async (response) => {
      navigate(DYNAMIC_ROUTES.product.createLink({ productId: response.data._id }));
      snackbar.success('Product created');
    }
  });

  const handleCancel = () => {
    navigate(ROUTING_CONFIG.productList);
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.productList, text: 'Product List' } ],
        current: 'New Product',
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="New Product Page"
          title="Create a new product"
        />
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => createMutation.mutateAsync(values)}
        validationSchema={productNewValidationSchema}
        validateOnMount
      >
        {(formik) => {
          return (
            <>
              <CardContentContainer>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Basic Information</Typography>
                    <ProductBasicInfoForm />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Availability</Typography>
                    <ProductAvailabilityForm />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Preperation</Typography>
                    <ProductPreperationForm />
                  </Grid>
                </Grid>
              </CardContentContainer>
              <FormFooter>
                <Button
                  onClick={handleCancel}
                  size="large"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={formik.handleSubmit as () => void}
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Create Product
                </Button>
              </FormFooter>
            </>
          );
        }}
      </Formik>
    </PageContentContainer>
  );
};
