import { Box, Button, Card, Divider, Grid, ListItemButton, Skeleton, Typography } from '@mui/material';
import { CardContentContainer, InventoryBatchesTable, StickyStackItem } from 'components';
import { useInventoryBatches, useProducts, useTeams } from 'queries';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardPageSection } from '../types';
import { ProductionAnalyticsTimelineChart } from 'components/AnalyticsChart/custom/ProductionAnalyticsTimelineChart.component';
import { DateService } from 'services';
import { InventoryBatchStatusEnum } from 'api/resources';
import { getProductTotalProduceAmount } from 'helpers';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';

export const ProductionOverview = () => {
  const navigate = useNavigate();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: products = [], isInitialLoading: productsLoading } = useProducts();
  const { data: inventoryBatches = [], isInitialLoading: inventoryBatchesLoading } = useInventoryBatches();
  const productsWithProduceAmount = products.map(product => ({ ...product, toProduce: getProductTotalProduceAmount(teams.filter(team => !team.pauseInventory), product) }));
  const productionList = [ ...productsWithProduceAmount ]
    .filter(product => product.toProduce)
    .sort((a, b) => {
      if (a.salesPerEventDate !== b.salesPerEventDate) {
        return b.salesPerEventDate - a.salesPerEventDate;
      }

      return b.toProduce - a.toProduce;
    });
  const totalProduceAmount = productsWithProduceAmount.reduce((r, product) => r + product.toProduce, 0);
  const productsNeedProduction = productsWithProduceAmount.filter(product => product.toProduce).length;
  const totalOpenInventoryBatches = inventoryBatches.filter(batch => batch.status === InventoryBatchStatusEnum.open).length;
  const totalReviewInventoryBatches = inventoryBatches.filter(batch => batch.status === InventoryBatchStatusEnum.review).length;

  const productionOverviewDiplay = useMemo(() => {
    if(teamsLoading || productsLoading) {
      return <Skeleton variant="rectangular" height={460} />;
    }

    return (
      <Box m={3}>
        <Box display="flex" gap={7}>
          <Box>
            <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{totalProduceAmount} <Typography mb={0.1}>Pieces</Typography></Typography>
            <Typography variant="body2" color="text.secondary">Total to produce</Typography>
          </Box>
          <Box>
            <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{productsNeedProduction} <Typography mb={0.1}>Designs</Typography></Typography>
            <Typography variant="body2" color="text.secondary">Need production (at least 1 piece)</Typography>
          </Box>
        </Box>
        <Box my={3}  mx={-3} bgcolor="primary.background">
          <Divider />
          <Box my={3} mx={2}>
            <Typography variant="h6" fontWeight={400} color="primary.dark">NEXT 3</Typography>
            <Typography variant="body2" color="text.secondary">Designs to run</Typography>
            <Box display="flex" justifyContent="space-between" gap={1} mt={2}>
              {productionList.slice(0, 3).map(product => {
                return (
                  <Box
                    sx={{
                      flex: 1,
                      backgroundColor: 'primary.background',
                      border: '1px solid',
                      borderColor: 'primary.main',
                      borderRadius: 1,
                    }}
                    key={product._id}
                  >
                    <ListItemButton onClick={() => navigate(DYNAMIC_ROUTES.product.createLink({ productId: product._id }))}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          py: 1,
                        }}
                      >
                        <Typography variant="h5" color="primary.main" fontWeight={500}>
                          <Typography variant="caption" component="span" color="text.secondary">produce</Typography>
                          &nbsp;{product.toProduce}&nbsp;
                          <Typography variant="caption" component="span" color="text.secondary">total</Typography>
                        </Typography>
                        <Typography variant="body2">{product.name}</Typography>
                      </Box>
                    </ListItemButton>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box display="flex" gap={1} mb={2}>
          <Button variant="outlined" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.productionSchedule)}>Go to Production Page</Button>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.inventoryBatches)}>Go to Inventory Batches Page</Button>
        </Box>
      </Box>
    );
  }, [ navigate, productionList, productsLoading, productsNeedProduction, teamsLoading, totalProduceAmount ]);

  return (
    <Box id={DashboardPageSection.PRODUCTION}>
      <StickyStackItem placement="top" order={1}>
        <Box zIndex={2}>
          <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
            <Typography variant="h6" my={1}>Production</Typography>
          </Box>
          <Box mx={-4}><Divider /></Box>
        </Box>
      </StickyStackItem>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} lg={6}>
          <Card variant="outlined" sx={{ borderRadius: 0 }}>
            {productionOverviewDiplay}
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardContentContainer sx={{ borderRadius: 0, height: 410 }}>
            <Box display="flex" gap={7} mb={4}>
              <Box>
                <Typography fontWeight={500} variant="h5">{totalOpenInventoryBatches}</Typography>
                <Typography variant="body2" color="text.secondary">Batch{totalOpenInventoryBatches === 1 ? '' : 'es'} open</Typography>
              </Box>
              <Box>
                <Typography fontWeight={500} variant="h5">{totalReviewInventoryBatches}</Typography>
                <Typography variant="body2" color="text.secondary">Batch{totalReviewInventoryBatches === 1 ? '' : 'es'} need review</Typography>
              </Box>
            </Box>
            <Box mx={-2} height={310}>
              <InventoryBatchesTable teams={teams} inventoryBatches={inventoryBatches} loading={teamsLoading || inventoryBatchesLoading} />
            </Box>
          </CardContentContainer>
        </Grid>
        <Grid item xs={12}>
          <ProductionAnalyticsTimelineChart dateRange={{ start: DateService.dayjs().subtract(2, 'weeks'), end: DateService.dayjs() }}/>
        </Grid>
      </Grid>
    </Box>
  );
};
