import { RefreshOutlined, Save } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { EditModeTogglerProps } from './EditModeToggler.component';
import { TableActionsBar } from 'components/TableActionsBar';
import { useState } from 'react';
import { AreYouSureModal } from 'components/Modal';

export type EditModeActionsBarProps = {
  editModeToggler?: React.ReactElement<EditModeTogglerProps>;
  actions?: React.ReactNode;
} & EditModeSaveAndResetButtonsProps;

export const EditModeActionsBar: React.FC<EditModeActionsBarProps> = ({ editModeToggler, actions, ...props }) => {

  return (
    <TableActionsBar
      editModeToggler={editModeToggler}
      leftActions={actions}
      rightActions={<EditModeSaveAndResetButtons {...props} />}
    />
  );
};

export type EditModeSaveAndResetButtonsProps = {
  resetDisabled: boolean;
  saveDisabled: boolean;
  onReset: () => void;
  onSaveChanges: () => void;
  hideSaveButton?: boolean;
  hideResetButton?: boolean;
};

export const EditModeSaveAndResetButtons: React.FC<EditModeActionsBarProps> = props => {
  const [ resetModalOpen, setResetModalOpen ] = useState(false);

  const onConfirmResetEdits = () => {
    setResetModalOpen(false);
    props.onReset();
  };

  return (
    <Box display="flex" alignItems="center" gap={2} whiteSpace="nowrap">
      {resetModalOpen && (
        <AreYouSureModal
          onClose={() => setResetModalOpen(false)}
          onConfirm={onConfirmResetEdits}
          description="Are you sure you want to reset all edits?"
          title="Reset Edits"
          confirmButtonText="Confirm Reset"
          confirmButtonColor="warning"
        />
      )}
      {!props.hideResetButton && (
        <Button
          variant="outlined"
          color="warning"
          onClick={() => setResetModalOpen(true)}
          disabled={props.resetDisabled}
          endIcon={<RefreshOutlined fontSize="small" />}
        >
          Reset Edits
        </Button>
      )}
      {!props.hideSaveButton && (
        <Button
          variant="contained"
          color="success"
          onClick={() => props.onSaveChanges()}
          disabled={props.saveDisabled}
          endIcon={<Save fontSize="small" />}
        >
          Save Edits
        </Button>
      )}
    </Box>
  );
};