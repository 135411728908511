import { GetInventoryBatchResponse } from 'api/actions';
import { Loading } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { ProductsPageContextProvider } from 'contexts';
import { useInventoryBatch } from 'queries';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

type UseInventoryBatchOutletContext = {
  inventoryBatch: GetInventoryBatchResponse['data'];
};

export const useInventoryBatchOutletContext = () => useOutletContext<UseInventoryBatchOutletContext>();

const InventoryBatchBaseRaw: React.FC<{inventoryBatchId: string}> = ({ inventoryBatchId }) => {
  const { data: inventoryBatch, isInitialLoading: loading } = useInventoryBatch(inventoryBatchId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!inventoryBatch) {
    return <Navigate to={ROUTING_CONFIG.inventoryBatches} replace />;
  }

  return <Outlet context={{ inventoryBatch }} />;
};

export const InventoryBatchBase = () => {
  const { inventoryBatchId } = DYNAMIC_ROUTES.inventoryBatch.useParams();

  if (!inventoryBatchId) {
    return <Navigate to={ROUTING_CONFIG.inventoryBatches} replace />;
  }

  return (
    <ProductsPageContextProvider waitForTeams waitForProducts>
      <InventoryBatchBaseRaw inventoryBatchId={inventoryBatchId} />
    </ProductsPageContextProvider>
  );
};
