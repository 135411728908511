import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { TransferLabel } from '../TransferLabel.component';
import { GetTeamsResponse } from 'api/actions';
import { InventoryTableTransferConfig, StandardInventoryTableFilters } from '../hooks';

export type InventoryTableStandardFiltersInputProps = {
  displayFilters: StandardInventoryTableFilters[];
  disableFilters?: StandardInventoryTableFilters[];
  transferConfig: InventoryTableTransferConfig | null;
  teams:  GetTeamsResponse['data'];
  standardFilters: StandardInventoryTableFilters[];
  setStandardFilters: (standardFilters: StandardInventoryTableFilters[]) => void;
  disabled?: boolean;
};

export const InventoryTableStandardFiltersInput: React.FC<InventoryTableStandardFiltersInputProps> = props => {
  const onToggleFilter = useCallback((filter: StandardInventoryTableFilters) => {
    const prev = props.standardFilters;

    const filterExists = prev.some(_filter => _filter === filter);

    if (filterExists) {
      props.setStandardFilters(prev.filter(_filter => _filter !== filter));
      return;
    }

    props.setStandardFilters([ ...prev, filter ]);
  }, [ props ]);

  const transferFilters = useMemo(() => {
    const transferConfig = props.transferConfig;

    if (!transferConfig) {
      return null;
    }

    const inTeam = props.teams.find(team => team._id === transferConfig.in);
    const outTeam = props.teams.find(team => team._id === transferConfig.out);

    return (
      <>
        {props.displayFilters.includes('showNoOutStock') && (
          <FormControlLabel
            label={<Box display="flex" alignItems="center">Show products that have no stock in <TransferLabel isOut team={outTeam} /></Box>}
            control={(
              <Checkbox
                checked={props.standardFilters.includes('showNoOutStock')}
                onChange={()=> onToggleFilter('showNoOutStock')}
                disabled={props.disableFilters?.includes('showNoOutStock') || props.disabled}
              />
            )}
          />
        )}
        {props.displayFilters.includes('showInNotNeeded') && (
          <FormControlLabel
            label={<Box display="flex" alignItems="center">Show products that dont need stock in <TransferLabel isIn team={inTeam} /></Box>}
            control={(
              <Checkbox
                checked={props.standardFilters.includes('showInNotNeeded')}
                onChange={()=> onToggleFilter('showInNotNeeded')}
                disabled={props.disableFilters?.includes('showInNotNeeded') || props.disabled}
              />
            )}
          />
        )}
        {props.displayFilters.includes('showOutNotOverstocked') && (
          <FormControlLabel
            label={<Box display="flex" alignItems="center">Show products that are not overstocked in <TransferLabel isOut team={outTeam} /></Box>}
            control={(
              <Checkbox
                checked={props.standardFilters.includes('showOutNotOverstocked')}
                onChange={()=> onToggleFilter('showOutNotOverstocked')}
                disabled={props.disableFilters?.includes('showOutNotOverstocked') || props.disabled}
              />
            )}
          />
        )}
      </>
    );
  }, [ onToggleFilter, props.disableFilters, props.disabled, props.displayFilters, props.standardFilters, props.teams, props.transferConfig ]);

  return (
    <Box display="flex" flexDirection="column">
      {props.displayFilters.includes('onlyShowWithProduction') && (
        <FormControlLabel
          label={<Box display="flex" alignItems="center">Only show products with items in production</Box>}
          control={(
            <Checkbox
              checked={props.standardFilters.includes('onlyShowWithProduction')}
              onChange={()=> onToggleFilter('onlyShowWithProduction')}
              disabled={props.disableFilters?.includes('onlyShowWithProduction') || props.disabled}
            />
          )}
        />
      )}
      {props.displayFilters.includes('showNotAvailableAtEvents') && (
        <FormControlLabel
          label="Show products not available at events"
          control={(
            <Checkbox
              checked={props.standardFilters.includes('showNotAvailableAtEvents')}
              onChange={()=> onToggleFilter('showNotAvailableAtEvents')}
              disabled={props.disableFilters?.includes('showNotAvailableAtEvents') || props.disabled}
            />
          )}
        />
      )}
      {props.displayFilters.includes('onlyShowEdits') && (
        <FormControlLabel
          label="Only show products with edits"
          control={(
            <Checkbox
              checked={props.standardFilters.includes('onlyShowEdits')}
              onChange={()=> onToggleFilter('onlyShowEdits')}
              disabled={props.disableFilters?.includes('onlyShowEdits') || props.disabled}
            />
          )}
        />
      )}
      {transferFilters}
    </Box>
  );
};