import { ProductProductionStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { GetProductsProductionStockUpdateLogsResponse } from 'api/actions';

type GetDetailsFunc = (row: GetProductsProductionStockUpdateLogsResponse['data'][number]) => React.ReactNode;

export const getProductionStockUpdateLogDetails: GetDetailsFunc = (row) => {
  const updateAmount = Math.abs(row.newQuantity - row.previousQuantity);

  switch(row.type) {
    case ProductProductionStockQuantityUpdateLogTypeEnum.set:
      return `Production stock set to ${row.newQuantity}`;
    case ProductProductionStockQuantityUpdateLogTypeEnum.unary:
      return `Production stock incremented by ${updateAmount}`;
    case ProductProductionStockQuantityUpdateLogTypeEnum.batched:
      return `Production stock decremented by ${updateAmount} due to being batched`;
    case ProductProductionStockQuantityUpdateLogTypeEnum.damaged:
      return `Production stock decremented by ${updateAmount} due to damage`;
    case ProductProductionStockQuantityUpdateLogTypeEnum.missing:
      return `Production stock decremented by ${updateAmount} due to missing`;
    case ProductProductionStockQuantityUpdateLogTypeEnum.production:
      return `Production stock incremented by ${updateAmount} due to production`;
    default:
      break;
  }

  return '';
};