import { Autocomplete } from 'components';
import { useUsersAsResourceOptions } from 'queries/user';

export type EventManagerFormProps = {
  autoFocus?: boolean;
  openOnFocus?: boolean;
};
export const EventManagerForm: React.FC<EventManagerFormProps> = ({ autoFocus, openOnFocus }) => {
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();

  return (
    <Autocomplete
      name="eventManager"
      label="Event Manager"
      options={users}
      getOptionLabel={(opt) => opt?.name}
      transformValue={(opt) => opt?._id}
      loading={usersLoading}
      autoFocus={autoFocus ?? true}
      openOnFocus={openOnFocus ?? true}
    />
  );
};