import { GetProductResponse } from 'api/actions';
import { Loading } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useProduct } from 'queries';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

type UseProductOutletContext = {
  product: GetProductResponse['data'];
  loading: boolean;
};

export const useProductOutletContext = () => useOutletContext<UseProductOutletContext>();

const ProductBaseRaw: React.FC<{productId: string}> = ({ productId }) => {
  const { data: product, isInitialLoading: loading } = useProduct(productId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!product) {
    return <Navigate to={ROUTING_CONFIG.productList} replace />;
  }

  return <Outlet context={{ product, loading }} />;
};

export const ProductBase = () => {
  const { productId } = DYNAMIC_ROUTES.product.useParams();

  if (!productId) {
    return <Navigate to={ROUTING_CONFIG.productList} replace />;
  }

  return <ProductBaseRaw productId={productId} />;
};
