import { useMemo } from 'react';
import { InventoryEdits, InventoryRow, InventoryTableEditableMode, InventoryTableTransferConfig, InventoryTableUpdateValidator, ValidateInventoryTableSetEditParams, ValidateInventoryTableTransferEditParams, ValidateInventoryTableUnaryEditParams } from './types';
import { getProductTeamStock } from './helpers';
import { UnknownEnum } from 'types';

export type UseInventoryTableApiArgsBase = {
  validateUnaryEdit?: InventoryTableUpdateValidator<ValidateInventoryTableUnaryEditParams>;
  validateSetEdit?: InventoryTableUpdateValidator<ValidateInventoryTableSetEditParams>;
  validateTransferEdit?: InventoryTableUpdateValidator<ValidateInventoryTableTransferEditParams>;
  allowZeroInTransferMode?: boolean;
};
export type UseInventoryTableApiArgs = UseInventoryTableApiArgsBase & {
  setInventoryEdits: React.Dispatch<React.SetStateAction<InventoryEdits>>;
  getProduct: (productId: string) => InventoryRow;
  isSetOperation: boolean;
  isTransferOperation: boolean;
  mode: InventoryTableEditableMode | UnknownEnum;
  transferConfig: InventoryTableTransferConfig | null;
};

type SetEditParams = {
  productId: string;
  teamId: string;
  newValue: string;
};


const defaultValidateUnaryEdit: InventoryTableUpdateValidator<ValidateInventoryTableUnaryEditParams> = () => undefined;
const defaultValidateSetEdit: InventoryTableUpdateValidator<ValidateInventoryTableSetEditParams> = () => undefined;
const defaultValidateTransferEdit: InventoryTableUpdateValidator<ValidateInventoryTableTransferEditParams> = (params) => {
  if (params.outStock.quantity - params.updateQty < 0) {
    return `Cannot transfer more than ${params.outStock.quantity}`;
  }
};

export const useInventoryTableApi = ({
  setInventoryEdits,
  getProduct,
  validateUnaryEdit = defaultValidateUnaryEdit,
  validateSetEdit = defaultValidateSetEdit,
  validateTransferEdit = defaultValidateTransferEdit,
  allowZeroInTransferMode,
  isSetOperation,
  isTransferOperation,
  mode,
  transferConfig,
}: UseInventoryTableApiArgs) => {
  const api = useMemo(() => {
    return {
      removeProductEdit: (productId: string) => {
        setInventoryEdits(prev => {
          const { [productId]: _, ...restOfEdits } = prev;

          return restOfEdits;
        });
      },
      setEdit: (params: SetEditParams) => {
        const { productId, teamId } = params;
        let { newValue } = params;

        if (newValue[0] === '-') {
          newValue = newValue.slice(1);
        }

        let value: number | undefined;

        if (newValue) {
          if (!/^[1-9][0-9]*$/.test(newValue)) {
            if ((isSetOperation || (isTransferOperation && allowZeroInTransferMode)) && newValue.startsWith('0')) {
              if (newValue === '0') {
                value = 0;
              } else {
                value = Number(newValue.slice(-1));
              }
            } else {
              return;
            }
          }

          if (newValue.length > 2) {
            return;
          }

          value = newValue ? Number(newValue) : undefined;
        }

        setInventoryEdits(prev => {
          if (value === undefined) {
            if (!prev[productId]) {
              return prev;
            }

            const { [teamId]: _, ...restOfProductEdits } = prev[productId];

            if (Object.keys(restOfProductEdits).length === 0) {
              const { [productId]: _, ...restOfEdits } = prev;

              return restOfEdits;
            }

            return { ...prev, [productId]: restOfProductEdits };
          }

          if (mode === InventoryTableEditableMode.damaged) {
            value = -value;
          }

          const product = getProduct(productId);
          let error: string | undefined;

          if (transferConfig && isTransferOperation) {
            const inStock = getProductTeamStock(product, transferConfig.in);
            const outStock = getProductTeamStock(product, transferConfig.out);

            error = validateTransferEdit({ inStock, outStock, updateQty: value });
          } else {
            const stock = getProductTeamStock(product, teamId);

            if (isSetOperation) {
              error = validateSetEdit({ stock, updateQty: value });
            } else {
              error = validateUnaryEdit({ stock, updateQty: value });
            }
          }

          return {
            ...prev,
            [productId]: {
              ...prev[productId],
              [teamId]: {
                value,
                error,
              },
            }
          };
        });
      },
    };
  }, [ allowZeroInTransferMode, getProduct, isSetOperation, isTransferOperation, mode, setInventoryEdits, transferConfig, validateSetEdit, validateTransferEdit, validateUnaryEdit ]);

  return api;
};