import { Grid } from '@mui/material';
import React from 'react';
import { TotalSalesChart } from './TotalSalesChart.component';
import { SalesPaymentMethodChart } from './SalesPaymentMethodChart.component';
import { SalesFrameChart } from './SalesFrameChart.component';
import { TeamsSalesChart } from './TeamsSalesChart.component';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { BestSellersChart } from './BestSellersChart.component';

export const SalesAnalytics = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TotalSalesChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TeamsSalesChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          type="events"
          header="Event Sales"
          getNameAndId={sale => ({ name: sale.order.event.name, id: sale.order.event._id })}
          getNavigateTo={(id) => DYNAMIC_ROUTES.event.createLink({ eventId: id })}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          header="Product Sales"
          getNameAndId={sale => ({ name: sale.product.name, id: sale.product._id })}
          getNavigateTo={(id) => DYNAMIC_ROUTES.product.createLink({ productId: id })}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          header="User Logged Sales"
          getNameAndId={sale => sale.order.user ? { name: sale.order.user.name, id: sale.order.user._id } : null}
          getNavigateTo={(id) => DYNAMIC_ROUTES.user.createLink({ userId: id })}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SalesPaymentMethodChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SalesFrameChart />
      </Grid>
    </Grid>
  );
};
