import { ReviewsOutlined } from '@mui/icons-material';
import { Skeleton, Box, Typography, ToggleButtonGroup, ToggleButton, Divider, Button, Card } from '@mui/material';
import { GetEventsForDashboardResponse } from 'api/actions';
import { ScrollableView, EventContent } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateService } from 'services';
import { EVENTS_OVERVIEW_CARD_HEIGHT, EVENTS_OVERVIEW_CARD_WIDTH } from './EventsOverview.component';

export type EventsOverviewFeedbackReminderCardProps = {
  feedbackReminder: GetEventsForDashboardResponse['data']['feedbackReminder'];
  loading?: boolean;
};

type ShowEvents = 'all' | 'complete' | 'incomplete';

export const EventsOverviewFeedbackReminderCard: React.FC<EventsOverviewFeedbackReminderCardProps> = ({ feedbackReminder, loading }) => {
  const navigate = useNavigate();
  const [ showEventsFeedbackReminder, setShowEventsFeedbackReminder ] = useState<ShowEvents>('all');

  const incompleteEventsFeedbackReminder = feedbackReminder.filter(event => event.startDate && !DateService.dayjs().isAfter(DateService.dayjsTz(event.startDate.dateAsUtc), 'day'));
  const completeEventsFeedbackReminder = feedbackReminder.filter(event => event.startDate && DateService.dayjs().isAfter(DateService.dayjsTz(event.startDate.dateAsUtc), 'day'));

  const selectedEventsFeedbackReminder = useMemo(() => {
    switch (showEventsFeedbackReminder) {
      case 'all':
        return feedbackReminder;
      case 'complete':
        return completeEventsFeedbackReminder;
      case 'incomplete':
      default:
        return incompleteEventsFeedbackReminder;
    }
  }, [ completeEventsFeedbackReminder, feedbackReminder, incompleteEventsFeedbackReminder, showEventsFeedbackReminder ]);

  if (loading) {
    return <Skeleton variant="rectangular" height={EVENTS_OVERVIEW_CARD_HEIGHT} sx={{ flex: 1 }} />;
  };

  return (
    <Card variant="outlined" sx={{ flex: 1, borderRadius: 0, minWidth: EVENTS_OVERVIEW_CARD_WIDTH }}>
      <Box m={2}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Box>
            <Typography fontWeight={500} variant="h5">{feedbackReminder.length}</Typography>
            <Typography variant="body2" color="text.secondary" mb={3}>Event{feedbackReminder.length === 1 ? '' : 's'} awaiting your feedback</Typography>
          </Box>
          <ToggleButtonGroup
            color="primary"
            value={showEventsFeedbackReminder}
            orientation="vertical"
            exclusive
            onChange={(_, value) => value && setShowEventsFeedbackReminder(value)}
          >
            <ToggleButton value="all" size="small">All ({feedbackReminder.length})</ToggleButton>
            <ToggleButton value="complete" size="small">Complete ({completeEventsFeedbackReminder.length})</ToggleButton>
            <ToggleButton value="incomplete" size="small">Incomplete ({incompleteEventsFeedbackReminder.length})</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Divider />
        <ScrollableView height="390px" enableScrollShadow>
          {selectedEventsFeedbackReminder.map((event, index) => {
            return (
              <Box key={event._id} mb={3}>
                {!!index && <Divider />}
                <EventContent
                  data={{ event, startDate: event.startDate, endDate: event.endDate }}
                  disableActionsDivider
                  actions={(
                    <Button variant="outlined" endIcon={<ReviewsOutlined />} onClick={() => navigate(DYNAMIC_ROUTES.eventFeedback.createLink({ eventId: event._id }))} fullWidth>Leave Feedback</Button>
                  )}
                />
              </Box>
            );
          })}
        </ScrollableView>
        <Divider />
      </Box>
    </Card>
  );
};