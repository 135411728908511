import queryString from 'query-string';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

type CreateLink<PathParams> = (pathParams: PathParams) => string;

const handleQueryParams = <PathParams, QueryParams>(createLink: CreateLink<PathParams>) => (pathParams: PathParams, queryParams?: QueryParams) => {
  const link = createLink(pathParams);

  return queryParams ? `${link}?${queryString.stringify(queryParams)}` : link;
};

export const useRouterQueryParam = <TKey extends string, TValue extends string>(key: TKey) => {
  const location = useLocation();
  const search = location.search;
  const result = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return searchParams.get(key) as TValue;
  }, [ search, key ]);

  return result;
};

export const createTypedDynamicRoute = <
  PathParams extends Record<string, string>,
  QueryParams extends Record<string, string> = {}
>(createLink: CreateLink<PathParams>) => ({
  createLink: handleQueryParams<PathParams, QueryParams>(createLink),
  template: () => {
    return createLink(new Proxy({} as PathParams, {
      get(_, prop: string) {
        return ':' + prop;
      }
    }));
  },
  useParams: () => useParams<PathParams>(),
  useQueryParam: <TKey extends Extract<keyof QueryParams, string>>(key: TKey) => {
    const location = useLocation();
    const search = location.search;
    const result = React.useMemo(() => {
      const searchParams = new URLSearchParams(search);

      return searchParams.get(key) as QueryParams[TKey];
    }, [ search, key ]);

    return result;
  },
  useAllQueryParams: () => {
    const location = useLocation();
    const search = location.search;
    const result: QueryParams = React.useMemo(() => {
      const searchParams = new URLSearchParams(search);
      const kv: Record<string, string> = {};

      searchParams.forEach((value, key) => {
        kv[key] = value;
      });

      return kv as QueryParams;
    }, [ search ]);

    return result;
  }
});