import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';
import { GridColDef, GridColumnGroup, GridColumnVisibilityModel, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import { ProductStock, UserEmployeeRoleEnum } from 'api/resources';
import { FilterButton, Table, TableActionsBar, EditSettingsModal, TeamSelectionInput, ShowNotAtEventsInput, TeamCell } from 'components';
import flatten from 'lodash/flatten';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useEffect, useMemo, useState } from 'react';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { getProductTotalProduceAmount, getProductProduceAmount, productSalesCountColumns, getNonPauseInventoryTeams } from 'helpers';
import { useTableActionColumn } from 'hooks';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { LogRunDrawer } from './LogRunDrawer.component';

const enum PerTeamColumnKeys {
  baseline = 'baseline',
  inStock = 'inStock',
  produce = 'produce',
}

type PerTeamColumnGetters = {
  field: string;
  getValue: (product: GetProductsResponse['data'][number], team: GetTeamsResponse['data'][number], stock: ProductStock) => string | number;
};

const perTeamColumnsHash: Record<PerTeamColumnKeys, PerTeamColumnGetters> = {
  [PerTeamColumnKeys.baseline]: { field: 'Baseline', getValue: (product, team) => team.isWarehouse ? product.activeWarehouseBaseline : product.activeBaseline },
  [PerTeamColumnKeys.inStock]: { field: 'Stock', getValue: (_, __, stock) => stock.quantity },
  [PerTeamColumnKeys.produce]: { field: 'Produce', getValue: (product, team) => getProductProduceAmount(product, team) },
};

const perTeamColumns= Object.values(perTeamColumnsHash);

const useProductionTableColumns = (selectedTeams: GetTeamsResponse['data'], setLogRunProduct: React.Dispatch<React.SetStateAction<string | null>>): GridColDef[] => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const inventoryColumns: GridColDef[] = flatten(selectedTeams.map((team) => {
    return perTeamColumns.map(({ field, getValue }): GridColDef => {
      return {
        field: `${team._id}-${field}`, // arbitrary
        headerName: field,
        flex: 1,
        width: 60,
        type: 'number',
        valueGetter: (params: GridValueGetterParams<GetProductsResponse['data'][number]>) => getValue(params.row, team, params.row.stocks[team._id]),
        editable: false,
        // hide production column from wholesale managers
        hideable: field !== perTeamColumnsHash.produce.field || isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
      };
    });
  }));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      hideable: false,
    }
  ];

  if (isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ])) {
    columns.push(...productSalesCountColumns);
  } else {
    columns.push(...productSalesCountColumns.map(column => ({ ...column, hideable: false })));
  }

  columns.push(
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: ({ value }) => new Date(value),
      type: 'date',
      width: 150,
    },
    ...inventoryColumns,
    {
      field: 'productionQuantity',
      headerName: 'In production',
      width: 110,
      align: 'center',
      valueGetter: (params: GridValueGetterParams<GetProductsResponse['data'][number]>) => {
        return selectedTeams
          .filter(team => team.isWarehouse)
          .reduce((total, team) => {
            const stock = params.row.stocks[team._id];

            return total + stock.productionQuantity;
          }, 0);
      },
    },
    {
      field: 'total',
      headerName: 'Produce',
      width: 112,
      hideable: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]),
      align: 'center',
      valueGetter: (params: GridValueGetterParams<GetProductsResponse['data'][number]>) => {
        return getProductTotalProduceAmount(selectedTeams, params.row);
      },
      renderCell: ({ value }) => {
        return <Typography sx={{ textDecoration: 'underline' }} fontWeight={500}>{value}</Typography>;
      }
    },
    {
      field: 'actions',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: '',
      width: 90,
      renderCell: ({ row }) => {
        return  (
          <Button size="small" variant="contained" onClick={() => setLogRunProduct(row._id)}>
            Log Run
          </Button>
        );
      }
    },
  );

  return columns;
};

export const ProductionTable: React.FC = () => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const { products, teams, loading } = useProductsPageContext();
  const [ selectedPerTeamColumnKeys, setSelectedPerTeamColumnKeys ] = useState<PerTeamColumnKeys[]>([ PerTeamColumnKeys.produce ]);
  const [ selectedTeams, setSelectedTeams ] = useState<GetTeamsResponse['data']>(getNonPauseInventoryTeams(teams));
  const [ showMetBaseline, setShowMetBaseline ] = useState<boolean>(!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]));
  const [ showProductsNotAvailableAtEvents, setShowProductsNotAvailableAtEvents ] = useState<boolean>(!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]));
  const [ settingsOpen, setSettingsOpen ] = useState(false);
  const [ logRunProductId, setLogRunProductId ] = useState<string | null>(null);

  const warehouseTeam = teams.find(team => team.isWarehouse)!;
  const logRunProduct = useMemo(() => products.find(product => product._id === logRunProductId) ?? null, [ logRunProductId, products ]);

  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });
  const productionTableColumnsRaw = useProductionTableColumns(selectedTeams, setLogRunProductId);
  const productionTableColumns = withActionColumn(productionTableColumnsRaw);

  const rows = products.filter((product) => {
    if (!showMetBaseline) {
      const metBaseline = getProductTotalProduceAmount(selectedTeams, product) === 0;

      if (metBaseline) {
        return false;
      }
    }

    if (!showProductsNotAvailableAtEvents && !product.availability?.events) {
      return false;
    }

    return true;
  });

  const columnGroupingModel = useMemo((): GridColumnGroup[] => {
    return selectedTeams.map((team) => {
      const children = perTeamColumns.map(({ field }) => ({ field: `${team._id}-${field}` }));

      return {
        groupId: team._id,
        renderHeaderGroup: () => <TeamCell team={team} />,
        headerAlign: 'center',
        children
      };
    });
  }, [ selectedTeams ]);

  const [ columnVisibilityModel, setColumnVisibilityModel ] = useState<GridColumnVisibilityModel>({
    salesCount: isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ]) ? true : false,
    prevSixMonthSalesCount: isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ]) ? true : false,
    total: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
    ...selectedTeams.reduce((r, team) => ({
      ...r,
      [`${team._id}-${perTeamColumnsHash.produce.field}`]: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
    }), {}),
    createdAt: false,
  });

  useEffect(() => {
    setColumnVisibilityModel(prev => ({
      ...prev,
      ...selectedTeams.reduce((r, team) => ({
        ...r,
        ...Object.keys(perTeamColumnsHash).reduce((r1, key) => ({
          ...r1,
          [`${team._id}-${perTeamColumnsHash[key as PerTeamColumnKeys].field}`]: selectedPerTeamColumnKeys.includes(key as PerTeamColumnKeys),
        }), {}),
      }), {}),
    }));
  }, [ selectedPerTeamColumnKeys, selectedTeams ]);

  return (
    <>
      <LogRunDrawer stock={logRunProduct?.stocks[warehouseTeam._id]} selectedTeams={selectedTeams} product={logRunProduct} onClose={() => setLogRunProductId(null)} />
      <EditSettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        title="Production Schedule Filters"
        teamSelectionInput={<TeamSelectionInput selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} teams={teams} />}
        showNotAtEventsInput={<ShowNotAtEventsInput showProductsNotAvailableAtEvents={showProductsNotAvailableAtEvents} setShowProductsNotAvailableAtEvents={setShowProductsNotAvailableAtEvents} />}
        otherFilters={(
          <>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setShowMetBaseline((prev) => !prev)}
                  checked={showMetBaseline}
                />
              }
              label="Show products that met the baseline for all teams"
            />
            <FormControl component="fieldset" variant="standard">
              <FormLabel>Displayed Stock Data Columns</FormLabel>
              <FormGroup row>
                {Object.keys(perTeamColumnsHash).map((key) => (
                  <FormControlLabel
                    key={key}
                    control={(
                      <Checkbox
                        checked={selectedPerTeamColumnKeys.includes(key as PerTeamColumnKeys)}
                        onChange={() => setSelectedPerTeamColumnKeys((prev) => (prev.includes(key as PerTeamColumnKeys) ? prev.filter((k) => k !== key) : [ ...prev, key as PerTeamColumnKeys ]))}
                      />
                    )}
                    label={perTeamColumnsHash[key as PerTeamColumnKeys].field}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </>
        )}
      />
      <TableActionsBar
        leftActions={(
          <FilterButton
            onClick={() => setSettingsOpen(true)}
            disabled={!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ])}
          />
        )}
      />
      <Table
        rows={rows}
        columns={productionTableColumns}
        loading={loading}
        getRowId={(x) => x._id}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        initialState={{
          pinnedColumns: { right: [ 'productionQuantity', 'total', 'actions' ] },
          sorting: {
            sortModel: [ { field: 'salesPerEventDate', sort: 'desc' }, { field: 'total', sort: 'desc' } ],
          },
        }}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        getCellClassName={(params) => {
          return params.row[params.field]?.type === PerTeamColumnKeys.produce ? 'borderRight' : '';
        }}
        getRowClassName={(params: GridRowClassNameParams<GetProductsResponse['data'][number]> ) => {
          const index = params.indexRelativeToCurrentPage;

          return index % 2 ? 'selected-darker' : '';
        }}
      />
    </>
  );
};