import { useInventoryBatchOutletContext } from './InventoryBatch.base';
import { InventoryBatchStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { Open } from './steps/1_Open.component';
import { useCurrentUser } from 'contexts';
import { BatchOverview } from './BatchOverview.component';
import { Review } from './steps/2_Review.component';

export const InventoryBatch: React.FC = () => {
  const { getIsMe, isAuthorizedEmployee } = useCurrentUser();
  const { inventoryBatch } = useInventoryBatchOutletContext();

  switch (inventoryBatch.status) {
    case InventoryBatchStatusEnum.open:
      if (!getIsMe(inventoryBatch.user._id) && !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ])) {
        break;
      }

      return <Open />;
    case InventoryBatchStatusEnum.review:
      if (getIsMe(inventoryBatch.user._id) && !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ])) {
        break;
      }

      return <Review />;

    case InventoryBatchStatusEnum.managerReview:
      if (!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ])) {
        break;
      }

      return <Review isManagerReview />;
  }

  return <BatchOverview />;
};