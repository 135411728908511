import { OrderPaymentEnum } from 'api/resources';
import { FrameEnum, StrictForm } from 'types';

export const enum OrderPageSection {
  OVERVIEW = 'overview',
  CUSTOMER = 'customer',
}

export const enum OrderPageSectionRowId {
  PAYMENT = 'payment',
  SALES = 'sales',

  CUSTOMER_FIRST_NAME = 'first_name',
  CUSTOMER_LAST_NAME = 'last_name',
  CUSTOMER_ADDRESS = 'address',
  CUSTOMER_CITY = 'city',
  CUSTOMER_STATE = 'state',
  CUSTOMER_ZIP = 'zip',
  CUSTOMER_PHONE = 'phone',
  CUSTOMER_EMAIL = 'email',
}

export type OrderSectionRowForm = {
  [OrderPageSectionRowId.PAYMENT]: StrictForm<{
    payment: OrderPaymentEnum;
  }>;

  [OrderPageSectionRowId.SALES]: StrictForm<{
    salePrice: number;
    frame: FrameEnum;
  }>;

  [OrderPageSectionRowId.CUSTOMER_FIRST_NAME]: StrictForm<{
    firstName: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_LAST_NAME]: StrictForm<{
    lastName: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_ADDRESS]: StrictForm<{
    address: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_CITY]: StrictForm<{
    city: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_STATE]: StrictForm<{
    state: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_ZIP]: StrictForm<{
    zip: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_PHONE]: StrictForm<{
    phone: string;
  }>;
  [OrderPageSectionRowId.CUSTOMER_EMAIL]: StrictForm<{
    email: string;
  }>;
};
