import React from 'react';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { LoggingPortalResourceTypeEnum } from 'api/actions';
import { Card, Box, Button } from '@mui/material';
import { EventWithEventDateContent } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';

export const LoggingPortalEventDate = () => {
  const { event, eventDate } = useLoggingPortalContext(LoggingPortalResourceTypeEnum.eventDate);
  const navigate = useNavigate();

  return (
    <Card variant="outlined" >
      <EventWithEventDateContent
        key={event._id}
        data={{
          event: event,
          eventDate: eventDate,
          eventDates: event.dates,
          startDate: event.startDate,
          endDate: event.endDate,
        }}
        eventDateProps={{
          hideStaff: true
        }}
        actions={(
          <Box display="flex" justifyContent="flex-end" py={1.5} px={2} gap={1} flexDirection={{ xs: 'column', md: 'row' }}>
            <Button variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.event.createLink({ eventId: event._id }))} sx={{ flex: 1 }}>Go To Event</Button>
            <Button variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.eventDate.createLink({ eventId: event._id, eventDateId: eventDate._id }))} sx={{ flex: 1 }}>Go To Event Date</Button>
          </Box>
        )}
        collapsable
      />
    </Card>
  );
};
