import { useMutation } from '@tanstack/react-query';
import { validateCredentials } from 'api/actions';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { ResourceForm } from 'types';

export type useValidateMutationProps = {
  onConfirm: (userId: string) => void;
};

export const useValidateMutation = (props: useValidateMutationProps) => {
  const alert = useAlertSnackbar();

  return useMutation({
    mutationFn: (form: ResourceForm['validateCredentials']) => {
      if (form.credentialType === 'password') {
        return validateCredentials({ userId: form.userId, password: form.password });
      } else {
        return validateCredentials({ userId: form.userId, pin: form.pin });
      }
    },
    onSuccess: (_, form) => {
      props.onConfirm(form.userId);
    },
    onError: () => {
      alert.error('Invalid Credentials. Please, try again.');
    }
  });
};