import { ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import React from 'react';
import { AnalyticsTimelineChart } from 'components';
import { useAnalyticsTimelineChartConfigWithCompare } from 'pages/Analytics/hooks';

export const DamagedMissingProductsChart = () => {
  const { dateRange, updateLogs, updateLogsLoading } = useAnalyticsPageContext();
  const totalDamagedAndMissing= updateLogs
    .filter(updateLog => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.damaged || updateLog.type === ProductStockQuantityUpdateLogTypeEnum.missing)
    .reduce((r, updateLog) => r + updateLog.previousQuantity - updateLog.newQuantity, 0);

  const { config } = useAnalyticsTimelineChartConfigWithCompare({
    type: 'updateLogs',
    getValue:  (updateLog) => updateLog.previousQuantity - updateLog.newQuantity,
    datasetsConfigs: [
      { filter: (updateLog) => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.damaged, label: 'Damaged' },
      { filter: (updateLog) => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.missing, label: 'Missing' }
    ]
  });

  return (
    <AnalyticsTimelineChart
      config={config}
      header="Damaged / Missing"
      subheader={totalDamagedAndMissing.toString()}
      dateRange={dateRange}
      desiredTrend="desc"
      loading={updateLogsLoading}
    />
  );
};
