import { Autocomplete, CardContentLink, RadioGroup, SectionCard, SectionCardRow, Select, TextInput } from 'components';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper, processFormValueUpdate } from 'helpers';
import { updateEvent } from 'api/actions';
import { mixed, number, object, string } from 'yup';
import { UnknownEnum, YesNoEnum, yesNoUnknownSchema } from 'types';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { EventIndoorOurdoorEnum } from 'api/resources';
import { useMemo } from 'react';
import { EventSectionCardRowEditable } from '../components';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { useEventContext } from '../event.context';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { useEventOrganizers } from 'queries';

export const OverviewSection: React.FC = () => {
  const { event } = useEventContext();
  const { data: eventOrganizers = [], isInitialLoading: eventOrganizersLoading } = useEventOrganizers();

  const indoorOutdoorOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.indoorOutdoor), []);

  return (
    <SectionCard title="Overview" id={EventPageSection.OVERVIEW}>
      <EventSectionCardRowEditable
        title="Name"
        rowId={EventPageSectionRowId.NAME}
        formikProps={{
          initialValues: { name: event.name },
          onSubmit: (values) => updateEvent(event._id, { name: values.name?.trim() }),
          validationSchema: object({ name: string().required('Event Name Required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="name"
            name="name"
            label="Event Name *"
            helperText={event ? 'Updates will apply to all related events across different years' : undefined}
            autoFocus
          />
        )}
      >
        {event.name}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Event Organizer"
        rowId={EventPageSectionRowId.EVENT_ORGANIZER}
        formikProps={{
          initialValues: { eventOrganizer: event.eventOrganizer?._id ?? null },
          onSubmit: values => updateEvent(event._id, {
            eventOrganizer: values.eventOrganizer,
          }),
          validationSchema: object({ eventOrganizer: string().nullable().default(null) })
        }}
        form={(
          <Autocomplete
            label="Event Organizer"
            name="eventOrganizer"
            options={eventOrganizers}
            getOptionLabel={(opt) => opt?.name}
            transformValue={(opt) => opt?._id}
            loading={eventOrganizersLoading}
            autoFocus
            openOnFocus
          />
        )}
      >
        {event.eventOrganizer && <CardContentLink title={event.eventOrganizer.name} href={DYNAMIC_ROUTES.eventOrganizer.createLink({ eventOrganizerId: event.eventOrganizer._id })} />}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Attendance"
        rowId={EventPageSectionRowId.ATTENDANCE}
        formikProps={{
          initialValues: { attendance: event.attendance ?? 0 },
          onSubmit: values => updateEvent(event._id, { attendance: processFormValueUpdate.number(values.attendance) }),
          validationSchema: object({ attendance: number().default(0) })
        }}
        form={(
          <TextInput
            fullWidth
            id="attendance"
            name="attendance"
            label="Attendance"
            type="number"
          />
        )}
      >
        {event.attendance}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Amount of Vendors"
        rowId={EventPageSectionRowId.VENDORS}
        formikProps={{
          initialValues: { vendors: event.vendors ?? 0 },
          onSubmit: values => updateEvent(event._id, { vendors: processFormValueUpdate.number(values.vendors) }),
          validationSchema: object({ vendors: number().default(0) })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="vendors"
            name="vendors"
            label="Amount of Vendors"
            autoFocus
          />
        )}
      >
        {event.vendors}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Indoor or Outdoor"
        rowId={EventPageSectionRowId.INDOOR_OUTDOOR}
        formikProps={{
          initialValues: { indoorOutdoor: event.indoorOutdoor ?? UnknownEnum.unknown },
          onSubmit: values => updateEvent(event._id, { indoorOutdoor: processFormValueUpdate.enumWithUnknown(values.indoorOutdoor) }),
          validationSchema: object({ indoorOutdoor: mixed<EventIndoorOurdoorEnum | UnknownEnum>().oneOf(eventEnumHelpers.indoorOutdoor.enumValues).required() })
        }}
        form={(
          <Select
            name="indoorOutdoor"
            label="Indoor or Outdoor?"
            options={indoorOutdoorOptions}
            autoFocus
            SelectProps={{ defaultOpen: true }}
          />
        )}
      >
        {eventEnumHelpers.indoorOutdoor.getLabel(event.indoorOutdoor)}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Rain or Shine"
        rowId={EventPageSectionRowId.RAIN_OR_SHINE}
        formikProps={{
          initialValues: { rainOrShine: yesNoEnumHelpers.yesNo.getEnumValue(event.rainOrShine) },
          onSubmit: values => updateEvent(event._id, { rainOrShine: processFormValueUpdate.yesNoUnknown(values.rainOrShine) }),
          validationSchema: object({ rainOrShine: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroup
            values={[
              { label: 'Rain date(s)', value: YesNoEnum.yes },
              { label: 'No rain dates', value: YesNoEnum.no },
              { label: 'Unknown', value: UnknownEnum.unknown },
            ]}
            name="rainOrShine"
            label='Is Event "Rain Or Shine"?'
            row
          />
        )}
      >
        {{
          [YesNoEnum.yes]: 'Rain date(s)',
          [YesNoEnum.no]: 'No rain dates',
          [UnknownEnum.unknown]: 'Unknown',
        }[yesNoEnumHelpers.yesNo.getEnumValue(event.rainOrShine)]}
      </EventSectionCardRowEditable>
      {event.createdBy && <SectionCardRow title="Created by">{event.createdBy.name}</SectionCardRow>}
    </SectionCard>
  );
};