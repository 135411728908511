import { EventNoteOutlined, ListAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import { Box, Divider, List, ListItemButton, Skeleton, Stack, Typography } from '@mui/material';
import { CardContentContainer, EditableImage, StickyStackItem, ToDoListItem } from 'components';
import { useEvents, useEventsIncompleteToDos } from 'queries';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardPageSection } from '../types';
import { useEventManagementNodeIdsBadgeNumbersHash } from 'pages/EventsManagement/hooks';
import { EventsManagementTreeViewNodeIdEnum, eventsManagementTreeViewNodeIdEnumHelpers } from 'pages/EventsManagement/helpers';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { eventEnumHelpers } from 'helpers';
import { EnumHelpers } from 'helpers/enums/types';
import { DateService } from 'services';
import { useCurrentUser } from 'contexts';
import { QUERY_KEY } from 'queries/query-keys';

export const EventsManagementOverview = () => {
  const { _id } = useCurrentUser();

  const { data: events = [], isInitialLoading: eventsLoading } = useEvents();
  const { data: eventsIncompleteToDos = [], isInitialLoading: eventsIncompleteToDosLoading } = useEventsIncompleteToDos({ userId: _id });
  const upcomingEvents = events.filter(event => event.endDate && !DateService.dayjs().isAfter(DateService.dayjsTz(event.endDate.dateAsUtc)));
  const badgeNumbersHash = useEventManagementNodeIdsBadgeNumbersHash(events);
  const totalAlerts = badgeNumbersHash[EventsManagementTreeViewNodeIdEnum.events] + badgeNumbersHash[EventsManagementTreeViewNodeIdEnum.applications] + badgeNumbersHash[EventsManagementTreeViewNodeIdEnum.logistics];

  const eventsManagementOverviewDisplay = useMemo(() => {
    if(eventsLoading) {
      return <Skeleton variant="rectangular" width={1060} height={680} />;
    }

    const eventsNodeIds = [ EventsManagementTreeViewNodeIdEnum.eventsDuplicate ];
    const applicationsNodeIds = [
      EventsManagementTreeViewNodeIdEnum.applicationsMissingData,
      EventsManagementTreeViewNodeIdEnum.applicationsComingSoon,
      EventsManagementTreeViewNodeIdEnum.applicationsOpen,
      EventsManagementTreeViewNodeIdEnum.applicationsClosed,
      EventsManagementTreeViewNodeIdEnum.applicationsPending,
      EventsManagementTreeViewNodeIdEnum.applicationsWaitListed,
      EventsManagementTreeViewNodeIdEnum.applicationsDenied,
    ];
    const logisticsAcceptedNodeIds = [
      EventsManagementTreeViewNodeIdEnum.logisticsMissingData,
      EventsManagementTreeViewNodeIdEnum.logisticsInsurance,
      EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue,
      EventsManagementTreeViewNodeIdEnum.logisticsEventDates,
      EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList,
      EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList,
      EventsManagementTreeViewNodeIdEnum.logisticsLodging,
    ];

    return (
      <CardContentContainer sx={{ borderRadius: 0 }}>
        <Box m={3}>
          <Typography fontWeight={500} variant="h5">{totalAlerts}</Typography>
          <Typography variant="body2" color="text.secondary">Total alerts</Typography>

          <Typography color="text.secondary" mt={3} ml={-1}>Events</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mx={-2}>
            <Box
              sx={{
                width: 150,
                m: 1,
                border: '1px solid',
                borderRadius: 1,
                backgroundColor: 'primary.main',
                color: 'common.white'
              }}
            >
              <EventsManagementOverviewListIconButton
                initialNodeId={EventsManagementTreeViewNodeIdEnum.eventsCalendar}
                title={<EventNoteOutlined fontSize="large" />}
                label="Calendar"
              />
            </Box>
            <Box
              sx={{
                width: 150,
                m: 1,
                border: '1px solid',
                borderRadius: 1,
                backgroundColor: 'primary.main',
                color: 'common.white'
              }}
            >
              <EventsManagementOverviewListIconButton
                initialNodeId={EventsManagementTreeViewNodeIdEnum.eventsProSearch}
                title={<ListAltOutlined fontSize="large" />}
                label="List"
              />
            </Box>
            {eventsNodeIds.map(nodeId => <EventsManagementTreeViewNodeListIconButton key={nodeId} nodeId={nodeId} badgeNumbersHash={badgeNumbersHash}/>)}
          </Box>

          <Typography color="text.secondary" mt={1} ml={-1}>Applications</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mx={-2}>
            {applicationsNodeIds.map(nodeId => <EventsManagementTreeViewNodeListIconButton key={nodeId} nodeId={nodeId} badgeNumbersHash={badgeNumbersHash}/>)}
          </Box>

          <Typography color="text.secondary" mt={1} ml={-1}>Logistics</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mx={-2}>
            {logisticsAcceptedNodeIds.map(nodeId => <EventsManagementTreeViewNodeListIconButton key={nodeId} nodeId={nodeId} badgeNumbersHash={badgeNumbersHash}/>)}
          </Box>
        </Box>
      </CardContentContainer>
    );
  }, [ badgeNumbersHash, eventsLoading, totalAlerts ]);

  const statusesOverviewDisplay = useMemo(() => {
    if(eventsLoading) {
      return <Skeleton variant="rectangular" width={1060} height={630} />;
    }

    return (
      <CardContentContainer sx={{ borderRadius: 0 }}>
        <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{upcomingEvents.length}</Typography>
        <Typography variant="body2" color="text.secondary" ml={1}>Total upcoming events</Typography>


        <Typography color="text.secondary" mb={2} mt={5}>Participation Status</Typography>
        <Box display="flex" flexWrap="wrap" gap={3}>
          {eventEnumHelpers.participationStatus.enumValues.map(status => {
            const statusEventsCount = upcomingEvents.filter(event => event.participationStatus === status).length;

            return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.participationStatus} count={statusEventsCount} totalEvents={upcomingEvents.length} />;
          })}
        </Box>

        <Typography color="text.secondary" mb={2} mt={5}>Application Status</Typography>
        <Box display="flex" flexWrap="wrap" gap={3}>
          {eventEnumHelpers.applicationStatus.enumValues.map(status => {
            const statusEventsCount = upcomingEvents.filter(event => event.applicationStatus === status).length;

            return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.applicationStatus} count={statusEventsCount} totalEvents={upcomingEvents.length} />;
          })}
        </Box>

        <Typography color="text.secondary" mb={2} mt={5}>Acceptance Status</Typography>
        <Box display="flex" flexWrap="wrap" gap={3}>
          {eventEnumHelpers.acceptanceStatus.enumValues.map(status => {
            const statusEventsCount = upcomingEvents.filter(event => event.acceptanceStatus === status).length;

            return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.acceptanceStatus} count={statusEventsCount} totalEvents={upcomingEvents.length} />;
          })}
        </Box>
      </CardContentContainer>
    );
  }, [ eventsLoading, upcomingEvents ]);

  const toDosDisplay = useMemo(() => {
    // TODO: figure out loading UI
    if (eventsIncompleteToDosLoading) {
      return null;
    }

    return  (
      <CardContentContainer sx={{ borderRadius: 0 }}>
        <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{eventsIncompleteToDos.length}</Typography>
        <Typography variant="body2" color="text.secondary" ml={1}>To-dos</Typography>

        {eventsIncompleteToDos.length ?  (
          <List sx={{ mt: 2 }}>
            {eventsIncompleteToDos.map((toDo, index, arr) => (
              <>
                <ToDoListItem
                  leftContent={(
                    <Box display="flex" gap={1}>
                      <EditableImage
                        width={90}
                        height={90}
                        imageUrl={toDo.event.iconUrl}
                        disabledEditable
                        onUpload={() => {}}
                      />
                      <Box>
                        <Typography>
                          <b>{toDo.event.name}</b>
                          <Typography component="span" color="text.secondary" variant="body2"> ({toDo.event.year})</Typography>
                        </Typography>
                        <Typography>{DateService.getFormattedDateRange(toDo.event.startDate?.dateAsUtc, toDo.event.endDate?.dateAsUtc)}</Typography>
                      </Box>
                    </Box>
                  )}
                  checkboxPosition="end"
                  key={toDo._id}
                  event={toDo.event}
                  toDo={toDo}
                  invalidateQueriesHandler={(queryClient) => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENTS_INCOMPLETE_TO_DOS({ userId: _id }) })}
                />
                {index < arr.length - 1 && <Box mx={-2} my={1}><Divider /></Box>}
              </>
            ))}
          </List>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" my={5} gap={3}>
            <ThumbUpAltOutlined sx={{ fontSize: 180, color: theme => theme.palette.success.main, mb: 2 }} />
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="h5">Great work!</Typography>
              <Typography color="text.secondary">You have no incomplete to-dos</Typography>
            </Box>
          </Box>
        )}
      </CardContentContainer>
    );
  }, [ _id, eventsIncompleteToDos, eventsIncompleteToDosLoading ]);

  return (
    <Box id={DashboardPageSection.EVENTS_MANAGEMENT}>
      <StickyStackItem placement="top" order={1}>
        <Box zIndex={2}>
          <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
            <Typography variant="h6" my={1}>Events Management</Typography>
          </Box>
          <Box mx={-4}><Divider /></Box>
        </Box>
      </StickyStackItem>
      <Box maxWidth={900} display="flex" flexDirection="column" gap={2} mt={2}>
        {eventsManagementOverviewDisplay}
        {statusesOverviewDisplay}
        {toDosDisplay}
      </Box>
    </Box>
  );
};

type EventsManagementOverviewListIconButtonProps = {
  initialNodeId: EventsManagementTreeViewNodeIdEnum;
  title: React.ReactNode;
  label: string;
};

// Events Management Overview

const EventsManagementOverviewListIconButton: React.FC<EventsManagementOverviewListIconButtonProps> = ({ initialNodeId, title, label }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton onClick={() => navigate(DYNAMIC_ROUTES.events.createLink({}, { initialNodeId }))}>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%" justifyContent="center">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 55,
            height: 55,
            borderRadius: 1,
          }}
        >
          {title}
        </Box>
        <Typography variant="body2">{label}</Typography>
      </Box>
    </ListItemButton>
  );
};

export type EventsManagementTreeViewNodeListIconButtonProps = {
  nodeId: EventsManagementTreeViewNodeIdEnum;
  badgeNumbersHash: { [key: string]: number };
};

const EventsManagementTreeViewNodeLabelOverride: { [key: string]: string } = {
  [EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList]: 'Staff Schedule',
  [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList]: 'Team Assignment',
};

const EventsManagementTreeViewNodeListIconButton: React.FC<EventsManagementTreeViewNodeListIconButtonProps> = ({ badgeNumbersHash, nodeId }) => {
  const badgeNumber = badgeNumbersHash[nodeId] ?? 0;

  return (
    <Box
      key={badgeNumber}
      sx={{
        width: 150,
        m: 1,
        border: '1px solid',
        borderRadius: 1,
        borderColor: 'primary.main',
        backgroundColor: 'primary.background',
        color: 'primary.main',
      }}
    >
      <EventsManagementOverviewListIconButton
        initialNodeId={nodeId}
        title={<Typography variant="h5" fontWeight={500}>{badgeNumber > 100 ? '99+' : badgeNumber}</Typography>}
        label={EventsManagementTreeViewNodeLabelOverride[nodeId] ?? eventsManagementTreeViewNodeIdEnumHelpers.getLabel(nodeId)}
      />
    </Box>
  );
};

// Statuses Overview

type EventsStatusesOverviewNodeProps<StatusEnum extends string> = {
  value: StatusEnum;
  helpers: EnumHelpers<StatusEnum>;
  count: number;
  totalEvents: number;
};

const EventsStatusesOverviewNode = <StatusEnum extends string>(props: EventsStatusesOverviewNodeProps<StatusEnum>) => {
  const percentage = Math.round(props.count / props.totalEvents * 100);

  return (
    <Box
      sx={theme => ({
        width: 120,
        height: 110,
        borderRadius: 2,
        borderTop: `12px solid ${theme.palette[props.helpers.getColor(props.value)].main}`,
        background: theme.palette.grey[200],
      })}
    >
      <Stack alignItems="center" justifyContent="space-between" gap={1} my={1}>
        <Typography variant="body2">{props.helpers.getLabel(props.value)}</Typography>
        <Typography fontWeight={600}>{percentage}%</Typography>
        <Typography variant="body2" color="text.secondary">{props.count} events</Typography>
      </Stack>
    </Box>
  );
};