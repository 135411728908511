import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { CardContentContainer, FormFooter, PageContentContainer, PageContentResourceHeader, useAlertSnackbar } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { eventOrganizerValidationSchema } from './validation';
import { EventOrganizerBasicInfoForm } from './forms/EventOrganizerBasicInfo.form';
import { useMutation } from '@tanstack/react-query';
import { createEventOrganizer } from 'api/actions';
import { ResourceForm } from 'types';

const initialValues: ResourceForm['eventOrganizer'] = {
  name: '',
  website: '',
  mailingAddress: '',
  notes: '',
};

export const EventOrganizerNew = () => {
  const snackbar = useAlertSnackbar();
  const navigate = useNavigate();
  const createMutation = useMutation({
    mutationFn: (form: ResourceForm['eventOrganizer']) => {
      return createEventOrganizer({
        name: form.name.trim(),
        website: form.website.trim(),
        mailingAddress: form.mailingAddress.trim(),
        notes: form.notes.trim() || undefined,
      });
    },
    onSuccess: (res) => {
      navigate(DYNAMIC_ROUTES.eventOrganizer.createLink({ eventOrganizerId: res.data._id }));
      snackbar.success('Event organizer created');
    }
  });

  const handleCancel = () => {
    navigate(ROUTING_CONFIG.eventOrganizers);
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.eventOrganizers, text: 'Event Organizers' } ],
        current: 'New Event Organizer',
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="New Event Organizer Page"
          title="Create a new event organizer"
        />
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => createMutation.mutateAsync(values)}
        validationSchema={eventOrganizerValidationSchema}
        validateOnMount
        isInitialValid={false}
      >
        {(formik) => {
          return (
            <Box display="flex" flexDirection="column" gap={5}>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <CardContentContainer>
                    <Typography variant="h6" mb={2}>Year</Typography>
                    <EventOrganizerBasicInfoForm />
                  </CardContentContainer>
                </Grid>
              </Grid>

              <FormFooter>
                <Button
                  onClick={handleCancel}
                  size="large"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting}
                >
                  Create Event Organizer
                </Button>
              </FormFooter>
            </Box>
          );
        }}
      </Formik>
    </PageContentContainer>
  );
};
