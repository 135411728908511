import { authAxiosRequest } from 'api/axios';
import { Event, EventDate, Order, Product, ProductStockUpdateLogTransferTypeEnum, ProductStockQuantityUpdateLogTypeEnum, Sale, Storefront, Team, ProductProductionStockUpdateLog, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

type UpdateLogStock = {
  team: PickFromResource<Team, 'name' | 'color'>;
  product: PickFromResource<Product, 'name'>;
};

export type GetProductsStockUpdateLogsResponse = {
  data: {
    _id: string;
    stock: UpdateLogStock;
    user?: PickFromResource<User, 'name'>;
    createdAt: string;
    previousQuantity: number;
    newQuantity: number;
    type: ProductStockQuantityUpdateLogTypeEnum;

    transferType?: ProductStockUpdateLogTransferTypeEnum;
    transferStock?: UpdateLogStock;
    sale?: ResourceWithPopulated<Sale, {
      order: ResourceWithPopulated<PickFromResource<Order, 'type' | 'event' | 'eventDate' | 'storefront'>, {
        eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
        event?: PickFromResource<Event, 'name'>;
        storefront?: PickFromResource<Storefront, 'name'>;
      }>;
    }>;
    event?: PickFromResource<Event, 'name'>;
  }[];
};

export type GetProductsStockUpdateLogsInput = {
  startDate?: string;
  endDate?: string;
  limit?: number;
  productId?: string;
  teamId?: string;
  batchId?: string;
};

export const getProductsStockUpdateLogs = (input: GetProductsStockUpdateLogsInput = {}) => {
  const { productId, batchId, teamId, ...params } = input;

  if (productId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/products/${productId}/update-logs/stock`, { params });
  }

  if (teamId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/teams/${teamId}/update-logs/stock`, { params });
  }

  if (batchId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/inventory-batch/${batchId}/update-logs/stock`, { params });
  }

  return authAxiosRequest<GetProductsStockUpdateLogsResponse>('/api/update-logs/stock', { params });
};

export type GetProductsProductionStockUpdateLogsResponse = {
  data: ResourceWithPopulated<ProductProductionStockUpdateLog,{
    stock: UpdateLogStock;
    user: PickFromResource<User, 'name'>;
  }>[];
};


export type GetProductsProductionStockUpdateLogsInput = {
  limit?: number;
  productId?: string;
  batchId?: string;
};

export const getProductsProductionStockUpdateLogs = (input: GetProductsProductionStockUpdateLogsInput = {}) => {
  const { productId, batchId, ...params } = input;

  if (productId) {
    return authAxiosRequest<GetProductsProductionStockUpdateLogsResponse>(`/api/products/${productId}/update-logs/production-stock`, { params });
  }

  if (batchId) {
    return authAxiosRequest<GetProductsStockUpdateLogsResponse>(`/api/inventory-batch/${batchId}/update-logs/production-stock`, { params });
  }

  return authAxiosRequest<GetProductsProductionStockUpdateLogsResponse>('/api/update-logs/production-stock', { params });
};
