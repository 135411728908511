import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ProductionStockUpdateLogsTable, useSelectionChipGroup, useStockUpdateLogTable } from 'components';
import { useProductsProductionStockUpdateLogs } from 'queries';
import { useState } from 'react';

type View = 'regular' | 'production' | 'both';

export const ProductUpdateLogs: React.FC = () => {
  const [ view, setView ] = useState<View>('regular');
  const { StockUpdateLogTable, SelectionChipGroup: StockUpdateLogSelectionChipGroup } = useStockUpdateLogTable();
  const { SelectionChipGroup: ProductionStockUpdateLogsSelectionChipGroup, value: limit } = useSelectionChipGroup({ initialValue: '100' });
  const { data: productionStockUpdateLogs = [], isInitialLoading: isProductionStockUpdateLogsLoading } = useProductsProductionStockUpdateLogs({ limit: limit === 'all' ? undefined : Number(limit) });

  const renderTable = (title: string, table: React.ReactNode, chipGroup: React.ReactNode) => (
    <Box flex="1 1 calc(50% - 16px)" minWidth="300px" maxWidth="100%">
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography
          flex={1}
          variant="subtitle1"
          width="fit-content"
          fontWeight={500}
        >
          {title}
        </Typography>
        <Box flex={{ xs: 0, md: 1 }}>
          {chipGroup}
        </Box>
      </Box>
      {table}
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%">
      <Box maxWidth={445}>
        <FormControl fullWidth>
          <InputLabel id="table-view-type">View Type</InputLabel>
          <Select
            labelId="table-view-type"
            label="View Type"
            size="small"
            value={view}
            onChange={(e) => setView(e.target.value as View)}
          >
            <MenuItem value="regular">Regular update logs</MenuItem>
            <MenuItem value="production">Production update logs</MenuItem>
            <MenuItem value="both">Both regular and production update logs (side by side)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" flexWrap={view === 'both' ? 'wrap' : 'nowrap'} gap={4} width="100%" mt={view === 'both' ? 0 : { xs: 0, md: -8 }}>
        {(view === 'regular' || view === 'both') &&
          renderTable(
            view === 'both' ? 'Regular Update Logs' : '',
            <StockUpdateLogTable />,
            <StockUpdateLogSelectionChipGroup />
          )}
        {(view === 'production' || view === 'both') &&
          renderTable(
            view === 'both' ? 'Production Update Logs' : '',
            <ProductionStockUpdateLogsTable rows={productionStockUpdateLogs} loading={isProductionStockUpdateLogsLoading} />,
            <ProductionStockUpdateLogsSelectionChipGroup
              options={[
                { value: '100', label: 'Last 100' },
                { value: '500', label: 'Last 500' },
                { value: 'all', label: 'Show All' },
              ]}
              disabled={isProductionStockUpdateLogsLoading}
            />
          )}
      </Box>
    </Box>
  );
};
