import { OpenInNewOutlined, SortOutlined } from '@mui/icons-material';
import { Box, Button, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { GetUsersAsResourceOverview, getUser } from 'api/actions';
import { UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { BaseTextField, CardContentContainer, EditableImage, FilterButton, FilterCheckboxFieldConfiguration, FilterEnumFieldConfiguration, Modal, PageContentContainer, PageContentHeader, useFilter } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { userEnumHelpers } from 'helpers';
import { QUERY_KEY } from 'queries/query-keys';
import { useUsersAsResourceOverview } from 'queries/user';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateService, currencyFormatter, filterResourceBySearch } from 'services';

const employeeRolesFilterConfiguration: FilterEnumFieldConfiguration<GetUsersAsResourceOverview['data'][number], UserEmployeeRoleEnum>= {
  field: 'employeeRoles',
  label: 'Employee Roles',
  type: 'enum',
  options: userEnumHelpers.employeeRole.enumValues,
  getOptionLabel: userEnumHelpers.employeeRole.getLabel,
  predicate: (user, value) => !user.employeeRoles.length || user.employeeRoles.includes(value),
  defaultValue: userEnumHelpers.employeeRole.enumValues,
};

const activeFilterConfiguration: FilterCheckboxFieldConfiguration<GetUsersAsResourceOverview['data'][number]> = {
  field: 'showDeactivated',
  label: 'Show deactivated users',
  type: 'checkbox',
  defaultValue: false,
  predicate: (user, value) => {
    if(value) {
      return true;
    }

    return user.active;
  },
};

const noEmployeeRolesConfiguration: FilterCheckboxFieldConfiguration<GetUsersAsResourceOverview['data'][number]> = {
  field: 'showNoEmployeeRoles',
  label: 'Show users with no employee roles assigned',
  type: 'checkbox',
  predicate: (user, value) => {
    if(value) {
      return true;
    }

    return !!user.employeeRoles.length;
  },
  defaultValue: true,
};

const filterConfigurations = [
  activeFilterConfiguration,
  noEmployeeRolesConfiguration,
  employeeRolesFilterConfiguration,
];

type SortType = 'alphabetically' | 'lastActive' | 'mostUpcomingDates';

export const Users = () => {
  const me = useCurrentUser();
  const navigate = useNavigate();
  const { data: users = [], isInitialLoading: loading } = useUsersAsResourceOverview();
  const queryClient = useQueryClient();

  const [ sortType, setSortType ] = useState<SortType>('alphabetically');
  const [ showSortModal, setShowSortModal ] = useState(false);
  const [ search, setSearch ] = useState<string>('');

  const onUserClick = async (userId: string) => {
    await queryClient.prefetchQuery(QUERY_KEY.USER(userId), async () => (await (getUser(userId))).data);

    if (me.getIsMe(userId)) {
      navigate(ROUTING_CONFIG.profile);
    } else {
      navigate(userId);
    }
  };

  const onNewUserClick = () => {
    navigate('new');
  };

  const { filteredRows, modal, setShowModal } = useFilter({
    localStorageId: 'users',
    filterConfigurations: filterConfigurations,
    rows: users,
  });

  const sortedAndFilteredUsers = useMemo(() => {
    return filterResourceBySearch(filteredRows, 'name', search).sort((a, b) => {
      if (!a.active) {
        return 1;
      }
      if (!b.active) {
        return -1;
      }

      if (sortType === 'alphabetically') {
        // group admin users, group deactivated users, sort users alphabetically
        if (a.role !== b.role) {
          return a.role === UserRoleEnum.admin ? -1 : 1;
        }

        return a.name < b.name ? -1 : 1;
      }

      if (sortType === 'lastActive') {
        if(!a.lastSession) {
          return 1;
        }

        if(!b.lastSession) {
          return -1;
        }

        return DateService.dayjs(a.lastSession?.createdAt).isAfter(b.lastSession?.createdAt) ? -1 : 1;
      }

      if (sortType === 'mostUpcomingDates') {
        return b.upcomingEventDatesCount - a.upcomingEventDatesCount;
      }

      return 0;
    });
  }, [ filteredRows, search, sortType ]);

  return (
    <PageContentContainer
      loading={loading}
      header={(
        <PageContentHeader
          title="Users"
          rightActions={me.isAdmin && <Button variant="contained" onClick={onNewUserClick} size="small">New User</Button>}
        />
      )}
    >
      {modal}

      <Modal
        open={showSortModal}
        onClose={() => setShowSortModal(false)}
        topLeftIcon="close"
        autoHeight
      >
        <DialogTitle>Sort</DialogTitle>
        <DialogContent >
          <RadioGroup
            value={sortType}
            onChange={(_, value) => setSortType(value as SortType)}
          >
            <FormControlLabel value="alphabetically" control={<Radio />} label="Alphabetically (Admins first)" />
            <FormControlLabel value="lastActive" control={<Radio />} label="Most recently active" />
            <FormControlLabel value="mostUpcomingDates" control={<Radio />} label="Most upcoming show dates" />
          </RadioGroup>
        </DialogContent>
      </Modal>

      <Box display="flex" justifyContent="space-between">
        <BaseTextField
          name="search"
          value={search}
          placeholder="Enter user name..."
          size="small"
          label="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Box mb={2} display="flex" gap={1}>
          <FilterButton onClick={() => setShowModal(true)} />
          <Button variant="outlined" startIcon={<SortOutlined />} onClick={() => setShowSortModal(true)}>
          Sort
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {sortedAndFilteredUsers.map(({ _id, name, email, active, role, profileImageUrl, employeeRoles, ...user }) => {
          const isMe = me.getIsMe(_id);
          const isTeamMemberOrTeamLead = employeeRoles.some(role => role === UserEmployeeRoleEnum.teamLead || role === UserEmployeeRoleEnum.teamMember);

          return (
            <Grid key={_id} item xs={12} md={6} lg={4} >
              <CardContentContainer
                disableAutoHeight
                editOptionIcon={<OpenInNewOutlined />}
                showEditOption
                onEdit={() => onUserClick(_id)}
                sx={{ backgroundColor: theme => !active ? theme.palette.grey[200] : undefined }}
                clickable
              >
                <Box display="flex" alignItems="flex-start" gap={2}>
                  <EditableImage
                    width="121px"
                    height="121px"
                    onUpload={()=>{}}
                    imageUrl={profileImageUrl}
                    modalTitle="Update User Image"
                    disabledEditable
                  />
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" width="100%" gap={2} minWidth={0}>
                    <Box>
                      <Box display="flex" gap={1} alignItems="center">
                        <Typography variant="h6" lineHeight={1} noWrap textOverflow="ellipsis" display="flex" alignItems="center">
                          {name} {isMe && '(you)'} {!active && <Typography display="flex" alignItems="center" color="error.main">&nbsp;DEACTIVATED</Typography>}
                        </Typography>
                        {role === UserRoleEnum.admin && <Typography color="primary.main">{role}</Typography>}
                      </Box>
                      <Typography color="text.secondary" noWrap textOverflow="ellipsis" mb={1}>{email}</Typography>
                      <Box height={65} bgcolor="primary.background" py={1} px={1} width="100%">
                        {employeeRoles.length ? (
                          <Typography fontWeight={500}>
                            {employeeRoles.map(role => userEnumHelpers.employeeRole.getLabel(role)).join(', ')}
                          </Typography>
                        ) : (
                          <Typography fontWeight={400} fontStyle="italic" color="text.secondary" width="100%">
                            No user roles assigned
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Stack gap={0.5} mt={1}>
                  <Typography>Last active: <b>{user.lastSession?.createdAt ? DateService.getFormattedDate(user.lastSession?.createdAt, 'MMM Do, YYYY h:mma') : 'Unknown'}</b></Typography>
                  <Box visibility={isTeamMemberOrTeamLead ? 'initial' : 'hidden'}>
                    <Typography variant="body2">
                      {user.eventsCount ? (<>
                        Total <b>{user.eventsCount}</b> show (<b>{user.eventDatesCount}</b> dates)
                        {me.isAdmin && (
                          <>
                            , with total of <b>{user.eventDatesSalesCount}</b>
                            &nbsp;sales (<b>{currencyFormatter.format(user.eventDatesSalesPrice)}</b>)
                          </>
                        )}
                      </>) : 'Have never participated in shows'}
                    </Typography>
                    {me.isAdmin && (
                      <Typography variant="body2">
                        Logged <b>{user.userLoggedSalesCount}</b> sales {!!user.userLoggedSalesCount && <>(<b>{currencyFormatter.format(user.userLoggedSalesPrice)}</b>)</>}
                      </Typography>
                    )}
                    <Typography><b>{user.upcomingEventDatesCount}</b> upcoming event dates</Typography>
                  </Box>
                </Stack>
              </CardContentContainer>
            </Grid>
          );
        })}
      </Grid>
    </PageContentContainer>
  );
};