import { ArrowRightAlt, Edit, KeyboardBackspaceOutlined, MoneyOffOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Autocomplete, Avatar, Box, Button, Card, Container, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Typography } from '@mui/material';
import { LightLayout, SaleProductListItem } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateService, currencyFormatter, getUserInitialsForAvatar } from 'services';
import { useLoggingPortalContext } from './LoggingPortal.base';
import { listDividersSx } from './LogSale/utils';
import { getFrameEnumFromValue, orderEnumHelpers, saleEnumHelpers } from 'helpers';
import { EditSaleModal, LoggingPortalEventDate, LoggingPortalStorefront, ProductSearchModal } from './components';
import { GetLoggingPortalDataResponse, LoggingPortalResourceTypeEnum } from 'api/actions';
import { useLoggingPortalError } from './hooks';
import { missingImg } from 'assets';
import { OrderSaleFulfillmentTypeEnum } from 'api/resources';

const DRAWER_WIDTH = 325;
const DRAWER_WIDTH_CLOSED = 58;

export const LoggingPortal: React.FC = () => {
  const [ salesDrawerOpen, setSalesDrawerOpen ] = useState(true);
  const [ productSearchModalOpen, setProductSearchModalOpen ] = useState(false);
  const { getIsMe } = useCurrentUser();
  const { userOptions, products, recentOrders, resourceType, resourceId } = useLoggingPortalContext();
  const [ selectedUserOption, setSelectedUserOption ] = useState<GetLoggingPortalDataResponse['data']['userOptions'][number]>(userOptions.find(user => getIsMe(user._id)) ?? userOptions[0]);
  const navigate = useNavigate();
  const { error, disableStartLog, navigateConfig } = useLoggingPortalError();
  const [ editSale, setEditSale ] = useState<GetLoggingPortalDataResponse['data']['recentOrders'][number]['sales'][number] | null>(null);

  const resourceDisplay = useMemo(() => {
    if (resourceType === LoggingPortalResourceTypeEnum.eventDate) {
      return <LoggingPortalEventDate />;
    }

    return <LoggingPortalStorefront />;
  }, [ resourceType ]);

  const salesCount = recentOrders.reduce((acc, order) => acc + order.sales.length, 0);

  const salesList = (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box py={{ xs: 2, sm: 1, lg: 2 }} px={2} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="body2" fontSize="small">Sales</Typography>
          <Typography fontSize="large" fontWeight={500}>{salesCount}</Typography>
        </Box>
      </Box>
      <Box flex={1} position="relative">
        <Box
          position={{ xs: 'initial', sm: 'absolute' }}
          top={0}
          bottom={0}
          left={0}
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexGrow={1} flexDirection="column" overflow="auto">
            {!recentOrders.length && (
              <Box display="flex" justifyContent="center" pt={4} pb={{ xs: 10, sm: 0 }} flexDirection="column" alignItems="center" gap={2}>
                <MoneyOffOutlined fontSize="large" />
                <Typography fontWeight={500} color="text.secondary" ml={1}>No sales added</Typography>
              </Box>
            )}
            <List sx={listDividersSx({ divideTop: true })} disablePadding>
              {recentOrders.map((order, idx, { length }) => {
                const createdAt = DateService.dayjs(order.createdAt);

                return (
                  <Fragment key={order._id}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      bgcolor={theme => theme.palette.primary.background}
                      px={1}
                      py={0.5}
                    >
                      <Box display="flex" gap={1}>
                        <Typography fontSize="small" fontWeight={500}>
                          #{length - idx}
                        </Typography>
                        <Typography fontSize="small">
                          {createdAt.fromNow()} – <Typography component="span" fontSize="inherit" fontWeight={500}>{order.user?.name}</Typography>
                        </Typography>
                      </Box>
                      <Typography fontSize="small">
                        {createdAt.format('hh:mm:ss A')}
                      </Typography>
                    </Box>
                    {order.sales.map(sale => {
                      const frameEnum = getFrameEnumFromValue(sale.frame);
                      const product = products.find(product => product._id === sale.product._id);
                      const secondary = `${currencyFormatter.format(sale.salePrice)} • ${saleEnumHelpers.frame.getLabel(frameEnum)} • ${orderEnumHelpers.payment.getLabel(order.payment)}`;
                      const imageUrl = product?.imageUrl || missingImg;

                      if (!product) {
                        return null;
                      }

                      return (
                        <SaleProductListItem
                          key={sale._id}
                          name={product.name}
                          imageUrl={imageUrl}
                          secondary={secondary}
                          fulfillmentType={sale.fulfillmentType}
                          actions={sale.fulfillmentType === OrderSaleFulfillmentTypeEnum.IN_PERSON && (
                            <Box display="flex" gap={1}>
                              <IconButton size="small" color="primary" onClick={() => setEditSale(sale)}>
                                <Edit fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const userOptionsDisplay = useMemo(() => {
    if (userOptions.length <= 6) {
      return (
        <List disablePadding>
          {userOptions.map((user, idx, { length }) => {
            const isMe = getIsMe(user._id);
            const salesCount = recentOrders.filter(sale => sale.user?._id === user._id).length;

            return (
              <ListItem key={user._id} divider={idx !== length - 1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" mx={2} width="100%">
                  <ListItemAvatar sx={{ mr: 2, minWidth: 'unset' }}>
                    <Avatar src={user.profileImageUrl}>{user.name && getUserInitialsForAvatar(user.name)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.name}${isMe ? ' (you)' : ''}`}
                    primaryTypographyProps={{ fontWeight: 500 }}
                    secondary={`${salesCount} sale${salesCount === 1 ? '' : 's'} logged`}
                  />
                  <Box display="flex" gap={1}>
                    <Button
                      sx={{ px: 10 }}
                      disabled={!!disableStartLog}
                      variant="contained"
                      onClick={() => navigate(DYNAMIC_ROUTES.loggingPortalLogSale.createLink({ resourceType, resourceId }, { user: user._id }))}
                    >
                      Start Log
                    </Button>
                  </Box>
                </Box>
              </ListItem>
            );
          })}
        </List>
      );
    }

    return (
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" mx={2} my={1} gap={1}>
        <Autocomplete
          value={selectedUserOption}
          onChange={(_, newValue: GetLoggingPortalDataResponse['data']['userOptions'][number]) => {
            setSelectedUserOption(newValue);
          }}
          options={userOptions}
          getOptionLabel={(option) => option.name}
          size="small"
          sx={{ width: 200 }}
          renderInput={(params) => <TextField {...params} />}
        />
        <Button
          sx={{ px: 10 }}
          disabled={!!disableStartLog}
          variant="contained"
          onClick={() => navigate(DYNAMIC_ROUTES.loggingPortalLogSale.createLink({ resourceType, resourceId }, { user: selectedUserOption._id }))}
        >
          Start Log
        </Button>
      </Box>
    );
  }, [ disableStartLog, getIsMe, navigate, recentOrders, resourceId, resourceType, selectedUserOption, userOptions ]);

  return (
    <LightLayout
      appbarProps={{
        leftAction: (
          <Button
            color="inherit"
            startIcon={<KeyboardBackspaceOutlined />}
            variant="outlined"
            onClick={() => navigate(ROUTING_CONFIG.logLiveSale)}
          >
            Exit <Hidden smDown>Logging Portal</Hidden>
          </Button>
        )
      }}
    >
      {editSale && <EditSaleModal sale={editSale} onClose={() => setEditSale(null)} />}

      <Hidden smDown lgUp>
        <Drawer variant="permanent" open={salesDrawerOpen} anchor="right">
          <Box width={salesDrawerOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED} height="100%" display="flex" flexDirection="column">
            <Toolbar />
            <Box>
              <Box bgcolor={theme => theme.palette.background.defaultDarker}>
                <IconButton onClick={() => setSalesDrawerOpen(p => !p)}>
                  <ArrowRightAlt sx={{ transform: salesDrawerOpen ? undefined : 'rotate(180deg)' }} />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            {salesDrawerOpen && salesList}
            {!salesDrawerOpen && (
              <Box mx="auto" mt={1}>
                <Typography variant="body2" fontSize="small">Sales</Typography>
                <Typography fontSize="large" fontWeight={500}>{recentOrders.length}</Typography>
              </Box>
            )}
          </Box>
        </Drawer>
      </Hidden>

      <Box display="flex">
        <Container maxWidth="md" sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
          {error && (
            <Box mt={{ xs: 2, sm: 3 }} bgcolor="background.paper">
              <Alert severity="warning" variant="outlined" sx={{ whiteSpace: 'pre-line', position: 'relative' }}>
                <AlertTitle>{error.title}</AlertTitle>
                {navigateConfig && (
                  <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <Button variant="outlined" onClick={() => navigate(navigateConfig.to)} sx={{ flex: 1 }} color="warning" size="small">{navigateConfig.label}</Button>
                  </Box>
                )}
                {error.message}
              </Alert>
            </Box>
          )}
          <Box
            display="flex"
            gap={{ xs: 2, sm: 1, lg: 2 }}
            py={{ xs: 1, sm: 2, md: 3 }}
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={{ xs: 2, sm: 1, md: 2 }}
              minWidth={{ sm: '360px' }}
              maxWidth={{ sm: '764px' }}
              flex={1}
            >
              {resourceDisplay}

              {!!userOptions.length &&  <Card variant="outlined">{userOptionsDisplay}</Card>}

              {productSearchModalOpen && <ProductSearchModal onClose={() => setProductSearchModalOpen(false)}/>}
              <Card variant="outlined">
                <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                  <Typography variant="h6">Need to find a product?</Typography>
                  <Button variant="outlined" onClick={() => setProductSearchModalOpen(true)}>Search Inventory</Button>
                </Box>
              </Card>

            </Box>
            <Hidden only={[ 'sm', 'md' ]}>
              <Card variant="outlined" sx={{ width: { xs: '100%', sm: DRAWER_WIDTH }, display: 'flex', flexDirection: 'column', minHeight: 450 }}>
                {salesList}
              </Card>
            </Hidden>
          </Box>

        </Container>

        <Hidden smDown lgUp>
          <Box minWidth={salesDrawerOpen ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSED} />
        </Hidden>
      </Box>

    </LightLayout>
  );
};