import { ResourceForm, yesNoSchema } from 'types';
import { SchemaOf, number, object, string } from 'yup';

const ratingSchema = number().min(0.5, 'Please, rate from 0.5 to 5 stars').required();

export const eventFeedbackValidationSchema = (): SchemaOf<ResourceForm['eventFeedback']> => {
  return object({
    overall: ratingSchema,
    busyness: ratingSchema,
    sales: ratingSchema,
    food: ratingSchema,
    artists: ratingSchema,
    parking: ratingSchema,

    unloadPullUp: yesNoSchema,
    packUpPullUp: yesNoSchema,
    doAgain: yesNoSchema,
    needMoreStaff: yesNoSchema,
    weatherAffectedSales: yesNoSchema,

    sameBoothLocation: yesNoSchema,
    boothNotes: string().default(''),
    nextYearBoothLocation: string().default(''),
    nextYearLargerBooth: yesNoSchema,
    nextYearMultipleLocations: yesNoSchema,

    nextYearRecommendations: string().default(''),
    complaints: string().default(''),
    note: string().default(''),
  });
};