import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { eventsManagementInfoConfigurations, eventsManagementTreeViewNodeIdEnumHelpers } from '../helpers';
import { ColorCodeAction, FilterFieldConfiguration, FilterValidFilterObject, InfoPopper } from 'components';
import { getNonDefaultFilterDisplay } from 'components/Filter/helpers';
import { ColorCodeConfig } from 'components/FullCalendar/CalendarSidebarAction/ColorCodeAction/colorCode';

type FilterProps =
  | {
    filter: FilterValidFilterObject;
    filterConfigurations: FilterFieldConfiguration[];
  }
  | {
    filter?: never;
    filterConfigurations?: never;
  };

type ColorCodeProps<Option extends string, Arg extends unknown> =
  | {
    colorCodeOption: Option | null;
    defaultColorCodeOption: Option;
    colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>;
  }
  | {
    colorCodeOption?: never;
    defaultColorCodeOption?: never;
    colorCodeConfigHash?: never;
  };

export type EventsManagementInfoPopperProps<Option extends string, Arg extends unknown> = {
  tab: keyof typeof eventsManagementInfoConfigurations;
} & FilterProps & ColorCodeProps<Option, Arg>;

export const EventsManagementInfoPopper = <Option extends string, Arg extends unknown>(props: EventsManagementInfoPopperProps<Option, Arg>) => {
  const config = eventsManagementInfoConfigurations[props.tab];

  const nonDefaultFiltersContent = useMemo(() => {
    if('filterConfigurations' in props && props.filterConfigurations && props.filter) {
      return getNonDefaultFilterDisplay(props.filter, props.filterConfigurations);
    }

    return null;
  }, [ props ]);

  const nonDefaultColorCodeContent = useMemo(() => {
    if(
      'colorCodeConfigHash' in props
      && props.colorCodeConfigHash
      && props.colorCodeOption !== undefined
      && props.defaultColorCodeOption !== undefined
      && props.colorCodeOption !== props.defaultColorCodeOption
    ) {
      return <ColorCodeAction colorCodeOption={props.colorCodeOption} colorCodeConfigHash={props.colorCodeConfigHash} />;
    }

    return null;
  }, [ props ]);

  if (!config) {
    return null;
  }

  return (
    <InfoPopper>
      <Typography variant="subtitle2" mb={1} fontWeight={600}>{eventsManagementTreeViewNodeIdEnumHelpers.getFullLabel(props.tab)}</Typography>
      <Typography variant="body2" mb={1}>
        {config.description}
      </Typography>
      <Typography variant="body2">
        {nonDefaultFiltersContent && (
          <>
            <Typography variant="subtitle2" fontWeight={600}>Non-default filters applied:</Typography>
            <Box display="flex" flexDirection="column">
              {nonDefaultFiltersContent}
            </Box>
          </>
        )}
        {nonDefaultColorCodeContent && (
          <>
            <Typography variant="subtitle2" fontWeight={600}>Non-default color code applied:</Typography>
            <Box display="flex" flexDirection="column">
              {nonDefaultColorCodeContent}
            </Box>
          </>
        )}
      </Typography>
    </InfoPopper>
  );
};