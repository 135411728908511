import React, { useMemo, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Box } from '@mui/material';
import { FullCalendar, WrapWithStatusIndicator } from 'components';
import { EventsSummaryHash, getConvertedEventsPerTeam } from 'components/FullCalendar/custom/helpers';
import { getTeamShortLabel } from 'helpers';
import { GetEventsResponse, GetTeamsResponse } from 'api/actions';
import { UseTeamSchedulingCalendarConfigReturn } from './useTeamSchedulingCalendarConfig';
import { TeamsSchedulingCalendarSummary } from './TeamsSchedulingCalendarSummary.component';
import { DateService } from 'services';
import { EventInput } from '@fullcalendar/react';

export type TeamsSchedulingFullCalendarProps = {
  id: string;
  initialDate?: Date;
  events: GetEventsResponse['data'];
  teams: GetTeamsResponse['data'];
  eventsSummaryHash: EventsSummaryHash;
  onEventClick?: (eventId: string) => void;
  containerIsMediumOrSmall: boolean;
} & UseTeamSchedulingCalendarConfigReturn;

const baseConfig = {
  plugins: [ dayGridPlugin ],
  initialView: 'dayGridWeek',
  views: {
    dayGridWeek: { type: 'dayGrid', duration: { weeks: 1 } },
  },
  height: 100,
  headerToolbar: false as const,
};

export const TeamsSchedulingFullCalendar: React.FC<TeamsSchedulingFullCalendarProps> = ({
  id,
  initialDate,
  teams,
  events,
  onEventClick,
  eventsSummaryHash,
  containerIsMediumOrSmall,
  filteredEvents,
  modals,
  sidebarActionsConfig,
  getEventContent,
  handleEventClick,
  selectedTeams,
}) => {
  const [ date, setDate ] = useState<Date>(initialDate ?? new Date());
  const convertedEventsPerTeam = useMemo((): Record<string, EventInput[]> => getConvertedEventsPerTeam(teams, events, filteredEvents), [ events, filteredEvents, teams ]);

  return (
    <Box
      display="flex"
      flexDirection={containerIsMediumOrSmall ? 'row' : 'column'}
      alignItems={containerIsMediumOrSmall ? 'flex-start' : 'initial'}
    >
      {modals}
      <Box flex={1}>
        <FullCalendar
          calendarsConfig={
            [ ...selectedTeams
              .filter(team => !team.isWarehouse)
              .map((team, index) => ({
                ...baseConfig,
                id: index === 0 ? id : `${id}_${team._id}`,
                initialDate,
                events: convertedEventsPerTeam[team._id],
                eventClick: handleEventClick(onEventClick),
                eventContent: getEventContent,
                dayHeaders: index === 0 ? true : false,
                label: (
                  <Box display="flex" alignItems="center" gap={1}>
                    <WrapWithStatusIndicator status={team.color}>
                      {getTeamShortLabel(team.name)}
                    </WrapWithStatusIndicator>
                  </Box>
                ),
                labelSx: { width: 85, mt: index === 0 ? 3 : 0, justifyContent: 'flex-end', pr: 1 },
              })),
            {
              ...baseConfig,
              label: 'No team',
              labelSx: { width: 85 },
              id: `${id}_no_team`,
              initialDate,
              dayHeaders: selectedTeams.length ? false : true,
              events: convertedEventsPerTeam['no_team'],
              eventContent: getEventContent,
              eventClick: handleEventClick(onEventClick),
            },
            ]}
          sidebarActionsConfig={sidebarActionsConfig}
          onDateChange={setDate}
        />
      </Box>
      <TeamsSchedulingCalendarSummary
        containerIsMediumOrSmall={containerIsMediumOrSmall}
        events={eventsSummaryHash[DateService.dayjs(date).format('W/YYYY')]?.events ?? []}
        eventDates={eventsSummaryHash[DateService.dayjs(date).format('W/YYYY')]?.eventDates ?? []}
        teams={teams}
      />
    </Box>
  );
};
