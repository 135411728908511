import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProductStocksBaseline, updateProductStocksProductionQuantity, updateProductStocksQuantity } from 'api/actions';
import { useAlertSnackbar } from 'components';
import { QUERY_KEY } from 'queries/query-keys';

export const useProductStocksBaselineMutation = () => {
  const alert = useAlertSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductStocksBaseline,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      alert.success('Baselines updated');
    }
  });
};

export const useProductStocksQuantityMutation = () => {
  const alert = useAlertSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductStocksQuantity,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      alert.success('Inventory updated');
    }
  });
};

export const useProductProductionStocksQuantityMutation = () => {
  const alert = useAlertSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductStocksProductionQuantity,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      alert.success('Production stocks updated');
    }
  });
};