import { authAxiosRequest } from 'api/axios';
import { DeepOptionalToNull } from 'api/resources/resources.types';

export type CreateEventPaymentDueInput = DeepOptionalToNull<{
  amountDue: number;
  dueWithApplication?: boolean;
  chargedUponAcceptance?: boolean;
  dueAsSoonAsPossible?: boolean;
  dueDate?: string;
}>;

export const createEventPaymentDue = (eventId: string, data: CreateEventPaymentDueInput) => {
  return authAxiosRequest(`/api/events/${eventId}/payments-due`, { method: 'POST', data });
};

export type UpdateEventPaymentDueInput = Partial<CreateEventPaymentDueInput>;

export const updateEventPaymentDue = (eventId: string, id: string, data: UpdateEventPaymentDueInput) => {
  return authAxiosRequest(`/api/events/${eventId}/payments-due/${id}`, { method: 'PUT', data });
};

export const deleteEventPaymentDue = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/payments-due/${id}`, { method: 'DELETE' });
};