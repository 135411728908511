import { GetVehicleResponse } from 'api/actions';
import { Loading } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useVehicle } from 'queries';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

type UseVehicleOutletContext = {
  vehicle: GetVehicleResponse['data'];
  loading: boolean;
};

export const useVehicleOutletContext = () => useOutletContext<UseVehicleOutletContext>();

const VehicleBaseRaw: React.FC<{vehicleId: string}> = ({ vehicleId }) => {
  const { data: vehicle, isInitialLoading: loading } = useVehicle(vehicleId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!vehicle) {
    return <Navigate to={ROUTING_CONFIG.vehicles} replace />;
  }

  return <Outlet context={{ vehicle, loading }} />;
};

export const VehicleBase = () => {
  const { vehicleId } = DYNAMIC_ROUTES.vehicle.useParams();

  if (!vehicleId) {
    return <Navigate to={ROUTING_CONFIG.vehicles} replace />;
  }

  return <VehicleBaseRaw vehicleId={vehicleId} />;
};