import { BackupTableOutlined, EditAttributes, FilterAltOutlined, InfoOutlined, InventoryOutlined, TuneOutlined } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, Divider, Tooltip, Typography } from '@mui/material';
import { Modal } from 'components/Modal';

export type SettingsModalProps = {
  stockTypeInput?: React.ReactNode;
  teamSelectionInput?: React.ReactNode;
  filterInputs?: React.ReactNode;
  modeInput?: React.ReactNode;
  showStockMetaInput?: React.ReactNode;
  onClose: () => void;
  title?: string;
};

export const InventoryTableSettingsModal: React.FC<SettingsModalProps> = props => {
  return (
    <Modal
      open
      onClose={props.onClose}
      topLeftIcon="close"
      transitionDuration={0}
      PaperProps={{ sx: { width: '100%', mx: theme => theme.spacing(2) } }}
    >
      <DialogTitle>{props.title ?? 'Settings'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={3} pb={3} pt={1}>
          <SettingsModalSection
            icon={<EditAttributes />}
            title="Stock Type"
          >
            {props.stockTypeInput}
          </SettingsModalSection>
          <SettingsModalSection
            icon={<TuneOutlined />}
            title="Edit Mode"
            tooltip={<EditTypeTooltip />}
          >
            {props.modeInput}
          </SettingsModalSection>
          <SettingsModalSection
            icon={<BackupTableOutlined />}
            title="Team Columns"
          >
            {props.teamSelectionInput}
          </SettingsModalSection>
          <SettingsModalSection
            icon={<FilterAltOutlined />}
            title="Filters"
          >
            {props.filterInputs}
          </SettingsModalSection>
          <SettingsModalSection
            icon={<InventoryOutlined />}
            title="Stock Information"
          >
            {props.showStockMetaInput}
          </SettingsModalSection>
        </Box>
      </DialogContent>
    </Modal>
  );
};

type SettingsModalSectionProps = {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
};

const SettingsModalSection: React.FC<SettingsModalSectionProps> = props => {
  if (!props.children) {
    return null;
  }

  return (
    <Box display="flex" ml={{ xs: 0, sm: -1 }}>
      <Box mr={3} sx={{ display: { xs: 'none', sm: 'unset' } }}>
        {props.icon}
      </Box>
      <Box flex={1}>
        <Box display="flex" alignItems="center" gap={2} mb={1}>
          <Typography fontWeight={500}>{props.title}</Typography>
          {props.tooltip}
        </Box>
        <Box display="flex" flexDirection="column">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

const EditTypeTooltip: React.FC = () => {
  return (
    <Tooltip
      title={(
        <div>
          <Typography variant="overline">Set Mode</Typography>
          <Typography variant="body2">The number you input will override and set the current inventory number</Typography>
          <Divider sx={{ mt: 1, background: theme => theme.palette.background.default }} />
          <Typography variant="overline">Add Mode</Typography>
          <Typography variant="body2">The number you input will be added to the current inventory number</Typography>
          <Divider sx={{ mt: 1, background: theme => theme.palette.background.default }} />
          <Typography variant="overline">Transfer Mode</Typography>
          <Typography variant="body2">Used to transfer products from one to to another. The number you input will be subtracted from the first and added to the second team you selected.</Typography>

        </div>
      )}
      color="text.secondary"
      placement="right"
      arrow
    >
      <InfoOutlined fontSize="small" color="inherit" />
    </Tooltip>
  );
};