import { GetEventsCommunicationLogInput, GetEventsForEventOrganizerInput, GetEventsIncompleteToDosInput, GetOrdersInput, GetProductsProductionStockUpdateLogsInput, GetProductsStockUpdateLogsInput, GetSalesAnalyticsDataWithCompareInput, GetSalesAsResourceAnalyticsDataInput, GetSalesInput, GetSalesReportDataInput, GetUpcomingEventsForTeamsHashInput, GetUpcomingEventsForVehiclesHashInput } from 'api/actions';

export const QUERY_KEY = {
  EVENTS_SALES: (input?: GetSalesInput) => [ 'events_sales', input ],
  STORE_FRONTS_SALES: (input?: GetSalesInput) => [ 'store_fronts_sales', input ],
  SALES: (input?: GetSalesInput) => [ 'sales', input ],
  SALES_FOR_EVENT: (eventId: string) => [ 'sales_for_event', eventId ],
  EVENTS_SALES_AS_RESOURCE_ANALYTICS_DATA: (input: GetSalesAsResourceAnalyticsDataInput) => [ 'events_sales_as_resource_analytics_data', input ],
  EVENT_SALE_ANALYTICS_DATA: (eventId: string) => [ 'event_sales_analytics_data', eventId ],
  SALES_REPORT_DATA: (input: GetSalesReportDataInput) => [ 'sales_report_data', input ],
  SALES_ANALYTICS_WITH_COMPARE: (input: GetSalesAnalyticsDataWithCompareInput) => [ 'sales_analytics_with_compare', input ],
  SALE: (id: string) => [ 'sale', id ],
  ORDERS: (input: GetOrdersInput) => [ 'orders', input ],
  NOT_FULFILLED_ORDERS: (input: GetOrdersInput) => [ 'not_fulfilled_orders', input ],
  ORDER: (id: string) => [ 'order', id ],
  PRODUCTS: [ 'products' ],
  PRODUCTS_AS_RESOURCE_OPTIONS: [ 'products_as_resource_options' ],
  PRODUCT: (id: string) => [ 'product', id ],
  SHOPIFY_PRODUCT: (shopifyProductId: number) => [ 'shopify_product', shopifyProductId ],
  SHOPIFY_PRODUCTS: (shopifyProductIds: number[]) => [ 'shopify_products', shopifyProductIds ],
  GENRES: [ 'genres' ],
  EVENTS: [ 'events' ],
  EVENTS_FOR_DASHBOARD: [ 'events_for_dashboard' ],
  EVENTS_TODAY: (today: string) => [ 'events_today', today ],
  TEAM_EVENTS: (input: GetUpcomingEventsForTeamsHashInput) => [ 'teams_events', input ],
  TEAMS_EVENTS: (input?: GetUpcomingEventsForTeamsHashInput) => [ 'teams_events', input ],
  VEHCILE_EVENTS: (input: GetUpcomingEventsForVehiclesHashInput & { vehicleId: string }) => [ 'vehicles_events', input ],
  VEHCILES_EVENTS: (input?: GetUpcomingEventsForVehiclesHashInput) => [ 'vehicles_events', input ],
  EVENTS_COMMUNICATION_LOG: (input?: GetEventsCommunicationLogInput) => [ 'events_communication_log', input ],
  EVENTS_INCOMPLETE_TO_DOS: (input: GetEventsIncompleteToDosInput) => [ 'events_to_dos', input ],
  EVENT: (id: string) => [ 'event', id ],
  EVENT_SCHEDULING: (id: string) => [ 'event_scheduling', id ],
  EVENT_TEAM_CHANGE_OPTIONS: (id: string) => [ 'event_team_change_options', id ],
  EVENT_VEHICLE_CHANGE_OPTIONS: (id: string) => [ 'event_vehicle_change_options', id ],
  EVENT_DATES: [ 'event_dates' ],
  EVENT_DATES_UPCOMING: (today: string) => [ 'event_dates_upcoming', today ],
  EVENT_DATES_TODAY: (today: string) => [ 'event_dates_today', today ],
  EVENT_DATES_FOR_USER: (id: string) => [ 'event_dates_for_user', id ],
  EVENT_DATE_ADD_STAFF_USER_OPTIONS: (id: string) => [ 'event_date_add_staff_user_options', id ],
  EVENT_FEEDBACK: (eventId: string) => [ 'event_feedback', eventId ],
  TEAMS: [ 'teams' ],
  TEAM: (id: string) => [ 'teams', id ],
  TEAM_VEHICLE_CHANGE_OPTIONS: (id: string) => [ 'team_vehicle_change_options', id ],
  VEHICLES: [ 'vehicles' ],
  VEHICLE: (id: string) => [ 'vehicle', id ],
  PRODUCTS_STOCK_UPDATE_LOGS: (input?: GetProductsStockUpdateLogsInput) => [ 'products_stock_update_logs', input ],
  PRODUCTS_PRODUCTION_STOCK_UPDATE_LOGS: (input?: GetProductsProductionStockUpdateLogsInput) => [ 'products_production_stock_update_logs', input ],
  USER: (id: string) => [ 'user', id ],
  USER_AVAILABILTY: (id: string) => [ 'user_availability', id ],
  USERS: [ 'users' ],
  USERS_AS_RESOURCE_OPTIONS: [ 'users_as_resource_options' ],
  USERS_AS_RESOURCE_OVERVIEW: [ 'users_as_resource_overview' ],
  USERS_AVAILABILTY_FOR_EVENT_DATE_HASH: (eventDateId: string) => [ 'event-date-users-availability', eventDateId ],
  USERS_AVAILABILTY_FOR_EVENT_HASH: (eventId: string) => [ 'event-users-availability', eventId ],
  USERS_AVAILABILTY_SET_HASH: [ 'users-availability-set-hash' ],
  USER_AVAILABILTY_SET: (userId: string) => [ 'users-availability-set-hash', userId ],
  EVENT_ORGANIZER: (id: string) => [ 'event_organizer', id ],
  EVENT_ORGANIZERS: [ 'event_organizers' ],
  EVENTS_FOR_EVENT_ORGANIZER: (id: string, input?: GetEventsForEventOrganizerInput) => [ 'events_for_event_organizer', id, input ],
  SESSIONS_FOR_USER: (id: string) => [ 'sessions_for_user', id ],
  LOGGING_PORTAL_DATA_FOR_EVENT_DATE: (eventDateId: string) => [ 'logging_portal_data_for_event_date', eventDateId ],
  LOGGING_PORTAL_DATA_FOR_STOREFRONT: (storefrontId: string) => [ 'logging_portal_data_for_storefront', storefrontId ],
  INVENTORY_BATCHES: [ 'inventory_batches' ],
  INVENTORY_BATCH: (id: string) => [ 'inventory_batch', id ],
  STUDIO_PLACE: [ 'studio_place' ],
  STOREFRONTS: [ 'storefronts' ],
};