import { EventCommunicationLogTypeEnum, EventLodgingBookingStatusEnum, EventPayment, EventPaymentTypeEnum } from 'api/resources';
import { eventEnumHelpers } from 'helpers';
import { ResourceForm, UnknownEnum, yesNoUnknownSchema } from 'types';
import { mixed, object, string, SchemaOf, array, number, boolean } from 'yup';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';

export const eventExternalLinkValidationSchema = (): SchemaOf<EventSectionRowForm[EventPageSectionRowId.EXTERNAL_LINKS]> => {
  return object({
    title: string().required(),
    url: string().required(),
  });
};

export const eventLodgingPlaceValidationSchema = (): SchemaOf<EventSectionRowForm[EventPageSectionRowId.LODGING_PLACE]> => {
  return object({
    cost: number().default(0),
    finalCost: number().default(0),
    placeName: string().required('Place name is required'),
    placeId: string().required('Place is required'),
    placeAddress: string().required('Place Address is required'),
    bookingStatus: mixed<EventLodgingBookingStatusEnum | UnknownEnum>().oneOf(eventEnumHelpers.lodgingBookingStatus.enumValues).required(),
    checkIn: string().required('Check in date and time are required').nullable(),
    checkOut: string().required('Check out date and time are required').nullable(),
    website: string().default(''),
    phone: string().default(''),
    confirmationNumber: string().default(''),
    notes: string().default(''),
    needToSendCreditAuthorization: yesNoUnknownSchema,
    creditAuthorizationSent: yesNoUnknownSchema,
  });
};

export const eventCommunicationLogValidationSchema = (): SchemaOf<ResourceForm['eventCommunicationLog']> => {
  return object({
    date: string().required(),
    type: mixed<EventCommunicationLogTypeEnum>().oneOf(eventEnumHelpers.communicationLog.enumValues).required(),
    note: string().default(''),
  });
};

export const eventApplicationPaymentValidationSchema = (payments: EventPayment[], cost: number): SchemaOf<ResourceForm['eventApplicationPayment']> => {
  return object({
    type: mixed<EventPaymentTypeEnum>().oneOf(eventEnumHelpers.paymentType.enumValues).required('Payment Type Required'),
    receiptNumber: string().default(''),
    amount: string().required('Payment Amount Required').test({
      name: 'invalidFormat',
      message: 'Invalid format',
      test: (value: string) => {

        // https://stackoverflow.com/questions/8609714/regex-greater-than-zero-with-2-decimal-places
        return new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/).test(value);
      }
    }).test({
      name: 'exceedsTotal',
      message: 'This amount summed with other payments exceeds the total cost',
      test: (value: string) => {
        const paymentsSum = payments.reduce((sum, payment) => sum + payment.amount, 0);

        const currentAmount = Number(value);

        return paymentsSum + currentAmount <= cost;
      }
    }).test({
      name: 'tooLow',
      message: 'You cannot pay less than 1 cent',
      test: (value: string) => {
        const currentAmount = Number(value);

        return currentAmount !== 0 || currentAmount < 0;
      }
    })
  });
};

export const eventPaymentDueValidationSchema = (): SchemaOf<ResourceForm['eventPaymentDue']> => {
  return object({
    amountDue: number().required('Amount due is required').min(0.01, 'Amount due cannot be less than 1 cent'),
    dueWithApplication: boolean(),
    chargedUponAcceptance: boolean(),
    dueAsSoonAsPossible: boolean(),
    dueDate: string().test({
      name: 'dueNotSet',
      message: "Please, select payment due date, or set it as 'with application' or 'asap'",
      test: (value, ctx) => {
        return value || ctx.parent.dueAsSoonAsPossible || ctx.parent.dueWithApplication || ctx.parent.chargedUponAcceptance;
      }
    }),
  });
};

export const eventToDoValidationSchema = (): SchemaOf<EventSectionRowForm[EventPageSectionRowId.TO_DO]> => {
  return object({
    title: string().required('Title Required'),
    date: string().nullable().default(null),
    description: string().default(''),
    assignees: array().of(string().required('Assignee is required')).min(1, 'At least one assignee must be selected'),
  });
};
