import { CreateEventFormType } from '../types';
import { SchemaOf, object, string } from 'yup';

export const createEventValidationSchema = (): SchemaOf<CreateEventFormType> => {
  return object({
    name: string().required('Event Name Required'),
    year: string().required('Year Required'),
    location: string().required('Location Required'),
    placeId: string().default(''),
    eventManager: string().default(''),
  });
};