import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { useProductsPageContext } from 'contexts';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateInventoryBatchInput, UpdateInventoryBatchStatusEnum, updateInventoryBatch } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { useCallback, useMemo } from 'react';
import { BeforeUnloadPrompt, InventoryEdits, InventoryTableEditableMode, InventoryTableSettingsModal, InventoryTableShowStockMetaInput, InventoryTableStandardFiltersInput, StandardInventoryTableFilters, Table, useInventoryTable } from 'components';
import { getInitialTransferConfig, getTeamsStockMetaConfig, prepareInitialInventoryEdits } from '../helpers';
import { InventoryBatchTypeEnum, InventoryBatchUpdate } from 'api/resources';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { CancelBatchButton } from '../components/CancelBatchButton.component';

const prepareInventoryBatchEditsToSave = (edits: InventoryEdits) => {
  return Object.entries(edits).reduce((acc: InventoryBatchUpdate[], [ productId, productEdits ]) => {
    return Object.values(productEdits).reduce((acc, inventoryEdit) => {
      const update: InventoryBatchUpdate = {
        quantity: inventoryEdit.value,
        product: productId,
      };

      return [ ...acc, update ];
    }, acc);
  }, []);
};

export const Open: React.FC = () => {
  const navigate = useNavigate();
  const { teams, getProductsSortedAlphabetically } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);

  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);
  const teamsStockMetaConfig = useMemo(() => getTeamsStockMetaConfig(initialTransferConfig, inventoryBatch.type), [ initialTransferConfig, inventoryBatch.type ]);
  const getDisplayStandardFilters = useCallback((): StandardInventoryTableFilters[] => {
    if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
      return [ 'showNotAvailableAtEvents', 'onlyShowEdits', 'showOutNotOverstocked' ];
    }

    if ([ InventoryBatchTypeEnum.production, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type)) {
      return [ 'showNotAvailableAtEvents', 'onlyShowEdits', 'onlyShowWithProduction' ];
    }

    return [  'showNotAvailableAtEvents', 'onlyShowEdits', 'showInNotNeeded', 'showNoOutStock' ];
  }, [ inventoryBatch.type ]);

  const initialMode = useMemo(() => {
    if ([ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type)) {
      return InventoryTableEditableMode.damaged;
    }

    if (inventoryBatch.type === InventoryBatchTypeEnum.production) {
      return InventoryTableEditableMode.unary;
    }

    return InventoryTableEditableMode.transfer;
  }, [ inventoryBatch.type ]);

  const {
    inventoryTableProps,
    editModal,
    setSettingsModalOpen,
    settingsModalOpen,
    transferConfig,
    showStockMeta,
    setShowStockMeta,
    standardFilters,
    setStandardFilters,
    hasEdits,
    hasErrors,
    inventoryEdits,
    setSaveLoading
  } = useInventoryTable({
    products,
    teams,
    getStockQuantity: stock => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? stock.productionQuantity : stock.quantity,
    initialInventoryEdits: prepareInitialInventoryEdits(inventoryBatch, warehouseTeamId),
    getCellInputError: ({ stock, updateQty }) => {
      if ((inventoryBatch.type === InventoryBatchTypeEnum.production && updateQty && stock.productionQuantity < updateQty)
        ||  (inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged && updateQty && stock.productionQuantity + updateQty < 0)){
        return 'Value can\'t exceed production quantity';
      }

      if (inventoryBatch.type === InventoryBatchTypeEnum.damaged && updateQty && stock.quantity + updateQty < 0) {
        return 'Value can\'t exceed quantity';
      }
    },
    settingsArgs: {
      initialMode,
      initialTeamIds: [ inventoryBatch.team._id ],
      initialShowStockProduction: inventoryBatch.type === InventoryBatchTypeEnum.production,
      initialShowStockMeta: inventoryBatch.type !== InventoryBatchTypeEnum.production,
      initialTransferConfig,
      initialStandardFilters: [ InventoryBatchTypeEnum.production, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type) ? [ 'onlyShowWithProduction' ] : [ ],
      getDisplayStandardFilters,
    },
    teamColumnsArgs: {
      teamsStockMetaConfig,
      getTeamColumnHeaderSecondary: () => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? 'production stock' : null,
    }
  });
  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async (_, { status }) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
      if (status !== UpdateInventoryBatchStatusEnum.review) {
        navigate(ROUTING_CONFIG.inventoryBatches);
      }
    },
    onError: () => {
      setSaveLoading(false);
    }
  });

  const onSave = async (moveToReview = false) => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareInventoryBatchEditsToSave(inventoryEdits),
      status: moveToReview ? UpdateInventoryBatchStatusEnum.review : undefined,
    });
  };

  return (
    <InventoryBatchLayout
      actions={(
        <>
          <CancelBatchButton />
          <Button variant="outlined" color="success" disabled={hasErrors || updateMutation.isLoading} onClick={() => onSave()}>Save and Finish Later</Button>
          <Button variant="contained" color="success" disabled={!hasEdits || hasErrors || updateMutation.isLoading} onClick={() => onSave(true)}>Complete (move to review)</Button>
        </>
      )}
    >
      <BeforeUnloadPrompt hasEdits={hasEdits} />
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          showStockMetaInput={<InventoryTableShowStockMetaInput showStockMeta={showStockMeta} setShowStockMeta={setShowStockMeta} />}
          filterInputs={(
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters()}
              disableFilters={[ InventoryBatchTypeEnum.productionDamaged, InventoryBatchTypeEnum.production ].includes(inventoryBatch.type)
                ? [ 'onlyShowWithProduction' ]
                : [ 'showNotAvailableAtEvents' ]
              }
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      {editModal}
      <Table preserveQuickFilterOnRowsUpdate {...inventoryTableProps} />
    </InventoryBatchLayout>
  );
};