import { ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { useProductsStockUpdateLogs } from 'queries';
import { useMemo } from 'react';
import { AnalyticsTimelineChart } from '../AnalyticsTimelineChart.component';
import { DateRange } from 'components/DateRangePickerPopover';
import { DateService } from 'services';

export type ProductionAnalyticsTimelineChartProps = {
  productId?: string;
  dateRange?: DateRange;
};

export const ProductionAnalyticsTimelineChart: React.FC<ProductionAnalyticsTimelineChartProps> = props => {
  const { data: updateLogs = [], isInitialLoading: loading } = useProductsStockUpdateLogs({ productId: props.productId });
  const productionUpdateLogs = updateLogs.filter(updateLog => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.unary);
  const initialDateRange = useMemo(() => {
    const dateRangeStart = productionUpdateLogs.reduce((r, updateLog) => r.isAfter(updateLog.createdAt) ? DateService.dayjs(updateLog.createdAt) : r, DateService.dayjs());

    return { start: dateRangeStart, end: DateService.dayjs() };
  }, [ productionUpdateLogs ]);
  const dateRange = props.dateRange ?? initialDateRange;

  const filteredProductionUpdateLogs = productionUpdateLogs.filter(updateLog => {
    const date = DateService.dayjs(updateLog.createdAt);

    return !(date.isAfter(dateRange.end, 'day') || date.isBefore(dateRange.start, 'day'));
  });

  const totalProduced = filteredProductionUpdateLogs.reduce((r, updateLogs) => r + updateLogs.newQuantity - updateLogs.previousQuantity, 0).toString();

  return (
    <AnalyticsTimelineChart
      header="Production"
      subheader={totalProduced}
      dateRange={props.dateRange ?? initialDateRange}
      config={{ baseConfig: { data: filteredProductionUpdateLogs, getDate: (updateLog) => DateService.dayjs(updateLog.createdAt), getValue: (updateLog) => updateLog.newQuantity - updateLog.previousQuantity, dateRange } }}
      loading={loading}
    />
  );
};