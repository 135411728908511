import { ApiResource } from './resources.types';

export type ProductStockUpdateLog = ApiResource<{
  stock: string;
  type: ProductStockQuantityUpdateLogTypeEnum;
  previousQuantity: number;
  newQuantity: number;

  transferType?: ProductStockUpdateLogTransferTypeEnum;
  transferStock?: string;

  sale?: string;
  event?: string;

  user: string;
}>;

export const enum ProductStockQuantityUpdateLogTypeEnum {
  set = 'set',
  unary = 'unary',
  damaged = 'damaged',
  missing = 'missing',
  transfer = 'transfer',
  sale = 'sale',
  deleteSale = 'deleteSale',
  saleProductChangeTransfer = 'saleProductChangeTransfer',
  eventTeamChangeTransfer = 'eventTeamChangeTransfer',
  productionBatched = 'productionBatched',
}

export const enum ProductStockUpdateLogTransferTypeEnum {
  to = 'to',
  from = 'from',
}

export type ProductProductionStockUpdateLog = ApiResource<{
  stock: string;
  type: ProductProductionStockQuantityUpdateLogTypeEnum;
  previousQuantity: number;
  newQuantity: number;

  user: string;
}>;


export const enum ProductProductionStockQuantityUpdateLogTypeEnum {
  set = 'set',
  unary = 'unary',
  production = 'production',
  batched ='batched',
  damaged = 'damaged',
  missing = 'missing',
}


