import { Box, Button, Card } from '@mui/material';
import { FormFooter, useAlertSnackbar, PageContentContainer, PageContentResourceHeader } from 'components';
import { Formik } from 'formik';

import { DateService } from 'services';
import { useMutation } from '@tanstack/react-query';
import { createEvent } from 'api/actions';
import { useNavigate } from 'react-router-dom';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { CreateEventFormType } from './types';
import { CreateEventForm, createEventValidationSchema } from './forms';
import { UserEmployeeRoleEnum } from 'api/resources';
import { useCurrentUser, UseCurrentUserOutput } from 'contexts';

const createInitialValues = (currentYear: number, currentUser: UseCurrentUserOutput): CreateEventFormType => ({
  year: String(currentYear),
  name: '',
  location: '',
  placeId: '',
  eventManager: currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]) ? currentUser._id : '',
});

export const EventNew = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const snackbar = useAlertSnackbar();
  const createEventMutation = useMutation({
    mutationFn: async (form: CreateEventFormType) => {

      return await createEvent({
        year: Number(form.year),
        name: form.name,
        placeId: form.placeId,
        eventManager: form.eventManager,
        // temp
        boothRequirements: {},
        insurance: {},
        lodging: {},
        website: {},
      });
    },
    onSuccess: async (response) => {
      navigate(DYNAMIC_ROUTES.event.createLink({ eventId: response.data._id }));
      snackbar.success('Event created');
    }
  });

  const handleCancel = () => {
    navigate(ROUTING_CONFIG.events);
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.events, text: 'Events' } ],
        current: 'New Event',
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="New Event Page"
          title="Create a new event"
        />
      )}
    >
      <Formik
        initialValues={createInitialValues(DateService.dayjs().year(), currentUser)}
        onSubmit={(values) => createEventMutation.mutateAsync(values)}
        validationSchema={createEventValidationSchema()}
        validateOnMount
        isInitialValid={false}
      >
        {(formik) => {
          return (
            <Box display="flex" flexDirection="column" gap={5}>

              <Card variant="outlined" sx={{ maxWidth: '500px' }}>
                <Box p={2}>
                  <CreateEventForm />
                </Box>
              </Card>

              <FormFooter>
                <Button
                  onClick={handleCancel}
                  size="large"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting}
                >
                  Create Event
                </Button>
              </FormFooter>
            </Box>
          );
        }}
      </Formik>
    </PageContentContainer>
  );
};
