import { GetInventoryBatchResponse, GetProductsResponse } from 'api/actions';
import React from 'react';
import { InventoryEdits } from './hooks';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum } from 'api/resources';
import { Box, BoxProps, Divider, Typography } from '@mui/material';
import { CheckCircleOutlined, HighlightOff } from '@mui/icons-material';
import { InventoryBatchStatusChip } from 'components/Chips';

export type PreviosQuantitiesCellProps = {
  inventoryBatch: GetInventoryBatchResponse['data'];
  row: GetProductsResponse['data'][number];
  inventoryEdits: InventoryEdits;
};

export const PreviosQuantitiesCell: React.FC<PreviosQuantitiesCellProps> = ({ inventoryBatch, row, inventoryEdits }) => {
  const batchUpdate = inventoryBatch.updates.find(update => update.product === row._id);
  const hadReview = ![ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type);
  const quantity = batchUpdate?.quantity;
  const reviewQuantity = batchUpdate?.reviewQuantity;
  const inventoryEditValue = inventoryEdits[row._id]?.[inventoryBatch.team._id]?.value;

  if (!batchUpdate) {
    return (
      <Box width="100%" display="flex">
        <PreviousQuantityWrapper sx={{ mx: -1, px: 1 }} isError={!!inventoryEditValue}>
          {(icon) => (
            <>
              {!!inventoryEditValue && icon}
              <Typography ml={1} variant="body2" fontStyle="italic" color="text.secondary">No updates</Typography>
            </>
          )}
        </PreviousQuantityWrapper>
      </Box>
    );
  }

  return (
    <Box width="100%" display="flex">
      <PreviousQuantityWrapper
        sx={{ ml: -1, pl: 1, mr: hadReview ? 0 : -1, pr: hadReview ? 0 : 1 }}
        isSuccess={!!inventoryEditValue ? inventoryEditValue === quantity : undefined}
        isError={!!inventoryEditValue ? inventoryEditValue !== quantity : undefined}
      >
        {(icon) => (
          <>
            <InventoryBatchStatusChip value={InventoryBatchStatusEnum.open} size="small" sx={{ width: 60, mr: 0.5 }} />
            <Typography pr={1} fontWeight={500} component="span" display="flex" alignItems="center" justifyContent="flex-end" flex={1} gap={1}>
              {icon}{Math.abs(quantity ?? 0)}
            </Typography>
          </>
        )}
      </PreviousQuantityWrapper>
      {hadReview && !inventoryEditValue && (
        <Divider flexItem orientation="vertical" sx={{ my: -4 }}/>
      )}
      {hadReview && (
        <PreviousQuantityWrapper
          sx={{ mr: -1, pr: 1 }}
          isSuccess={!!inventoryEditValue ? inventoryEditValue === reviewQuantity : undefined}
          isError={!!inventoryEditValue ? inventoryEditValue !== reviewQuantity : undefined}
        >
          {(icon) => (
            <>
              <Typography pl={1} fontWeight={500} component="span" display="flex" alignItems="center" flex={1} gap={1}>
                {Math.abs(reviewQuantity ?? 0)}{icon}
              </Typography>
              <InventoryBatchStatusChip value={InventoryBatchStatusEnum.review} size="small" sx={{ width: 60, ml: 0.5 }} />
            </>
          )}
        </PreviousQuantityWrapper>
      )}
    </Box>
  );
};

type PreviousQuantityWrapperProps = {
  isSuccess?: boolean;
  isError?: boolean;
  children: (icon: React.ReactNode) => React.ReactNode;
  sx?: BoxProps['sx'];
};

const PreviousQuantityWrapper: React.FC<PreviousQuantityWrapperProps> = ({ children, isSuccess, isError, sx }) => {
  const isHightlighted = isSuccess || isError;

  return (
    <Box
      sx={{
        flex: 1,
        my: -4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: isHightlighted? theme => theme.palette[isSuccess ? 'success' : 'error'].background : undefined,
        border: theme => `1px solid ${isHightlighted ? theme.palette[isSuccess ? 'success' : 'error'].main : 'transparent'}`,
        ...sx,
      }}
    >
      {children((
        <Box component="span" height={20} visibility={isHightlighted ? 'visible' : 'hidden'}>
          {isSuccess
            ? <CheckCircleOutlined fontSize="small" color="success" />
            : <HighlightOff fontSize="small" color="error" />
          }
        </Box>
      ))}
    </Box>
  );
};
