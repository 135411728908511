import { Dialog, Box, Typography, Button, Divider, Alert } from '@mui/material';
import { NumberPad } from 'components/NumberPad';
import { InventoryEdit, InventoryRow, InventoryTableCellEdit } from '../hooks';

export type InventoryTableEditModalProps = {
  cellEdit: InventoryTableCellEdit | null;
  onClose: () => void;
  onChange: (value: string, teamId: string, productId: string) => void;
  getProduct: (productId: string) => InventoryRow;
  getInventoryEdit: (product: InventoryRow, teamId: string) => InventoryEdit | undefined;
};

export const InventoryTableEditModal: React.FC<InventoryTableEditModalProps> = ({ cellEdit, getProduct, getInventoryEdit, onClose, onChange }) => {
  if (!cellEdit) {
    return null;
  }

  const product = getProduct(cellEdit.productId);
  const inventoryEdit = getInventoryEdit(product, cellEdit.teamId);

  return (
    <Dialog
      open
      maxWidth="xs"
      PaperProps={{ sx: { width: '100%', mx: theme => theme.spacing(2) } }}
      onClose={onClose}
    >
      <Box px={2} py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={500} fontSize="large">{product.name}</Typography>
        <Button onClick={onClose}>Done</Button>
      </Box>
      <Divider />
      <Box position="relative">
        {inventoryEdit?.error && (
          <Box position="absolute" top={0} right={0} width="fit-content">
            <Alert severity="error" sx={{ borderBottomLeftRadius: theme => theme.spacing(1) }}>{inventoryEdit.error}</Alert>
          </Box>
        )}
        <NumberPad
          value={inventoryEdit?.value !== undefined ? String(inventoryEdit.value) : ''}
          onChange={(value) => onChange(value, cellEdit.teamId, cellEdit.productId)}
          preventNegative
          error={inventoryEdit?.error}
        />
      </Box>
    </Dialog>
  );
};