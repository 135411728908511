import { FullCalendar, FullCalendarProps } from 'components/FullCalendar/FullCalendar.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GetEventsResponse, GetTeamsResponse } from 'api/actions';
import { useMemo, useState } from 'react';
import { EventReceiveArg } from 'types';
import { Box } from '@mui/material';
import { getTeamShortLabel } from 'helpers';
import { DragAndDropEventsCalendarEventExtendedProps, EventsSummaryHash, getConvertedEventsPerTeam } from '../helpers';
import { TeamsSchedulingCalendarSummary } from './TeamsSchedulingCalendarSummary.component';
import { DateService } from 'services';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';
import { UseTeamSchedulingCalendarConfigReturn } from './useTeamSchedulingCalendarConfig';

type RenderTeamChangeModalFunction = (params: {
  teamChangeObj: EventTeamChangeObject;
  onClose: () => void;
}) => React.ReactNode;

export type TeamsSchedulingFullCalendarEditableProps = {
  id: string; // used to retrieve confoguration (e.g. showTheseFields, selected teams)
  calendarId: string; // used to render calendar
  initialDate?: Date;
  events: GetEventsResponse['data'];
  teams: GetTeamsResponse['data'];
  eventsSummaryHash: EventsSummaryHash;
  renderTeamChangeModal: RenderTeamChangeModalFunction;
  onEventClick?: (eventId: string) => void;
  containerIsMediumOrSmall: boolean;
} & UseTeamSchedulingCalendarConfigReturn;

export type EventTeamChangeObject = {
  event: GetEventsResponse['data'][number];
  newTeam:  GetTeamsResponse['data'][number] | null;
  revert: () => void;
};

const baseConfig: Omit<FullCalendarProps['calendarsConfig'][number], 'id'> = {
  plugins: [ dayGridPlugin, interactionPlugin ],
  initialView: 'dayGridWeek',
  views: {
    dayGridWeek: { type: 'dayGrid', duration: { weeks: 1 } },
  },
  eventDrop: (info) => info.revert(),
  eventAdd: (info) => info.revert(),
  eventResize: (info) => info.revert(),
  editable: true,
  droppable: true,
  eventOverlap: false,
  firstDay: 1,
  headerToolbar: false,
  duration: { weeks: 1 },
  height: 'auto',
  fullWidthHeight: 'auto',
};

export const TeamsSchedulingFullCalendarEditable: React.FC<TeamsSchedulingFullCalendarEditableProps> = props => {
  const [ date, setDate ] = useState<Date>(props.initialDate ?? new Date());

  const [ _, setState ]= useState(0);
  const onStateChangeHandler = () => setState(p => p + 1);
  const [ teamChangeObj, setTeamChangeObj ] = useState<EventTeamChangeObject | null>(null);

  const onEventReceive = (info: EventReceiveArg<DragAndDropEventsCalendarEventExtendedProps>, newTeam: GetTeamsResponse['data'][number] | null) => {
    const event = info.event;
    const { allowedEnd, allowedStart } = event.extendedProps;
    const isEventWithinAllowedDates = allowedStart.isSame(event.start) && allowedEnd.isSame(event.end);

    if (isEventWithinAllowedDates) {
      setTeamChangeObj({
        event: info.event.extendedProps.event,
        newTeam,
        revert: info.revert
      });
    } else {
      info.revert();
      onStateChangeHandler();
    }
  };

  const {
    filteredEvents,
    modals,
    sidebarActionsConfig,
    getEventContent,
    handleEventClick,
    selectedTeams,
  } = props;

  const convertedEventsPerTeam = useMemo(() => getConvertedEventsPerTeam(props.teams, props.events, filteredEvents), [ filteredEvents, props.events, props.teams ]);

  const calendarsConfig = [
    ...selectedTeams
      .filter(team => !team.isWarehouse)
      .map((team, index): FullCalendarProps['calendarsConfig'][number] => ({
        ...baseConfig,
        dayHeaders: index === 0 ? true : false,
        label: (
          <Box display="flex" alignItems="center" gap={1}>
            <WrapWithStatusIndicator status={team.color}>
              {getTeamShortLabel(team.name)}
            </WrapWithStatusIndicator>
          </Box>
        ),
        height: 100,
        labelSx: { width: 85, mt: index === 0 ? 3 : 0, justifyContent: 'flex-end', pr: 1 },
        id: index === 0 ? props.calendarId : `${props.calendarId}_${team._id}`,
        initialDate: props.initialDate,
        events: convertedEventsPerTeam[team._id],
        eventReceive: (info: EventReceiveArg<DragAndDropEventsCalendarEventExtendedProps>) => onEventReceive(info, team),
        eventContent: getEventContent,
        eventClick: handleEventClick(props.onEventClick),
      })),
    {
      ...baseConfig,
      eventOverlap: true,
      dayHeaders: selectedTeams.length ? false : true,
      label: 'No team',
      labelSx: { width: 85 },
      height: 100,
      id: `${props.calendarId}_no_team`,
      initialDate: props.initialDate,
      events: convertedEventsPerTeam['no_team'],
      eventReceive: (info: EventReceiveArg<DragAndDropEventsCalendarEventExtendedProps>) => onEventReceive(info, null),
      eventContent: getEventContent,
      eventClick: handleEventClick(props.onEventClick),
    },
  ];

  if (!props.teams.length) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection={props.containerIsMediumOrSmall ? 'row' : 'column'}
      alignItems={props.containerIsMediumOrSmall ? 'flex-start' : 'initial'}
    >
      {modals}
      {teamChangeObj && props.renderTeamChangeModal({ teamChangeObj, onClose: () => setTeamChangeObj(null) })}
      <Box flex={1}>
        <FullCalendar
          calendarsConfig={calendarsConfig}
          sidebarActionsConfig={sidebarActionsConfig}
          onDateChange={setDate}
        />
      </Box>
      <TeamsSchedulingCalendarSummary
        events={props.eventsSummaryHash[DateService.dayjs(date).format('W/YYYY')]?.events ?? []}
        eventDates={props.eventsSummaryHash[DateService.dayjs(date).format('W/YYYY')]?.eventDates ?? []}
        teams={props.teams}
        containerIsMediumOrSmall={props.containerIsMediumOrSmall}
      />
    </Box>
  );
};