import { EventAcceptanceStatusEnum } from 'api/resources';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { DateService } from 'services';
import { getEventDateImagesWarnings } from 'helpers';
import { EventDateImageWarnings } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const useLoggingPortalError = () => {
  const data = useLoggingPortalContext();

  if ('eventDate' in data) {
    const { eventDate, event } = data;
    let disableStartLog = false;
    let error: null | { title: string; message: React.ReactNode } = null;
    let navigateConfig: { to: string; label: string} | null = null;
    const eventDateImageWarningsHash = getEventDateImagesWarnings(eventDate, event.dates);
    const eventDateImageWarnings = Object.values(eventDateImageWarningsHash);
    const eventNavigate = {
      to: DYNAMIC_ROUTES.event.createLink({ eventId: data.event._id }),
      label: 'Go to Event'
    };
    const eventDateNavigate = {
      to: DYNAMIC_ROUTES.eventDate.createLink({ eventId: data.event._id, eventDateId: data.eventDate._id }),
      label: 'Go to Event Date'
    };

    if (!DateService.dayjsTz(eventDate.dateAsUtc).isToday()) {
      error = {
        title: 'This event date is not today',
        message: 'The logging portal is meant for logging live sales, therefore it is disabled for event dates that are not today. Please exit. \n\nIf you are trying to log sales for a past event date, please use the "Log Sales" tab on the Event Sales page.'
      };
      disableStartLog = true;
    } else if (!eventDate.staff.length) {
      error = {
        title: 'No staff assigned',
        message: 'You must assign staff to this event date before logging sales. Please exit.'
      };
      disableStartLog = true;
      navigateConfig = eventDateNavigate;
    } else if (!event.team) {
      error = {
        title: 'No team assigned',
        message: 'You must assign a team to this event before logging sales. Please exit.'
      };
      disableStartLog = true;
      navigateConfig = eventNavigate;
    } else if (event.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
      error = {
        title: 'Event preperation status invalid',
        message: 'The event preperation status must be "accepted" before logging sales. Please exit.'
      };
      disableStartLog = true;
      navigateConfig = eventNavigate;
    } else if (eventDateImageWarnings.length) {
      error = {
        title: `Event date image${eventDateImageWarnings.length > 1 ? 's' : ''} missing`,
        message: <EventDateImageWarnings event={event} eventDate={eventDate} variant="body2" />,
      };
      navigateConfig = eventDateNavigate;
    }

    return { error, disableStartLog, navigateConfig };
  }

  return { error: null, disableStartLog: false, navigateConfig: null };;
};
