import { SettingsOutlined } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { GridSearchIcon, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';
import { StockMetaKey } from './StockMetaKey.component';

export type ToolbarProps = {
  hideSettingsButton?: boolean;
  showStockMeta: boolean;
  onOpenSettings: () => void;
  actions?: React.ReactNode;
  isSetMode?: boolean;
};

export const Toolbar: React.FC<ToolbarProps> = props => {
  const api = useGridApiContext();

  return (
    <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap-reverse', p: 1, gap: 2, borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      <TextField
        label="Search Products"
        size="small"
        variant="outlined"
        value={api.current.state.filter.filterModel.items.find(item => item.field === 'name')?.value ?? ''}
        onChange={(e) => api.current.upsertFilterItem({ field: 'name', operator: 'contains', value: e.target.value })}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GridSearchIcon fontSize="small" />
            </InputAdornment>
          )
        }}
      />
      {!props.hideSettingsButton && (
        <IconButton onClick={props.onOpenSettings}>
          <SettingsOutlined />
        </IconButton>
      )}
      {props.showStockMeta && <StockMetaKey />}
      {props.isSetMode && <Typography textTransform="uppercase" variant="body2">Set Mode Enabled</Typography>}
      <Box flex={1} display="flex" justifyContent="flex-end" gap={2}>
        {props.actions}
      </Box>
    </GridToolbarContainer>
  );
};