import { Formik } from 'formik';
import React, { useState } from 'react';
import { LogSaleStepEnum, useLogSaleContext } from '../utils';
import { Box, Button, DialogContent, Typography } from '@mui/material';
import { Modal, ShipmentForm, ShipmentInfo, shipmentInfoFormValidationSchema } from 'components';
import { CheckCircle } from '@mui/icons-material';
import { logoImg } from 'assets';

export type ShipmentInfoModalProps = {
  isEdit?: boolean;
  onClose: () => void;
};

export const ShipmentInfoModal: React.FC<ShipmentInfoModalProps> = ({ isEdit, onClose }) => {
  const { onUpdateShipmentInfo, state, onSetStep } = useLogSaleContext();
  const [ credentialsSubmitted, setCredentialsSubmitted ] = useState(false);

  const onSubmit = (values: ShipmentInfo) => {
    onUpdateShipmentInfo(values);
    setCredentialsSubmitted(true);
  };

  const onAcknowledge = () => {
    onSetStep(LogSaleStepEnum.payment);
    onClose();
  };

  if (credentialsSubmitted) {
    return (
      <Modal open onClose={onClose} fullScreen>
        <DialogContent>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" gap={1}>
            <CheckCircle color="success" sx={{ fontSize: 350, display: { xs: 'none', md: 'initial' } }} />
            <Typography variant="h2" fontWeight={400} textAlign="center" mb={1}>
              Thank you!
            </Typography>

            <Typography variant="h6" fontWeight={400} textAlign="center" color="text.secondary" maxWidth={450}>
              We&apos;ve received your {isEdit && 'updated'} shipment details. Please hand the device back to our staff member. If you have any questions or need to make changes, feel free to ask for assistance.
            </Typography>
            <Button onClick={onAcknowledge} sx={{ mb: 20, mt: 10 }} color="inherit" variant="outlined" size="large">
              Acknowledge (Staff only)
            </Button>
          </Box>
        </DialogContent>
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose} fullScreen>
      <DialogContent>
        <Formik
          onSubmit={onSubmit}
          initialValues={state.shipmentInfo}
          validationSchema={shipmentInfoFormValidationSchema}
        >
          {(formik) => {
            return (
              <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between" gap={1}>
                <Box display="flex" flexDirection="column" alignSelf="center" gap={{ xs: 1, md: 2, lg: 3 }} width="100%" maxWidth={600}>
                  <Box
                    component="img"
                    display={{ xs: 'none', sm: 'flex' }}
                    height={(theme) => ({ xs: theme.spacing(10), sm: theme.spacing(15), md: theme.spacing(20), lg: theme.spacing(25) })}
                    width="auto"
                    mx="auto"
                    sx={{ objectFit: 'contain' }}
                    src={logoImg}
                  />
                  <Typography variant="h4" textAlign="center" mb={3}>{isEdit ? 'Please enter' : 'Update'} shipping details</Typography>
                  <ShipmentForm />
                  <Box maxWidth={300} width="100%" alignSelf="center">
                    <Button variant="contained" onClick={() => formik.handleSubmit()} size="large" fullWidth>
                    Submit
                    </Button>
                  </Box>
                </Box>
                <Box maxWidth={300} width="100%" alignSelf="center">
                  <Button variant="outlined" onClick={onClose} fullWidth>
                    Cancel (Staff only)
                  </Button>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </DialogContent>
    </Modal>
  );
};
