import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type BeforeUnloadPromptProps = {
  hasEdits: boolean;
};

export const BeforeUnloadPrompt: React.FC<BeforeUnloadPromptProps> = ({ hasEdits }) => {
  const navigate = useNavigate();
  const [ finishStatus, setFinishStatus ] = useState(false);

  useEffect(() => {
    // the handler for showing the prompt when leaving the page
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if(hasEdits) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = ''; // For older browsers
      }
    };

    // the handler for showing the prompt when going back within the page
    const backButtonEventHandler = (e: PopStateEvent) => {
      e.preventDefault();
      // !finishStatus means user have not confirmed backing yet
      if (!finishStatus) {
        if (window.confirm('The changes you have made might not be saved.')) {
          setFinishStatus(true);
          // going back using react-router-dom, as 'cushion' prevented browser from stepping back
          navigate(-1);
        } else {
          // adding a 'cushion' back, since it was removed when 'back' was pressed
          window.history.pushState(null, '', window.location.pathname);
          setFinishStatus(false);
        }
      }
    };

    if (hasEdits) {
      // this is a 'cushion' that will prevent the browser from going back from the page
      // insted, when 'back' is pressed, it will just remove this extra step in history
      window.history.pushState(null, '', window.location.href);
      // listens to going back within a page
      window.addEventListener('popstate', backButtonEventHandler);
      // listens to exiting the page
      window.addEventListener('beforeunload', beforeUnloadHandler);
      return () => {
        window.removeEventListener('popstate', backButtonEventHandler);
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      };
    } else {
      // if we have the 'cushion', it mean we have added all the listeners
      if (window.history.state === null) {
        window.removeEventListener('popstate', backButtonEventHandler);
        window.removeEventListener('beforeunload', beforeUnloadHandler);
        // removing the 'cushion'
        window.history.back();
      }
    }
  }, [ finishStatus, hasEdits, navigate ]);

  return null;
};