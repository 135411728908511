import { Formik } from 'formik';
import React from 'react';
import { UpdateResourceModal, useEventContext } from 'components';
import { ResourceForm } from 'types';
import { EventFeedbackForm } from '../forms/EventFeedback.form';
import { eventFeedbackValidationSchema } from '../forms/validation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEventFeedback } from 'api/actions/eventFeedback';
import { QUERY_KEY } from 'queries/query-keys';
import { processFormValue } from 'helpers';

export type AddEventFeedbackModalProps = {
  onClose: () => void;
};

const initialAddEventFeedbackState: ResourceForm['eventFeedback'] = {
  overall: 0,
  busyness: 0,
  sales: 0,
  food: 0,
  artists: 0,
  parking: 0,

  unloadPullUp: null,
  packUpPullUp: null,
  doAgain: null,
  needMoreStaff: null,
  weatherAffectedSales: null,

  sameBoothLocation: null,
  boothNotes: '',
  nextYearBoothLocation: '',
  nextYearLargerBooth: null,
  nextYearMultipleLocations: null,

  complaints: '',
  nextYearRecommendations: '',
  note: '',
};

export const AddEventFeedbackModal: React.FC<AddEventFeedbackModalProps> = ({ onClose }) => {
  const { event } = useEventContext();
  const queryClient = useQueryClient();
  const createEventFeedbackMutation = useMutation({
    mutationFn: (form: ResourceForm['eventFeedback']) => createEventFeedback(event._id, {
      ...form,

      unloadPullUp: processFormValue.yesNoOrNull(form.unloadPullUp),
      packUpPullUp: processFormValue.yesNoOrNull(form.packUpPullUp),
      needMoreStaff: processFormValue.yesNoOrNull(form.needMoreStaff),
      weatherAffectedSales: processFormValue.yesNoOrNull(form.weatherAffectedSales),
      doAgain: processFormValue.yesNoOrNull(form.doAgain),
      sameBoothLocation: processFormValue.yesNoOrNull(form.sameBoothLocation),
      boothNotes: form.boothNotes || undefined,
      nextYearBoothLocation: form.nextYearBoothLocation || undefined,
      nextYearLargerBooth: processFormValue.yesNoOrNull(form.nextYearLargerBooth),
      nextYearMultipleLocations: processFormValue.yesNoOrNull(form.nextYearMultipleLocations),

      complaints: form.complaints || undefined,
      note: form.note || undefined,
      nextYearRecommendations: form.nextYearRecommendations || undefined,
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_FEEDBACK(event._id) });
      onClose();
    }
  });
  const onSubmit = async (form: ResourceForm['eventFeedback']) => {
    createEventFeedbackMutation.mutate(form);

    onClose();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialAddEventFeedbackState}
      validationSchema={eventFeedbackValidationSchema}
    >
      {
        (formik) => {
          return (
            <UpdateResourceModal
              open
              onClose={onClose}
              onSave={formik.handleSubmit}
              title="Add Event Feedback"
              saveDisabled={!formik.isValid}
              loading={formik.isSubmitting}
            >
              <EventFeedbackForm />
            </UpdateResourceModal>
          );
        }
      }
    </Formik>
  );
};
