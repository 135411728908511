import { CreateOutlined, FlakyOutlined, StarHalfOutlined, Storefront } from '@mui/icons-material';
import { FormGroupLayout, RadioGroupYesNoBoolean, Rating, TextInput } from 'components';
import React from 'react';
import { formSx } from 'styles';
import { getEventFeedbackIcon } from '../helpers';
import { useFormikContext } from 'formik';
import { ResourceForm, YesNoEnum } from 'types';
import { Box } from '@mui/material';

export const EventFeedbackForm = () => {
  const { values } = useFormikContext<ResourceForm['eventFeedback']>();

  return (
    <Box sx={formSx.formGroup}>
      <FormGroupLayout leftIcon={StarHalfOutlined}>
        <Rating Icon={getEventFeedbackIcon('overall')} label="How did you like the show?" name="overall" />
        <Rating Icon={getEventFeedbackIcon('busyness')} label="How busy was it?" name="busyness" />
        <Rating Icon={getEventFeedbackIcon('sales')} label="How were the sales?" name="sales" />
        <Rating Icon={getEventFeedbackIcon('food')} label="Was there good food available?" name="food" />
        <Rating Icon={getEventFeedbackIcon('artists')} label="Was there a wide range of high-quality artists?" name="artists" />
        <Rating Icon={getEventFeedbackIcon('parking')} label="Was parking easy?" name="parking" />
      </FormGroupLayout>
      <FormGroupLayout leftIcon={FlakyOutlined}>
        <RadioGroupYesNoBoolean
          label="Could you pull up to the spot to unload?"
          Icon={getEventFeedbackIcon('unloadPullUp')}
          name="unloadPullUp"
          row
        />
        <RadioGroupYesNoBoolean
          label="Could you pull up to the spot to pack up?"
          Icon={getEventFeedbackIcon('packUpPullUp')}
          name="packUpPullUp"
          row
        />
        <RadioGroupYesNoBoolean
          label="If we had more help, would we have sold more?"
          Icon={getEventFeedbackIcon('needMoreStaff')}
          name="needMoreStaff"
          row
        />
        <RadioGroupYesNoBoolean
          label="Were sales affected by weather?"
          Icon={getEventFeedbackIcon('weatherAffectedSales')}
          name="weatherAffectedSales"
          row
        />
        <RadioGroupYesNoBoolean
          label="Would you recommend we do this show again?"
          Icon={getEventFeedbackIcon('doAgain')}
          name="doAgain"
          row
        />
      </FormGroupLayout>
      <FormGroupLayout leftIcon={Storefront}>
        <RadioGroupYesNoBoolean
          label="Should we request the same booth location?"
          name="sameBoothLocation"
          row
        />
        {values.sameBoothLocation === YesNoEnum.yes && (
          <TextInput
            multiline
            rows={3}
            name="boothNotes"
            placeholder="Why was this location prime?"
          />
        )}
        {values.sameBoothLocation === YesNoEnum.no && (
          <>
            <TextInput
              multiline
              rows={3}
              name="boothNotes"
              placeholder="Why was this location a poor location?"
            />
            <TextInput
              multiline
              rows={3}
              name="nextYearBoothLocation"
              placeholder="What space numbers or areas should we apply for next year?"
            />
          </>
        )}
        <RadioGroupYesNoBoolean
          label="Should we consider a larger booth next year?"
          name="nextYearLargerBooth"
          row
        />
        <RadioGroupYesNoBoolean
          label="Is the show large enough where we could have done multiple booth locations? "
          name="nextYearMultipleLocations"
          row
        />
      </FormGroupLayout>
      <FormGroupLayout leftIcon={CreateOutlined}>
        <TextInput multiline rows={3} name="nextYearRecommendations" placeholder="Was there anything youd recommend we do differently or have for next year?" />
        <TextInput multiline rows={3} name="complaints" placeholder="Were there any issues or complaints worth noting involving the event staff or vendors?" />
        <TextInput multiline rows={4} name="note" label="Notes" />
      </FormGroupLayout>
    </Box>
  );
};