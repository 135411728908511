import { Box, Button, Card, Container, Divider, FormHelperText, List, Typography } from '@mui/material';
import { CardContentScrollContainer, Loading, SaleProductListItem, useAlertSnackbar } from 'components';
import { getFrameEnumFromValue, getLogLiveSaleNote, orderEnumHelpers, saleEnumHelpers } from 'helpers';
import { DiscountEnum, LogSaleStepEnum, listDividersSx, useLogSaleContext } from '../utils';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { currencyFormatter } from 'services';
import { DiscountChip } from '../components/DiscountChip.component';
import { TotalsOverview } from '../components/TotalsOverview.component';
import { Formik } from 'formik';
import { OrderPaymentEnum } from 'api/resources';
import { SchemaOf, boolean, mixed, object, string } from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { CreateLiveSaleBaseInput, LoggingPortalResourceTypeEnum, createLiveSaleForEventDate, createLiveSaleForStorefront } from 'api/actions';
import { useLoggingPortalContext } from 'pages/LoggingPortal/LoggingPortal.base';
import { PaymentTypeSelect } from 'components/Order/PaymentTypeSelect.component';
import { ShipmentInfoModal } from '../components/ShipmentInfo.modal';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';

export type PaymentStepProps = {
  userId: string;
};

type PaymentStepForm = {
  payment: OrderPaymentEnum | null;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

type PaymentStepFormValidated = {
  payment: OrderPaymentEnum;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

const paymentStepFormValidationSchema: SchemaOf<PaymentStepForm> = object().shape({
  payment: mixed<OrderPaymentEnum>().oneOf(orderEnumHelpers.payment.enumValues).required('Please select a payment type'),

  customerName: string().default('').when('customerEmail', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer name'),
  }),
  customerEmail: string().email('Please enter a valid email').default('').when('customerName', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer email'),
  }),
  sendReceipt: boolean().default(true),
}, [ [ 'customerName', 'customerEmail' ], [ 'customerEmail', 'customerName' ] ]);

const initialValues: PaymentStepForm = {
  payment: null,

  customerName: '',
  customerEmail: '',
  sendReceipt: true,
};

export const PaymentStep: React.FC<PaymentStepProps> = props => {
  const { resourceId, resourceType, resourceLabel, ...data } = useLoggingPortalContext();
  const { state, onSetStep, saleProductsTransformed, onClearAndExit } = useLogSaleContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const confirmSaleMutation = useMutation({
    mutationFn: async (form: PaymentStepFormValidated) => {
      const otherDiscountsTotal = state.discounts.reduce((acc, discount) => {
        switch (discount) {
          case DiscountEnum.fiveOff:
            return acc - 5;
          default:
            return acc;
        }
      }, 0);

      const discountPer = +((otherDiscountsTotal / saleProductsTransformed.length).toFixed(2));
      let filler = 0;

      if (discountPer * saleProductsTransformed.length !== otherDiscountsTotal) {
        filler = +((otherDiscountsTotal - (discountPer * saleProductsTransformed.length)).toFixed(2));
      }

      const sales = saleProductsTransformed.map(({ product, price, totalDiscount, frame, variantId, shipping }, idx, { length }): CreateLiveSaleBaseInput['sales'][number] => {
        let salePrice = price + totalDiscount + discountPer;

        if (filler && idx === length - 1) {
          salePrice = +((salePrice + filler).toFixed(2));
        }

        return {
          product: product._id,
          salePrice,
          frame,
          variantId,
          shipping,
        };
      });

      const customer = state.shipmentInfo.email ? {
        firstName: state.shipmentInfo.firstName,
        lastName: state.shipmentInfo.lastName,
        email: state.shipmentInfo.email,
        place: state.shipmentInfo.place,
        phone: state.shipmentInfo.phone,
      } : undefined;

      const shippingColorVinylProductNames = saleProductsTransformed
        .filter(saleProduct => saleProduct.colorVinyl && saleProduct.shipping)
        .map(({ product, variantTitle }) => `${product.name} (${variantTitle})`);

      const note = getLogLiveSaleNote({
        shippingColorVinylProductNames,
        resourceLabel,
        userName: state.validatedUser!.name,
        customerNote: state.shipmentInfo.notes
      });

      if ('eventDate' in data) {
        return await createLiveSaleForEventDate({
          eventDate: data.eventDate._id,
          sales,
          customer,
          payment: form.payment,
          user: props.userId,
          note,
        });
      }

      return await createLiveSaleForStorefront({
        storefront: data.storefront._id,
        sales,
        customer,
        payment: form.payment,
        user: props.userId,
        note,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        resourceType ===  LoggingPortalResourceTypeEnum.eventDate
          ? QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(resourceId)
          : QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(resourceId)
      );
      snackbar.success('Sale logged');
      onClearAndExit();
    },
    onError: () => {
      snackbar.error('Unable to log sale. Please try again or contact management.');
    }
  });

  const getPaymentTypeSelectDisabled = (paymentType: OrderPaymentEnum) => {
    if (paymentType === OrderPaymentEnum.cash) {
      if (saleProductsTransformed.some(saleProduct => !saleProduct.frame)) {
        return 'Only available for framed products';
      }
    }

    if (paymentType === OrderPaymentEnum.split) {
      return 'Split payments will be available soon';
    }

    return false;
  };

  const [ shipmentInfoModalOpen, setShipmentInfoModalOpen ] = useState(false);

  return (
    <>
      {shipmentInfoModalOpen && <ShipmentInfoModal isEdit onClose={() => setShipmentInfoModalOpen(false)} />}
      <Formik
        onSubmit={(values: PaymentStepFormValidated) => confirmSaleMutation.mutateAsync(values)}
        initialValues={initialValues}
        validationSchema={paymentStepFormValidationSchema}
      >
        {formik => {
          const paymentError = formik.touched.payment && formik.errors.payment;

          if (formik.isSubmitting) {
            return <Loading />;
          }

          return (
            <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="md">
              <NavigateButtons
                onNext={() => formik.handleSubmit()}
                onBack={() => onSetStep(LogSaleStepEnum.discount)}
              />
              <Box display="flex" gap={2} mx={{ xs: -2, sm: 0 }} flexWrap="wrap">

                <Box flex={1} display="flex" flexDirection="column" gap={2} minWidth={{ xs: 'unset', sm: '400px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1} maxWidth={{ xs: '100vw', sm: 'unset' }} >

                    <Card variant="outlined" sx={{ flex: 1 }}>
                      <Box display="flex" flexDirection="column" height="100%">
                        <Box flex={1}>
                          <List disablePadding sx={listDividersSx()}>
                            {saleProductsTransformed.map(({ product, id, frame, total, price, imageSrc, variantId }) => {
                              const frameLabel = saleEnumHelpers.frame.getLabel(getFrameEnumFromValue(frame));

                              return (
                                <SaleProductListItem
                                  key={id}
                                  name={product.name}
                                  isProductImagesDisplay={!variantId}
                                  imageUrl={imageSrc}
                                  secondary={frameLabel}
                                  actions={(
                                    <Box display="flex" alignItems="center" gap={1}>
                                      {price !== total && (
                                        <Typography sx={{ textDecoration: 'line-through', textDecorationColor: theme => theme.palette.error.light }}>
                                          {currencyFormatter.format(price)}
                                        </Typography>
                                      )}
                                      <Typography fontWeight={500}>
                                        {currencyFormatter.format(total)}
                                      </Typography>
                                    </Box>
                                  )}
                                />
                              );
                            })}
                          </List>
                        </Box>
                        <Divider />
                        <Box p={2} display="flex" justifyContent={state.discounts.length ? 'flex-end' :'space-between'} gap={2}>
                          <Box flex={1} display="flex" flexDirection="column" gap={1}>
                            {state.discounts.map((discount) => <DiscountChip key={discount} discount={discount} />)}
                          </Box>
                          <TotalsOverview />
                        </Box>
                      </Box>

                    </Card>

                    {!!state.shipmentInfo.email && (
                      <Card variant="outlined">
                        <Box p={2} display="flex" flexDirection="column" alignItems="flex-end" gap={1} maxWidth={{ xs: '100vw', sm: 230 }} height="100%">
                          <Typography
                            fontWeight={500}
                            textAlign="right"
                          >
                            {state.shipmentInfo.firstName} {state.shipmentInfo.lastName}
                          </Typography>
                          <Box display="flex" flexDirection="column" alignItems="flex-end">
                            <Typography variant="body2" textAlign="right">
                              {state.shipmentInfo.place.address}
                            </Typography>
                            {state.shipmentInfo.place.address2 && (
                              <Typography variant="caption" textAlign="right">
                                 Address line 2: {state.shipmentInfo.place.address2}
                              </Typography>
                            )}
                          </Box>
                          <Box display="flex" flexDirection="column" alignItems="flex-end">
                            <Typography variant="body2" fontWeight={500} textAlign="right">
                              {state.shipmentInfo.email}
                            </Typography>
                            <Typography variant="body2" fontWeight={500} textAlign="right">
                              {state.shipmentInfo.phone}
                            </Typography>
                          </Box>
                          {state.shipmentInfo.notes && (
                            <Box flex={1} display="flex" flexDirection="column" justifyContent="flex-end" width="100%">
                              <Box mx={-2} py={1}><Divider /></Box>
                              <CardContentScrollContainer maxHeight={saleProductsTransformed.length * 50}>
                                <Typography
                                  variant="body2"
                                  textAlign="right"
                                >
                                  {state.shipmentInfo.notes}
                                </Typography>
                              </CardContentScrollContainer>
                              <Box mx={-2} py={1}><Divider /></Box>
                            </Box>
                          )}
                          <Button fullWidth size="small" variant="contained" endIcon={<Edit />} onClick={() => setShipmentInfoModalOpen(true)}>Edit</Button>
                        </Box>
                      </Card>
                    )}

                  </Box>


                  <PaymentTypeSelect
                    selected={formik.values.payment}
                    onSelect={(paymentType) => formik.setFieldValue('payment', paymentType)}
                    getDisabled={getPaymentTypeSelectDisabled}
                  />

                  {Boolean(paymentError) && (
                    <FormHelperText error sx={{ textAlign: 'center', fontSize: theme => theme.typography.body2.fontSize }}>
                      {paymentError}
                    </FormHelperText>
                  )}
                </Box>
              </Box>

            </Container>
          );
        }}
      </Formik>
    </>
  );
};