import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean, Select, TextInput } from 'components';
import { useFormikContext } from 'formik';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper } from 'helpers';
import { useMemo } from 'react';
import { formSx } from 'styles';
import { EventResourceForm, UnknownEnum, YesNoEnum } from 'types';

export const JuryFeeForm: React.FC = () => {
  const juryFeePaymentTypeOptions = useMemo(() => [
    ...getSelectOptionsFromEnumHelper(eventEnumHelpers.paymentType),
    {
      value: UnknownEnum.unknown,
      label: 'Unknown'
    }
  ], []);
  const { values } = useFormikContext<EventResourceForm['juryFee']>();

  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="juryFeeRequired"
        label="Is There a Jury Fee? *"
        includeUnknown
        row
      />
      {values.juryFeeRequired === YesNoEnum.yes && (
        <>
          <TextInput
            name="juryFeeAmount"
            label="Jury Fee Amount"
            type="number"
          />
          <RadioGroupYesNoBoolean
            name="juryFeePaid"
            label="Is Jury Fee Paid?"
            includeUnknown
            row
          />
          <Select
            options={juryFeePaymentTypeOptions}
            name="juryFeePaymentType"
            label="Jury Fee Payment Type"
            disabled={values.juryFeePaid !== YesNoEnum.yes}
          />
        </>
      )}
    </Box>
  );
};