import { GridColDef } from '@mui/x-data-grid';
import { StaffList, StandardDateCell, Table } from 'components';
import React from 'react';
import { UserStaffStatusCell } from './UserStaffStatusCell.component';
import { useTableActionColumn } from 'hooks';
import { User } from 'api/resources';
import { GetEventDatesForUserResponse } from 'api/actions';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { DateService } from 'services';

const profileScheduleTableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Event Name',
    width: 200,
  },
  {
    field: 'date',
    headerName: 'Date',
    valueGetter: ({ value }) => new Date(value),
    width: 120,
    type: 'date',
    renderCell: StandardDateCell,
  },
  {
    field: 'start',
    headerName: 'Start Time',
    width: 90,
  },
  {
    field: 'end',
    headerName: 'End Time',
    width: 90,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    valueGetter: (params) => params.row.status,
    renderCell: UserStaffStatusCell,
  },
  {
    field: 'staff',
    headerName: 'Staff',
    width: 200,
    valueGetter: (params) => params.row.staff,
    renderCell: ({ value }) => <StaffList staffList={value} />
  },
];

const createRows = (eventDates: GetEventDatesForUserResponse['data'], user: User) => {
  return eventDates.map(date => {
    const staff = date.staff.find((staff) => staff.user._id === user._id);

    return {
      status: staff ? staff.status : undefined,
      eventId: date.event._id,
      _id: date._id,
      name: date.event.name,
      date: date.dateAsUtc,
      start: DateService.getFormattedTime(date.startTime),
      end: DateService.getFormattedTime(date.endTime),
      staff: date.staff,
    };
  });
};

type ProfileScheduleTableProps = {
  eventDates: GetEventDatesForUserResponse['data'];
  loading: boolean;
  user: User;
};

export const ProfileScheduleTable: React.FC<ProfileScheduleTableProps> = props => {
  const { withActionColumn } = useTableActionColumn({ routeTo: 'events', getNavigateTo: (id, row) => DYNAMIC_ROUTES.eventDate.createLink({ eventId: row.eventId, eventDateId: id }) });
  const columns = withActionColumn(profileScheduleTableColumns);
  const rows = createRows(props.eventDates, props.user);

  return (
    <Table
      getRowId={(x) => x._id}
      rows={rows}
      columns={columns}
      loading={props.loading}
      disableRowSelectionOnClick
    />
  );
};
