import { GetEventOrganizerResponse } from 'api/actions';
import { Loading } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useEventOrganizer } from 'queries';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';

type UseEventOrganizerOutletContext = {
  eventOrganizer: GetEventOrganizerResponse['data'];
  loading: boolean;
};

export const useEventOrganizerOutletContext = () => useOutletContext<UseEventOrganizerOutletContext>();

const EventOrganizerBaseRaw: React.FC<{eventOrganizerId: string}> = ({ eventOrganizerId }) => {
  const { data: eventOrganizer, isInitialLoading: loading } = useEventOrganizer(eventOrganizerId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!eventOrganizer) {
    return <Navigate to={ROUTING_CONFIG.eventOrganizers} replace />;
  }

  return <Outlet context={{ eventOrganizer, loading }} />;
};

export const EventOrganizerBase = () => {
  const { eventOrganizerId } = DYNAMIC_ROUTES.eventOrganizer.useParams();

  if (!eventOrganizerId) {
    return <Navigate to={ROUTING_CONFIG.eventOrganizers} replace />;
  }

  return <EventOrganizerBaseRaw eventOrganizerId={eventOrganizerId} />;
};