import { Dictionary, EventInput } from '@fullcalendar/react';
import { Theme } from '@mui/material';
import { GetEventsResponse, GetTeamsResponse, GetUserAvailabilityResponse } from 'api/actions';
import { EventDate } from 'api/resources';
import { userEnumHelpers } from 'helpers';
import flatten from 'lodash/flatten';
import { DateObjectType, DateService } from 'services';
import { EventContentArg } from 'types';

export const eventOrder = <ExtendedProps extends Dictionary>(a: EventContentArg<ExtendedProps>['event'], b: EventContentArg<ExtendedProps>['event']) => {
  const aTeam = a.extendedProps.event.team;
  const bTeam = b.extendedProps.event.team;

  if (!aTeam) {
    return 1;
  }

  if (!bTeam) {
    return -1;
  }

  return aTeam.name < bTeam.name ? -1 : 1;
};

export const getEventDateGroups = <T extends Pick<EventDate, 'dateAsUtc'>>(eventDates: T[]) => {
  const sortedDates = [ ...eventDates ].sort((a, b) => a.dateAsUtc.localeCompare(b.dateAsUtc));

  let groups = [ [ sortedDates[0] ] ];
  let latestDate = sortedDates[0].dateAsUtc;

  for (let i = 1; i < sortedDates.length; i += 1) {
    const currentDate = sortedDates[i].dateAsUtc;
    const currentDateObject = DateService.dayjsTz(currentDate);
    const latestDateObject = DateService.dayjsTz(latestDate);
    const diff = currentDateObject.diff(latestDateObject, 'day');

    if (diff > 1) {
      groups = [ ...groups, [ sortedDates[i] ] ];
    } else {
      groups[groups.length - 1] = [ ...groups[groups.length - 1], sortedDates[i] ];
    }

    latestDate = currentDate;
  }

  return groups;
};

export const getEventDatesStartAndEnd = <T extends Pick<EventDate, 'dateAsUtc'>>(eventDates: T[]) => {
  const sortedDates = [ ...eventDates ].sort((a, b) => a.dateAsUtc.localeCompare(b.dateAsUtc));

  const first = sortedDates[0];
  const last = sortedDates[sortedDates.length - 1];

  const start = DateService.dayjsTz(first.dateAsUtc).startOf('day');
  const end = DateService.dayjsTz(last.dateAsUtc).add(1, 'day').startOf('day');

  return { start, end };
};

// Events Calendar

export type EventsCalendarEventExtendedProps = {
  event: GetEventsResponse['data'][number];
  eventDate: GetEventsResponse['data'][number]['dates'][number];
  group: GetEventsResponse['data'][number]['dates'];
};

export const convertEventsForEventsCalendar = (events: GetEventsResponse['data'], spanEvents: boolean) => {
  return events.reduce((eventInputs, event) => {
    if (event.dates.length) {
      const groups = spanEvents
        ? getEventDateGroups(event.dates)
        : event.dates.map(eventDate => [ eventDate ]);

      const convertedEvents = groups.map((group): EventInput => {
        const { start, end } = getEventDatesStartAndEnd(group);

        const extendedProps: EventsCalendarEventExtendedProps = {
          event: event,
          eventDate: group[0],
          group: group,
        };

        return {
          title: event.name,
          start: start.toDate(),
          end: end.toDate(),
          id: group[0]._id,
          allDay: true,
          extendedProps: extendedProps,
        };
      });

      return [ ...eventInputs, ...convertedEvents ];
    }

    return eventInputs;
  }, []);
};

export const convertUserAvailabilityForCalendarBackground = (availabilitySlots: GetUserAvailabilityResponse['data'], theme: Theme) => {
  return availabilitySlots?.map((slot) => {
    const color = userEnumHelpers.availability.getColor(slot.availability);

    const result = {
      ...slot,
      start: DateService.dayjsTz(slot.startDateAsUtc).toISOString(),
      end: DateService.dayjsTz(slot.endDateAsUtc).add(1, 'day').toISOString(),
      display: 'background',
      color: theme.palette[color].background,
      allDay: true
    };

    return result;
  });
};

export type DragAndDropEventsCalendarEventExtendedProps = {
  allowedStart: DateObjectType;
  allowedEnd: DateObjectType;
  editable: boolean;

  event: GetEventsResponse['data'][number];
  eventDate: GetEventsResponse['data'][number]['dates'][number];
  group: GetEventsResponse['data'][number]['dates'];
  isFilteredOut: boolean;
};

export const convertEventsForDragAndDropEventsCalendar = (events: GetEventsResponse['data'], filteredEvents?: GetEventsResponse['data']) => {
  return events.reduce((allEventDates, event) => {
    if (event.dates.length) {
      const groups = getEventDateGroups(event.dates);

      const events = groups.map((group): EventInput => {
        const { start, end } = getEventDatesStartAndEnd(group);

        const extendedProps: DragAndDropEventsCalendarEventExtendedProps = {
          allowedStart: start,
          allowedEnd: end,
          event: event,
          eventDate: group[0],
          group: group,
          editable: true,
          isFilteredOut: !!filteredEvents && !filteredEvents?.some(filteredEvent => filteredEvent._id === event._id),
        };

        return {
          title: event.name,
          start: start.toDate(),
          end: end.toDate(),
          id: group[0]._id,
          allDay: true,
          extendedProps: extendedProps,
          editable: true,
        };
      });

      return [ ...allEventDates, ...events ];
    }
    return allEventDates;
  }, []);
};

export const getConvertedEventsPerTeam = (teams: GetTeamsResponse['data'], events: GetEventsResponse['data'], filteredEvents?: GetEventsResponse['data']): Record<string, EventInput[]> => {
  const convertedEventsPerTeam = teams.reduce((r, team) => ({
    ...r,
    [team._id]: convertEventsForDragAndDropEventsCalendar(events.filter(e => e.team?._id === team._id), filteredEvents?.filter(e => e.team?._id === team._id))
  }), {} as Record<string, EventInput[]>);

  return {
    ...convertedEventsPerTeam,
    'no_team': convertEventsForDragAndDropEventsCalendar(events.filter(e => !e.team), filteredEvents?.filter(e => !e.team)),
  };
};

// Events summary

export type EventsSummaryHash = Record<string, {
  events: GetEventsResponse['data'];
  eventDates: GetEventsResponse['data'][number]['dates'];
}>;

export const getEventsSummaryHash = (events: GetEventsResponse['data']) => {
  const datesHash = flatten(events.map(event => event.dates)).reduce((r, eventDate) => {
    const key = DateService.dayjsTz(eventDate.dateAsUtc).format('W/YYYY');

    return {
      ...r,
      [key]: [ ...(r[key] ?? []), eventDate ],
    };
  }, {} as Record<string, GetEventsResponse['data'][number]['dates']>);

  return Object.keys(datesHash).reduce((r, key: keyof typeof datesHash) => {
    const eventIds = datesHash[key].map(d => d.event);
    const _events = events.filter(e => eventIds.includes(e._id));

    return { ...r, [key]: { eventDates: datesHash[key], events: _events } };
  }, {} as Record<string, {
    eventDates: GetEventsResponse['data'][number]['dates'];
    events: GetEventsResponse['data'];
  }>);
};