import { Skeleton, Box, Typography, Divider, Button, Card, ListItemButton } from '@mui/material';
import { GetEventsForDashboardResponse, LoggingPortalResourceTypeEnum } from 'api/actions';
import { ScrollableView, EventWithEventDateContent, useEventPreviewDrawerContext } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { DateService } from 'services';
import { EVENTS_OVERVIEW_CARD_HEIGHT, EVENTS_OVERVIEW_CARD_WIDTH } from './EventsOverview.component';
import { getEventLodgingPlaceForDate } from 'helpers';

export type EventsOverviewUpcomingEventsCardProps = {
  upcomingAttendEvents: GetEventsForDashboardResponse['data']['upcomingAttendEvents'];
  loading?: boolean;
};

export const EventsOverviewUpcomingEventsCard: React.FC<EventsOverviewUpcomingEventsCardProps> = ({ loading, upcomingAttendEvents }) => {
  const navigate = useNavigate();
  const { setOpenEvent } = useEventPreviewDrawerContext();

  if (loading) {
    return <Skeleton variant="rectangular" height={EVENTS_OVERVIEW_CARD_HEIGHT} sx={{ flex: 1 }} />;
  }

  return (
    <Card variant="outlined" sx={{ flex: 1, borderRadius: 0, minWidth: EVENTS_OVERVIEW_CARD_WIDTH }}>
      <Box m={2}>
        <Typography fontWeight={500} variant="h5">{upcomingAttendEvents.length}</Typography>
        <Typography variant="body2" color="text.secondary" mb={3}>Confirmed upcoming event{upcomingAttendEvents.length === 1 ? '' : 's'}</Typography>
        <Divider />
        <ScrollableView height="410px" enableScrollShadow>
          {upcomingAttendEvents.map((event, index) => {
            const isToday = DateService.dayjs().isSame(DateService.dayjsTz(event.upcomingEventDate.dateAsUtc), 'day');

            return (
              <ListItemButton key={event._id} onClick={() => setOpenEvent(event._id, 'main')} disableTouchRipple>
                <Box mb={3}>
                  {!!index && <Divider />}
                  <EventWithEventDateContent
                    data={{
                      event,
                      eventDate: event.upcomingEventDate,
                      eventDates: event.dates,
                      startDate: event.startDate,
                      endDate: event.endDate,
                      travelData: {
                        studioToEventMatrix: event.place.fromStudioMatrix,
                        lodgingToEventMatrix: getEventLodgingPlaceForDate(event, DateService.dayjsTz(event.upcomingEventDate.dateAsUtc))?.place.toEventMatrix,
                      }
                    }}
                    disableActionsDivider
                    actions={(
                      <>
                        {isToday && (
                          <Box mb={1}>
                            <Button variant="contained" onClick={() => navigate(DYNAMIC_ROUTES.loggingPortal.createLink({ resourceType: LoggingPortalResourceTypeEnum.eventDate, resourceId: event.upcomingEventDate._id }))} fullWidth>Go To Logging Portal</Button>
                          </Box>
                        )}
                        <Box display="flex" gap={1}>
                          <Button variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.event.createLink({ eventId: event._id }))} sx={{ flex: 1 }}>Go To Event</Button>
                          <Button variant="contained" onClick={() => navigate(DYNAMIC_ROUTES.eventDate.createLink({ eventId: event._id, eventDateId: event.upcomingEventDate._id }))} sx={{ flex: 1 }}>Go To Event Date</Button>
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </ListItemButton>
            );
          })}
        </ScrollableView>
        <Divider />
      </Box>
    </Card>
  );
};