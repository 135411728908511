import { useProductsStockUpdateLogs } from 'queries';
import { useTeamOutletContext } from '../Team.base';
import { ProductStockUpdateLogTransferTypeEnum, ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { useMemo } from 'react';
import { DateService } from 'services';
import { AnalyticsTimelineChart } from 'components';

export const TeamInventoryChart = () => {
  const { team } = useTeamOutletContext();
  const { data: updateLogs = [], isInitialLoading: loading } = useProductsStockUpdateLogs({ teamId: team._id });
  const productionUpdateLogs = updateLogs.filter(updateLog => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.unary || (updateLog.transferStock?.team._id === team._id && updateLog.transferType === ProductStockUpdateLogTransferTypeEnum.to));
  const totalProduced = productionUpdateLogs.reduce((r, updateLogs) => r + updateLogs.newQuantity - updateLogs.previousQuantity, 0).toString();
  const dateRange = useMemo(() => {
    const dateRangeStart = productionUpdateLogs.reduce((r, updateLog) => r.isAfter(updateLog.createdAt) ? DateService.dayjs(updateLog.createdAt) : r, DateService.dayjs());

    return { start: dateRangeStart, end: DateService.dayjs() };
  }, [ productionUpdateLogs ]);

  return (
    <AnalyticsTimelineChart
      header={team.isWarehouse ? 'Production' : 'Picking'}
      subheader={totalProduced}
      dateRange={dateRange}
      config={{ baseConfig: { data: productionUpdateLogs, getDate: (updateLog) => DateService.dayjs(updateLog.createdAt), getValue: (updateLog) => updateLog.newQuantity - updateLog.previousQuantity, dateRange } }}
      loading={loading}
    />
  );
};