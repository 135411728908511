import { LogSaleLayout } from './components/LogSaleLayout.component';
import { LogSaleContextProvider, LogSaleStepEnum, getLoggingPortalTypeRoute, useLogSaleContext } from './utils';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { Navigate } from 'react-router-dom';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { ProductsStep } from './steps/ProductsStep.component';
import { DiscountAndShippingStep } from './steps/DiscountAndShippingStep.component';
import { PaymentStep } from './steps/PaymentStep.compoent';
import { ValidateUser } from './steps/ValidateUser.component';

export type LogSaleRawProps = {
  userId: string;
};

export const LogSaleRaw: React.FC<LogSaleRawProps> = props => {
  const { state } = useLogSaleContext();

  if (state.validatedUser?._id !== props.userId) {
    return <ValidateUser userId={props.userId} />;
  }

  const stepHash: Record<LogSaleStepEnum, React.ReactNode> = {
    [LogSaleStepEnum.products]: <ProductsStep />,
    [LogSaleStepEnum.discount]: <DiscountAndShippingStep />,
    [LogSaleStepEnum.payment]: <PaymentStep userId={props.userId} />,
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{stepHash[state.step]}</>;
};

export const LogSale: React.FC = () => {
  const { userOptions, resourceType, resourceId } = useLoggingPortalContext();
  const userId = DYNAMIC_ROUTES.loggingPortalLogSale.useQueryParam('user');

  if (!userId || !userOptions.find(user => user._id === userId)) {
    return <Navigate to={DYNAMIC_ROUTES.loggingPortal.createLink({ resourceType: getLoggingPortalTypeRoute(resourceType), resourceId })} replace />;
  }

  return (
    <LogSaleContextProvider>
      <LogSaleLayout userId={userId}>
        <LogSaleRaw userId={userId} />
      </LogSaleLayout>
    </LogSaleContextProvider>
  );
};