import { ProductStock } from 'api/resources';
import { InventoryRow, InventoryTableLatestUpdates, SaveInventoryEditsParams } from './types';
import { SxProps, Theme } from '@mui/material';

export const getProductTeamStock = (product: InventoryRow, teamId: string) => product.stocks[teamId];
export const getProductTeamStockQuantity = (product: InventoryRow, teamId: string, getStockQuantity: (stock: ProductStock) => number) => getStockQuantity(getProductTeamStock(product, teamId));

export const onSetLatestUpdatesHandler = (params: SaveInventoryEditsParams, setLatestUpdates: React.Dispatch<React.SetStateAction<InventoryTableLatestUpdates>>) => {
  const { transferConfig, edits } = params;

  setLatestUpdates((currentLatest) => {
    return Object.entries(edits).reduce((allLatest, [ productId, productUpdates ]) => {

      const jawns = Object.keys(productUpdates).reduce((productLatest, teamId) => {
        const timestamp = new Date().toISOString();

        if (transferConfig && params.isTransferOperation) {
          return { ...productLatest, [teamId]: timestamp, [transferConfig.out]: timestamp };
        }

        return { ...productLatest, [teamId]: new Date().toISOString() };
      }, currentLatest[productId] ?? {});

      return { ...allLatest, [productId]: jawns };
    }, currentLatest);
  });
};

export const inventoryTableSx: SxProps<Theme> = {
  minHeight: '700px',
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiDataGrid-root .Mui-hovered': {
    backgroundColor: 'transparent',
  },
  '& .inventoryTableCell': {
    px: 1,
  },
  '& .cellHasEdit': {
    background: theme => theme.palette.grey[50]
  },
  '& .rowHighlight': {
    '& .productName': {
      position: 'relative',
      pl: 2,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4px',
        height: '100%',
        background: theme => theme.palette.success.main,
      },
    },
  },
  '& .rowHasErrors': {
    '& .productName': {
      '&::after': {
        background: theme => theme.palette.error.main,
      }
    }
  }
};