import { createEventToDo, deleteEventToDo, updateEventToDo } from 'api/actions';
import { SectionCard, useEventContext } from 'components';
import { processFormValueUpdate, removeUnchanged } from 'helpers';
import React from 'react';
import { DateService } from 'services';
import { useCurrentUser } from 'contexts';
import { EventSectionCardRowEditableList, ToDoListItem } from '../components';
import { EventToDoForm, eventToDoValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';

export const ToDosSection = () => {
  const { event, invalidateQueriesHandler } = useEventContext();
  const { _id } = useCurrentUser();

  return (
    <SectionCard title="To Dos" id={EventPageSection.TO_DOS}>
      <EventSectionCardRowEditableList
        title="To Dos"
        listItems={event.toDos}
        rowId={EventPageSectionRowId.TO_DO}
        renderItem={(toDo) => <ToDoListItem toDo={toDo} event={event} invalidateQueriesHandler={invalidateQueriesHandler} />}
        form={<EventToDoForm />}
        createButtonLabel="To Do"
        orderBy={{
          getField: (listItem) => listItem.dateAsUtc ? listItem.dateAsUtc : '',
          direction: 'desc',
        }}
        getFormikProps={(listItem) => ({
          initialValues: {
            title: listItem.title,
            date: listItem.dateAsUtc ? DateService.dayjs(listItem.dateAsUtc).toISOString() :  null,
            description: listItem.description ?? '',
            assignees: listItem.assignees.map(({ _id }) => _id) ?? [],
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEventToDo(event._id, listItem._id, {
              title: updates.title,
              date: updates.date,
              description: processFormValueUpdate.string(updates.description),
              assignees: updates.assignees as string[] | undefined,
            });
          },
          validationSchema: eventToDoValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            title: '',
            date: null,
            description: '',
            assignees: [ _id ],
          },
          onSubmit: (values) => createEventToDo(event._id, {
            title: values.title,
            date: values.date ?? undefined,
            description: processFormValueUpdate.string(values.description) || undefined,
            assignees: values.assignees,
          }),
          validationSchema: eventToDoValidationSchema,
        }}
        deleteMutationFn={(todo) => deleteEventToDo(event._id, todo._id)}
      />
    </SectionCard>
  );
};
