import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { GetProductsProductionStockUpdateLogsInput, GetProductsProductionStockUpdateLogsResponse, GetProductsStockUpdateLogsInput, GetProductsStockUpdateLogsResponse, getProductsProductionStockUpdateLogs, getProductsStockUpdateLogs } from 'api/actions';

export const useProductsStockUpdateLogs = (input?: GetProductsStockUpdateLogsInput, opts?: UseQueryOptions<GetProductsStockUpdateLogsResponse['data']>) => {
  return useQuery(QUERY_KEY.PRODUCTS_STOCK_UPDATE_LOGS(input), async () => {
    const response = await getProductsStockUpdateLogs(input);

    return response.data;
  }, opts);
};

export const useProductsProductionStockUpdateLogs = (input?: GetProductsProductionStockUpdateLogsInput, opts?: UseQueryOptions<GetProductsProductionStockUpdateLogsResponse['data']>) => {
  return useQuery(QUERY_KEY.PRODUCTS_PRODUCTION_STOCK_UPDATE_LOGS(input), async () => {
    const response = await getProductsProductionStockUpdateLogs(input);

    return response.data;
  }, opts);
};