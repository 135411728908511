import React, { useCallback, useMemo } from 'react';
import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { Button } from '@mui/material';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { BeforeUnloadPrompt, InventoryEdits, InventoryTableEditableMode, InventoryTableSettingsModal, InventoryTableStandardFiltersInput, PreviosQuantitiesCell, StandardInventoryTableFilters, Table, useAlertSnackbar, useInventoryTable } from 'components';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, InventoryBatchUpdate, UserEmployeeRoleEnum } from 'api/resources';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { GetInventoryBatchResponse, GetProductsResponse, updateInventoryBatch, UpdateInventoryBatchInput, UpdateInventoryBatchStatusEnum } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { AxiosError } from 'axios';
import { getInitialTransferConfig } from '../helpers';
import { CancelBatchButton } from '../components/CancelBatchButton.component';
import { GridColDef } from '@mui/x-data-grid';

const getEditReviewQuantity = (batch: GetInventoryBatchResponse['data'], update: GetInventoryBatchResponse['data']['updates'][number], warehouseTeamId: string, inventoryEdits: InventoryEdits) => {
  const edit = inventoryEdits[update.product]?.[batch.type === InventoryBatchTypeEnum.overstockPicking ? warehouseTeamId : batch.team._id];
  const reviewQuantity = edit?.value ?? 0;

  return reviewQuantity;
};

const prepareInventoryBatchEditsToSave = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string, inventoryEdits: InventoryEdits, isManagerReview: boolean) => {
  const batchProductUpdates = batch.updates
    .map((update) => {
      const reviewQuantity = getEditReviewQuantity(batch, update, warehouseTeamId, inventoryEdits);

      if (isManagerReview) {
        return {
          ...update,
          managerReviewQuantity: reviewQuantity
        };
      }

      return {
        ...update,
        reviewQuantity,
      };
    });

  const addedProductsUpdates = Object.entries(inventoryEdits)
    .filter(([ productId ]) => !batchProductUpdates.find(update => update.product === productId))
    .reduce((acc: InventoryBatchUpdate[], [ productId, productEdits ]) => {
      return Object.values(productEdits).reduce((acc, inventoryEdit) => {
        if (isManagerReview) {
          const update: InventoryBatchUpdate = {
            quantity: 0,
            reviewQuantity: 0,
            managerReviewQuantity: inventoryEdit.value,
            product: productId,
          };

          return [ ...acc, update ];
        }

        const update: InventoryBatchUpdate = {
          quantity: 0,
          reviewQuantity: inventoryEdit.value,
          product: productId,
        };

        return [ ...acc, update ];
      }, acc);
    }, []);

  return [ ...batchProductUpdates, ...addedProductsUpdates ];
};

export type ReviewProps = {
  isManagerReview?: boolean;
};

export const Review: React.FC<ReviewProps> = ({ isManagerReview = false }) => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const { teams, getProductsSortedAlphabetically } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);

  const initialMode = useMemo(() => {
    if ([ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type)) {
      return InventoryTableEditableMode.damaged;
    }

    if (inventoryBatch.type === InventoryBatchTypeEnum.production) {
      return InventoryTableEditableMode.unary;
    }

    return InventoryTableEditableMode.transfer;
  }, [ inventoryBatch.type ]);

  const getDisplayStandardFilters = useCallback((): StandardInventoryTableFilters[] => {
    if (!inventoryBatch.team.isWarehouse) {
      return [  'showNotAvailableAtEvents', 'onlyShowEdits' ];
    }

    return [ 'onlyShowEdits' ];
  }, [ inventoryBatch.team.isWarehouse ]);

  const getExtraColumns = useCallback((inventoryEdits: InventoryEdits) => {
    if (inventoryBatch.status === InventoryBatchStatusEnum.managerReview) {
      const columns: GridColDef<GetProductsResponse['data'][number]>[] = [
        {
          field: 'intialAndReviewQuantities',
          headerName: 'Previous Quantities',
          headerAlign: 'center',
          valueGetter: ({ row }) => inventoryBatch.updates.find(update => update.product === row._id)?.quantity ?? undefined,
          type: 'number',
          width: [ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type) ? 150 : 250,
          align: 'center',
          renderCell: ({ row }) => {
            return (
              <PreviosQuantitiesCell
                row={row}
                inventoryBatch={inventoryBatch}
                inventoryEdits={inventoryEdits}
              />
            );
          },
        },
      ];

      return columns;
    }
  }, [ inventoryBatch ]);

  const {
    inventoryTableProps,
    editModal,
    hasErrors,
    inventoryEdits,
    hasEdits,
    setSaveLoading,
    onResetEdits,
    settingsModalOpen,
    setSettingsModalOpen,
    standardFilters,
    setStandardFilters,
    transferConfig,
  } = useInventoryTable({
    products: inventoryBatch.status === InventoryBatchStatusEnum.managerReview
      ? products.sort((a, b) => {
        const aUpdate = inventoryBatch.updates.find(update => update.product === a._id);
        const bUpdate = inventoryBatch.updates.find(update => update.product === b._id);

        if (aUpdate && !bUpdate) {
          return -1;
        }

        if (!aUpdate && bUpdate) {
          return 1;
        }

        return 0;
      })
      : products,
    teams,
    getStockQuantity: stock => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? stock.productionQuantity : stock.quantity,
    settingsArgs: {
      initialMode,
      initialTransferConfig,
      initialTeamIds: [ inventoryBatch.team._id ],
      initialShowStockMeta: false,
      getDisplayStandardFilters,
    },
    apiArgs: {
      validateTransferEdit: () => undefined,
    },
    teamColumnsArgs: {
      compactTransferColumn: true,
      getTeamColumnHeaderSecondary: () => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? 'production stock' : null,
    },
    getExtraColumns,
  });

  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async (data) => {
      setSaveLoading(false);
      onResetEdits();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });

      if (data.data.status === InventoryBatchStatusEnum.closed) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      }
    },
    onError: (e: AxiosError) => {
      setSaveLoading(false);
      snackbar.error(e.response?.data?.error || 'Failed to update batch');
    }
  });

  const onComplete = async () => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareInventoryBatchEditsToSave(inventoryBatch, warehouseTeamId, inventoryEdits, isManagerReview),
      status: isManagerReview ? UpdateInventoryBatchStatusEnum.closed : UpdateInventoryBatchStatusEnum.reviewComplete,
    });
  };

  return (
    <InventoryBatchLayout
      actions={(
        <>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && <CancelBatchButton />}
          <Button variant="outlined" onClick={() => navigate(ROUTING_CONFIG.inventoryBatches)} disabled={updateMutation.isLoading}>Cancel Review</Button>
          <Button variant="contained" disabled={hasErrors || updateMutation.isLoading} onClick={onComplete}>Complete Review</Button>
        </>
      )}
    >
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          filterInputs={(
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters()}
              disableFilters={inventoryBatch.team.isWarehouse ? undefined : [ 'showNotAvailableAtEvents' ]}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      <BeforeUnloadPrompt hasEdits={hasEdits} />
      {editModal}
      <Table preserveQuickFilterOnRowsUpdate {...inventoryTableProps} />
    </InventoryBatchLayout>
  );
};
