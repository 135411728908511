import { useCallback, useMemo } from 'react';
import { InventoryEdit, InventoryRow, InventoryTableLatestUpdates, InventoryTableTransferConfig, InventoryTableUpdateValidator } from './types';
import { GetTeamsResponse } from 'api/actions';
import { GridColDef } from '@mui/x-data-grid';
import { getProductTeamStock, getProductTeamStockQuantity } from './helpers';
import { ProductStock } from 'api/resources';
import { TransferLabel } from '../TransferLabel.component';
import { LatestUpdateWrapper } from '../LatestUpdateWrapper.component';
import { Badge, Box, IconButton, Typography } from '@mui/material';
import { StockQtyCellContent } from '../StockQtyCellContent.component';
import { StockMetaCellContent, StockMetaConfig } from '../StockMetaCellContent.component';
import { DateService } from 'services';
import { getProductTeamBaseline } from 'helpers';
import { UpdateQtyCellContent } from '../UpdateQtyCellContent.component';
import { AutoFixHighOutlined } from '@mui/icons-material';
import { TeamCell } from 'components/Table';

export type UseInventoryTableTeamColumnsArgsBase = {
  compactTransferColumn?: boolean;
  disableTransferColumnError?: boolean;
  teamsStockMetaConfig?:  { [teamId: string]: StockMetaConfig };
  getRowInputsDisabled?: (row: InventoryRow) => boolean;
  getTeamColumnHeaderSecondary?: (team: GetTeamsResponse['data'][number]) => string | null;
};

export type UseInventoryTableTeamColumnsArgs = UseInventoryTableTeamColumnsArgsBase & {
  onChange: (value: string, productId: string, teamId: string) => void;
  onEditCell: (productId: string, teamId: string) => void;
  teams: GetTeamsResponse['data'];
  latestUpdates: InventoryTableLatestUpdates;
  getInventoryEdit: (row: InventoryRow, teamId: string) => InventoryEdit | undefined;
  getStockQuantity: (stock: ProductStock) => number;
  getCellInputError: InventoryTableUpdateValidator<{ stock: InventoryRow['stocks'][string]; updateQty: number | undefined }>;
  teamIds: string[];
  showStockProduction: boolean;
  showStockMeta: boolean;
  transferConfig: InventoryTableTransferConfig | null;
  isTransferOperation: boolean;
  isSetOperation: boolean;
};
export const useInventoryTableTeamColumns = ({
  disableTransferColumnError,
  compactTransferColumn,
  getTeamColumnHeaderSecondary,
  onChange,
  onEditCell,
  teamsStockMetaConfig,
  getRowInputsDisabled = () => false,
  teams,
  latestUpdates,
  getInventoryEdit,
  getStockQuantity,
  getCellInputError,
  teamIds,
  showStockProduction,
  showStockMeta,
  transferConfig,
  isTransferOperation,
  isSetOperation,
}: UseInventoryTableTeamColumnsArgs) => {
  const getLatestStockUpdate = useCallback((productId: string, teamId: string) => {
    const latest = latestUpdates?.[productId]?.[teamId];

    if (latest) {
      return DateService.dayjs(latest).fromNow();
    }
  }, [ latestUpdates ]);

  const getTeamColumnBase = useCallback((team: GetTeamsResponse['data'][number]): GridColDef<InventoryRow> => {
    let width = 285;

    if (isTransferOperation) {
      width -= 50;
    }
    if (showStockMeta) {
      width += 25;
    }

    return {
      field: transferConfig ? team._id + '_transfer' : team._id,
      headerName: team.name,
      headerAlign: 'center',
      cellClassName: 'inventoryTableCell',
      width,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) => getProductTeamStockQuantity(row, team._id, getStockQuantity),
    };
  }, [ isTransferOperation, showStockMeta, transferConfig, getStockQuantity ]);

  const teamColumns = useMemo(() => {
    if (transferConfig && isTransferOperation) {
      const { in: inTeamId, out: outTeamId } = transferConfig;
      const inTeam = teams.filter(team => team._id === inTeamId)[0];
      const outTeam = teams.filter(team => team._id === outTeamId)[0];

      if (compactTransferColumn) {
        const compactTransferColumn: GridColDef<InventoryRow> = {
          field: outTeam._id + inTeam._id + '_transfer',
          headerName:  `${outTeam.name} >>> ${inTeam.name}`,
          headerAlign: 'center',
          renderHeader: () => (
            <Box display="inline-flex" alignItems="center" gap={0.5} fontWeight={500} ml={1}>
              <TeamCell team={outTeam} secondary={getTeamColumnHeaderSecondary?.(outTeam)} />
              <Box component="span" color="error.main">(out)</Box>
              <Box component="span" mx={1}>{'>>>'}</Box>
              <TeamCell team={inTeam} secondary={getTeamColumnHeaderSecondary?.(inTeam)} />
              <Box component="span" color="success.main">(in)</Box>
            </Box>
          ),
          disableColumnMenu: true,
          sortable: false,
          filterable: false,
          resizable: false,
          width: 254,
          renderCell: ({ row, field }) => {
            const inventoryEdit = getInventoryEdit(row, inTeamId);
            const inTeamStock = getProductTeamStock(row, inTeamId);
            const isDisabled = getRowInputsDisabled(row);
            const error = getCellInputError({ stock: inTeamStock, updateQty: inventoryEdit?.value });

            return (
              <UpdateQtyCellContent
                disabled={isDisabled}
                loading={inventoryEdit?.loading}
                error={disableTransferColumnError ? undefined : (inventoryEdit?.error ?? error)}
                row={row}
                value={inventoryEdit?.value}
                onChange={(value) => onChange(value, row._id, inTeamId)}
                field={field}
                onEditCell={() => onEditCell(row._id, inTeamId)}
              />
            );
          },
          align: 'center',
        };

        return [ compactTransferColumn ];
      }

      const inColumn: GridColDef<InventoryRow> = {
        ...getTeamColumnBase(inTeam),
        renderHeader: () => <TransferLabel isIn team={inTeam} secondary={getTeamColumnHeaderSecondary?.(inTeam)} />,
        renderCell: ({ row }) => {
          const stockQuantity = getProductTeamStockQuantity(row, inTeamId, getStockQuantity);
          const inventoryEdit = getInventoryEdit(row, inTeamId);

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={getLatestStockUpdate(row._id, inTeamId)}>
              <Box flex={1} textAlign="center">
                <StockQtyCellContent quantity={stockQuantity} updateQuantity={inventoryEdit?.value} />
              </Box>
              {showStockMeta && <StockMetaCellContent config={teamsStockMetaConfig?.[inTeamId]} isSetOperation={isSetOperation} quantity={stockQuantity} updateQuantity={inventoryEdit?.value} baseline={getProductTeamBaseline(row, inTeam)} />}
            </LatestUpdateWrapper>
          );
        }
      };

      const outColumn: GridColDef<InventoryRow> = {
        ...getTeamColumnBase(outTeam),
        renderHeader: () => <TransferLabel isOut team={outTeam} secondary={getTeamColumnHeaderSecondary?.(outTeam)} />,
        renderCell: ({ row }) => {
          const stockQuantity = getProductTeamStockQuantity(row, outTeamId, getStockQuantity);
          const inventoryEdit = getInventoryEdit(row, inTeamId);
          const updateQuantity = inventoryEdit ? -inventoryEdit.value : undefined;

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={getLatestStockUpdate(row._id, outTeamId)} latestUpdatePosition="right">
              {showStockMeta && <StockMetaCellContent config={teamsStockMetaConfig?.[outTeamId]} isSetOperation={isSetOperation} quantity={stockQuantity} updateQuantity={updateQuantity} baseline={getProductTeamBaseline(row, outTeam)} />}
              <Box flex={1} textAlign="center">
                <StockQtyCellContent quantity={stockQuantity} updateQuantity={updateQuantity} />
              </Box>
            </LatestUpdateWrapper>
          );
        }
      };

      const editColumn: GridColDef<InventoryRow> = {
        field: 'action',
        headerName: '>>>',
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'inventoryTableCell',
        width: 170,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row, field }) => {
          const inventoryEdit = getInventoryEdit(row, inTeamId);
          const outTeamStockQuantity = getProductTeamStockQuantity(row, outTeamId, getStockQuantity);
          const outTeamBaseline = getProductTeamBaseline(row, outTeam);
          const inTeamStock = getProductTeamStock(row, inTeamId);
          const inTeamStockQuantity = getProductTeamStockQuantity(row, inTeamId, getStockQuantity);
          const inTeamBaseline = getProductTeamBaseline(row, inTeam);
          const isDisabled = getRowInputsDisabled(row);
          const error = getCellInputError({ stock: inTeamStock, updateQty: inventoryEdit?.value });

          const maxQuantity = outTeamStockQuantity;
          const neededQuantity = transferConfig?.autoFillBase === 'out' ? Math.max(outTeamStockQuantity - outTeamBaseline, 0) : Math.max(inTeamBaseline - inTeamStockQuantity, 0);

          const autoFillValue = Math.min(maxQuantity, neededQuantity);
          const isAutoFilled = inventoryEdit?.value === autoFillValue;

          return (
            <Box display="flex" alignItems="center" gap={1} justifyContent="space-between" width="100%">
              <UpdateQtyCellContent
                disabled={isDisabled}
                loading={inventoryEdit?.loading}
                error={inventoryEdit?.error ?? error}
                row={row}
                value={inventoryEdit?.value}
                onChange={(value) => onChange(value, row._id, inTeamId)}
                field={field}
                onEditCell={() => onEditCell(row._id, inTeamId)}
              />
              <Box flex={1} textAlign="center">
                <IconButton
                  sx={{
                    visibility: autoFillValue && !isAutoFilled && !isDisabled ? 'visible' : 'hidden',
                  }}
                  color="primary"
                  onClick={() => onChange(String(autoFillValue), row._id, inTeamId)}
                >
                  <Badge badgeContent={autoFillValue} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <AutoFixHighOutlined />
                  </Badge>
                </IconButton>
              </Box>
            </Box>
          );
        }
      };

      return [ outColumn, editColumn, inColumn ];
    }

    const selectedTeams = teams.filter(team => teamIds.includes(team._id));

    return selectedTeams.map((team): GridColDef<InventoryRow> => {
      const teamId = team._id;

      return {
        ...getTeamColumnBase(team),
        renderHeader: () => <TeamCell team={team} secondary={getTeamColumnHeaderSecondary?.(team)} />,
        renderCell: ({ row, field }) => {
          const stock = getProductTeamStock(row, teamId);
          const stockQuantity = getProductTeamStockQuantity(row, teamId, getStockQuantity);
          const inventoryEdit = getInventoryEdit(row, teamId);
          const latestUpdate = getLatestStockUpdate(row._id, teamId);
          const isDisabled = getRowInputsDisabled(row);
          const error = getCellInputError({ stock, updateQty: inventoryEdit?.value });
          const value = inventoryEdit?.value;
          const productionQuantity = getProductTeamStock(row, teamId).productionQuantity;
          const updatedProductionQuantity = (value && value > 0) ? productionQuantity - value : undefined;

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={latestUpdate}>
              <Box flex={1} textAlign="center">
                <StockQtyCellContent isSetOperation={isSetOperation} quantity={stockQuantity} updateQuantity={value} />
              </Box>
              <UpdateQtyCellContent
                disabled={isDisabled}
                loading={inventoryEdit?.loading}
                error={inventoryEdit?.error ?? error}
                row={row}
                value={(value && value < 0) ? -value : value}
                onChange={(newValue) => onChange(newValue, row._id, teamId)}
                field={field}
                onEditCell={() => onEditCell(row._id, teamId)}
              />
              <Box my={2}>
                {showStockProduction && (
                  <Box
                    minWidth="64px"
                    height="100%"
                    bgcolor={theme => theme.palette.primary.background}
                    border={theme => `1px solid ${theme.palette.primary.main}`}
                    borderRadius={theme => theme.spacing(0.5)}
                  >
                    <Box
                      p={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography variant="body2">In prod.:</Typography>
                      <Box display="flex" alignItems="flex-end" gap={0.25}>
                        <Typography fontSize={updatedProductionQuantity !== undefined ? '13px' : '15px'} lineHeight={1} color={updatedProductionQuantity !== undefined ? 'text.disabled' : 'text.primary'}>{productionQuantity}</Typography>
                        {updatedProductionQuantity !== undefined &&<Typography fontSize="15px" lineHeight={1} color="text.primary">{updatedProductionQuantity}</Typography>}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              {showStockMeta && <StockMetaCellContent isSetOperation={isSetOperation} quantity={stockQuantity} updateQuantity={inventoryEdit?.value} baseline={getProductTeamBaseline(row, team)} />}
            </LatestUpdateWrapper>
          );
        }
      };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ transferConfig, isTransferOperation, teams, getTeamColumnBase, getStockQuantity, getInventoryEdit, getLatestStockUpdate, showStockMeta, teamsStockMetaConfig, isSetOperation, getRowInputsDisabled, getCellInputError, teamIds, showStockProduction ]);

  return teamColumns;
};