import { Drawer } from '@mui/material';
import { ScrollableView } from 'components/MainLayout';

export type PreviewDrawerProps = {
  children: React.ReactNode;
  open: boolean;
};

export const PreviewDrawer: React.FC<PreviewDrawerProps> = ({ children, open }) => {
  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      PaperProps={{
        sx: {
          boxShadow: theme => theme.shadows[5],
          position: 'fixed',
          top: { xs: '56px', sm: '64px', md: '64px' },
          height: { xs: 'calc(100% - 56px)', sm: 'calc(100% - 64px)', md: 'calc(100% - 64px)' },
          width: { xs: '100%', md: '50%', lg: '40%' },
        },
      }}
    >
      <ScrollableView>
        {children}
      </ScrollableView>
    </Drawer>
  );
};