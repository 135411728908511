import { ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import React from 'react';
import { AnalyticsChart } from 'components';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const TeamProductionChart = () => {
  const { updateLogsLoading } = useAnalyticsPageContext();
  const { config } = useAnalyticsChartDataWithCompare({
    type: 'updateLogs',
    baseConfig: {
      getValue:  (updateLog) => updateLog.newQuantity - updateLog.previousQuantity,
      getLabel: (updateLog) => updateLog.stock.team.name,
      getId: (updateLog) => updateLog.stock.team._id,
    },
    datasetsConfigs: [ { filter: (updateLog) => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.unary } ],
  });

  return (
    <AnalyticsChart
      header="Production Per Team"
      config={config}
      loading={updateLogsLoading}
      getNavigateTo={(id) => DYNAMIC_ROUTES.team.createLink({ teamId: id })}
    />
  );
};
