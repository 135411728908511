import { GetProductsStockUpdateLogsResponse } from 'api/actions';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import { getSortedData, maxCountOptions } from 'pages/Analytics/helpers';
import { useMemo, useState } from 'react';
import { AnalyticsChart } from 'components';
import { ProductStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';
import { MenuItem, Select } from '@mui/material';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const ProductProductionChart = () => {
  const { updateLogs, updateLogsLoading } = useAnalyticsPageContext();
  const [ maxCount, setMaxCount ] = useState(5);
  const getNameAndId = (updateLog: GetProductsStockUpdateLogsResponse['data'][number]) => {
    return ({ name: updateLog.stock.product.name, id: updateLog.stock.product._id });
  };
  const mostProduced = useMemo(() => {
    const productionUpdateLogs = updateLogs.filter(updateLog => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.unary);
    const orderedUsersData = getSortedData({ data: productionUpdateLogs, getNameAndId });

    return orderedUsersData.slice(0, Math.min(orderedUsersData.length, maxCount));
  }, [ maxCount, updateLogs ]);

  const { config } = useAnalyticsChartDataWithCompare({
    type: 'updateLogs',
    baseConfig: {
      getValue:  (updateLog) => updateLog.newQuantity - updateLog.previousQuantity,
      getLabel: (updateLog) => getNameAndId(updateLog).name,
      getId: (updateLog) => getNameAndId(updateLog).id,
    },
    datasetsConfigs: [ { filter: (updateLog) => updateLog.type === ProductStockQuantityUpdateLogTypeEnum.unary && mostProduced.some(product => getNameAndId(updateLog).id === product._id) } ],
  });

  return (
    <AnalyticsChart
      header="Production Per Product"
      config={config}
      loading={updateLogsLoading}
      actions={(
        <Select
          size="small"
          value={maxCount}
          onChange={(e) => setMaxCount(e.target.value as number)}
          fullWidth
        >
          {maxCountOptions.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
        </Select>
      )}
      getNavigateTo={(id) => DYNAMIC_ROUTES.product.createLink({ productId: id })}
    />
  );
};
