import { authAxiosRequest } from 'api/axios';
import { Event, EventCollection, EventCommunicationLog, EventDate, EventFile, EventFutureInterestStatusEnum, EventIndoorOurdoorEnum, EventInsurance, EventLodging, EventLodgingBookingStatusEnum, EventOrganizer, EventPaymentDue, EventPayment, EventToDo, Place, PlaceMatrix, Team, User, UserEmployeeRoleEnum, Vehicle, EventElectricityChoiceEnum } from 'api/resources';
import { CreateResourceResponse, DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { DeepPartial } from 'types';

export type EventStartDateAndEndDate = { dateAsUtc: string; _id: string };

export type GetEventsResponse = {
  data: ResourceWithPopulated<Event, {
    team?: ResourceWithPopulated<PickFromResource<Team, 'name' | 'color' | 'teamLead'>, {
      teamLead?: PickFromResource<User, 'name'>;
    }>;
    vehicle?: PickFromResource<Vehicle, 'name'>;
    eventManager?: PickFromResource<User, 'name'>;
    teamManager?: PickFromResource<User, 'name'>;
    createdBy?: PickFromResource<User, 'name'>;
    eventOrganizer?: PickFromResource<EventOrganizer, 'name'>;
    eventCollection: ResourceWithPopulated<EventCollection, {
      events: PickFromResource<Event, 'name' | 'year'>[];
    }>;

    place: Place & { fromStudioMatrix: PlaceMatrix };

    lodging?: ResourceWithPopulated<EventLodging, {
      places: ResourceWithPopulated<EventLodging['places'][number], {
        place: (Place & {
          fromStudioMatrix: PlaceMatrix;
          toEventMatrix: PlaceMatrix;
        });
      }>[];
    }>;

    dates: ResourceWithPopulated<EventDate, {
      staff: ResourceWithPopulated<EventDate['staff'][number], {
        user: PickFromResource<User, 'name' | 'phoneNumber' | 'email' | 'profileImageUrl' | 'payRate'> & {
          employeeRoles: UserEmployeeRoleEnum[];
        };
      }>[];
      salesCount: number;
      salesRevenue: number;
      createdBy?: PickFromResource<User, 'name'>;
    }>[];

    paid: number;
    cost: number;
    applicationPayments: EventPayment[];
    paymentsDue: (EventPaymentDue & { isPaidInFull: boolean })[];

    toDos: ResourceWithPopulated<EventToDo, {
      assignees: PickFromResource<User, 'name'>[];
    }>[];

    salesCount: number;
    salesRevenue: number;
    averageOverallRating: number;
    startDate: EventStartDateAndEndDate | null;
    endDate: EventStartDateAndEndDate | null;
  }>[];
};

// dateString format is YYYY-MM-DD
export const getEvents = (dateString?: string) => {
  return authAxiosRequest<GetEventsResponse>('/api/events', { params: { date_eq: dateString } });
};

export type GetEventsForEventOrganizerInput = {
  startDate?: string;
  endDate?: string;
};
export type GetEventsForEventOrganizerResponse = {
  data: ResourceWithPopulated<PickFromResource<Event, 'year' | 'name'>, {
    salesCount: number;
    salesRevenue: number;
    startDate: EventStartDateAndEndDate | null;
    endDate: EventStartDateAndEndDate | null;
    yearsStr: string;
  }>[];
};

export const getEventsForEventOrganizer = (eventOrganizerId: string, params?: GetEventsForEventOrganizerInput) => {
  return authAxiosRequest<GetEventsForEventOrganizerResponse>(`/api/event-organizers/${eventOrganizerId}/events`, { params });
};

export type GetEventResponse = {
  data: GetEventsResponse['data'][number];
};

export const getEvent = (id: string) => {
  return authAxiosRequest<GetEventResponse>(`/api/events/${id}`);
};

export type GetEventResourceChangeOptionsData = {
  id: string;
  name: string;
  teamLead?: string;
  eventDateConflicts?: {
    eventName: string;
    eventDate: string;
    eventDateId: string;
  }[];
};

export type GetEventTeamChangeOptionsResponse = {
  data: (GetEventResourceChangeOptionsData & Pick<Team, 'color' | 'teamLead' | 'isWarehouse'>)[];
};

export const getEventTeamChangeOptions = (eventId: string) => {
  return authAxiosRequest<GetEventTeamChangeOptionsResponse>(`/api/events/${eventId}/team_change_options`);
};

export type GetEventVehicleChangeOptionsResponse = {
  data: (GetEventResourceChangeOptionsData & { mpg: number })[];
};

export const getEventVehicleChangeOptions = (eventId: string) => {
  return authAxiosRequest<GetEventVehicleChangeOptionsResponse>(`/api/events/${eventId}/vehicle_change_options`);
};

export type CreateEventInput = DeepOptionalToNull<{
  year: number;
  name: string;
  placeId: string;
  notes?: string;
  description?: string;
  spaceSize?: string;
  spaceNumber?: string;
  vendors?: number;
  attendance?: number;
  indoorOutdoor?: EventIndoorOurdoorEnum | null;
  rainOrShine?: boolean;

  vehicle?: string;
  eventManager?: string;
  teamManager?: string;
  eventOrganizer?: string;

  applicationOpenDate?: string;
  applicationIsOpen?: boolean;
  applicationDeadlineDate?: string;
  applicationOpenUntilFull?: boolean;
  applicationStatus?: Event['applicationStatus'];
  acceptanceStatus?: Event['acceptanceStatus'];
  applicationDeniedReason?: string;
  participationStatus?: Event['participationStatus'];
  applicationPlatform?: Event['applicationPlatform'];
  defaultPaymentType?: Event['defaultPaymentType'];
  paymentNote?: string;
  paymentsDueDate?: string;
  boothFee?: number;
  juryFee?: boolean;
  juryFeeAmount?: number;
  juryFeePaid?: boolean;
  juryFeePaymentType?: Event['juryFeePaymentType'];
  electricityWanted?: boolean;
  electricGeneratorAllowed?: boolean;
  electricityAvailable?: boolean;
  electricityFee?: boolean;
  electricityChoice?: EventElectricityChoiceEnum;
  electricityFeeAmount?: number;

  boothRequirements: {
    hasRequirements?: boolean;
    areTentsAllowed?: boolean;

    isWhiteTopRequired?: boolean;
    isFlameRetardantRequired?: boolean;

    maximumHeight?: number;
    isTrussAllowed?: boolean;

    notes?: string;
  };

  insurance: {
    isRequired?: boolean;
    status?: EventInsurance['status'];
    shared?: EventInsurance['shared'];
  };
  lodging: {
    isRequired?: boolean;
    notes?: string;
  };
  website: {
    applicationLink?: string;
    publicLink?: string;
    publicDescription?: string;
    addedToWebsite?: boolean;
  };
}>;

export const createEvent = (data: CreateEventInput) => {
  return authAxiosRequest<CreateResourceResponse>('/api/events', { method: 'POST', data });
};

export type UpdateEventInput = DeepPartial<Omit<CreateEventInput, 'year'> & {
  team: string | null;
  iconUrl: string | null;
  attention: boolean;
  onWebsite: boolean | null;
  futureInterestStatus: EventFutureInterestStatusEnum | null;
}>;

export const updateEvent = (id: string, data: UpdateEventInput) => {
  return authAxiosRequest(`/api/events/${id}`, { method: 'PUT', data });
};

export type UpdateEventsInput = (DeepPartial<DeepOptionalToNull<Pick<Event,
| 'spaceSize'
| 'attendance'
| 'applicationOpenDate'
| 'applicationDeadlineDate'
| 'applicationStatus'
| 'acceptanceStatus'
| 'applicationPlatform'
>>> & { _id: string })[];

export const updateEvents = (data: UpdateEventsInput) => {
  return authAxiosRequest('/api/events', { method: 'PUT', data });
};

export type CreateEventYearInput = {
  year: number;
};

export const createEventYear = (id: string, data: CreateEventYearInput) => {
  return authAxiosRequest<CreateResourceResponse>(`/api/events/${id}/year`, { method: 'POST', data });
};

export const deleteEvent = (id: string) => {
  return authAxiosRequest(`/api/events/${id}`, { method: 'DELETE' });
};

export type CreateEventApplicationPaymentInput = DeepOptionalToNull<{
  amount: number;
  receiptNumber?: string;
  type?: EventPayment['type'];
  paymentDue?: string;
}>;

export const createEventApplicationPayment = (eventId: string, data: CreateEventApplicationPaymentInput) => {
  return authAxiosRequest(`/api/events/${eventId}/application_payment`, { method: 'POST', data });
};

export type UpdateEventApplicationPaymentInput = Partial<CreateEventApplicationPaymentInput>;

export const updateEventApplicationPayment = (eventId: string, id: string, data: UpdateEventApplicationPaymentInput) => {
  return authAxiosRequest(`/api/events/${eventId}/application_payment/${id}`, { method: 'PUT', data });
};

export const deleteEventApplicationPayment = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/application_payment/${id}`, { method: 'DELETE' });
};

export type CreateEventFileInput = {
  name: string;
  type: EventFile['type'];
  url: string;
};

export const createEventFile = (eventId: string, data: CreateEventFileInput) => {
  return authAxiosRequest(`/api/events/${eventId}/file`, { method: 'POST', data });
};

export type UpdateEventFileInput = Partial<Omit<CreateEventFileInput, 'url'>>;

export const updateEventFile = (eventId: string, id: string, data: UpdateEventFileInput) => {
  return authAxiosRequest(`/api/events/${eventId}/file/${id}`, { method: 'PUT', data });
};

export const deleteEventFile = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/file/${id}`, { method: 'DELETE' });
};

export type CreateEventExternalLinkInput = {
  title: string;
  url: string;
};

export const createEventExternalLink = (eventId: string, data: CreateEventExternalLinkInput) => {
  return authAxiosRequest(`/api/events/${eventId}/external_link`, { method: 'POST', data });
};

export type UpdateEventExternalLinkInput = Partial<CreateEventExternalLinkInput>;

export const updateEventExternalLink = (eventId: string, id: string, data: UpdateEventExternalLinkInput) => {
  return authAxiosRequest(`/api/events/${eventId}/external_link/${id}`, { method: 'PUT', data });
};

export const deleteEventExternalLink = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/external_link/${id}`, { method: 'DELETE' });
};

export type CreateEventLodgingPlaceInput = DeepOptionalToNull<{
  placeName: string;
  placeId: string;
  checkIn: string;
  checkOut: string;
  cost?: number;
  finalCost?: number;
  bookingStatus?: EventLodgingBookingStatusEnum;
  website?: string;
  phone?: string;
  confirmationNumber?: string;
  notes?: string;
  needToSendCreditAuthorization?: boolean;
  creditAuthorizationSent?: boolean;
}>;

export const createEventLodgingPlace = (eventId: string, data: CreateEventLodgingPlaceInput) => {
  return authAxiosRequest(`/api/events/${eventId}/lodging_place`, { method: 'POST', data });
};

export type UpdateEventLodgingPlaceInput = Partial<CreateEventLodgingPlaceInput>;

export const updateEventLodgingPlace = (eventId: string, id: string, data: UpdateEventLodgingPlaceInput) => {
  return authAxiosRequest(`/api/events/${eventId}/lodging_place/${id}`, { method: 'PUT', data });
};

export const deleteEventLodgingPlace = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/lodging_place/${id}`, { method: 'DELETE' });
};

export type GetEventsCommunicationLogResponse = {
  data: (EventCommunicationLog & {
    event: PickFromResource<Event, 'name' | 'year'>;
    user?: PickFromResource<User, 'name' | 'profileImageUrl'>;
  })[];
};

export type GetEventsCommunicationLogInput = {
  limit?: number;
};

export const getEventsCommunicationLog = (data?: GetEventsCommunicationLogInput) => {
  return authAxiosRequest<GetEventsCommunicationLogResponse>('/api/events/communication_log', { method: 'POST', data });
};

export type CreateEventCommunicationLogInput = DeepOptionalToNull<{
  date: string;
  type: EventCommunicationLog['type'];
  note?: string;
}>;

export const createEventCommunicationLog = (eventId: string, data: CreateEventCommunicationLogInput) => {
  return authAxiosRequest(`/api/events/${eventId}/communication_log`, { method: 'POST', data });
};

export type UpdateEventCommunicationLogInput = Partial<CreateEventCommunicationLogInput>;

export const updateEventCommunicationLog = (eventId: string, id: string, data: UpdateEventCommunicationLogInput) => {
  return authAxiosRequest(`/api/events/${eventId}/communication_log/${id}`, { method: 'PUT', data });
};

export const deleteEventCommunicationLog = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/communication_log/${id}`, { method: 'DELETE' });
};

export type GetEventsIncompleteToDosResponse = {
  data: (EventToDo & {
    event: ResourceWithPopulated<PickFromResource<Event, 'name' | 'year' | 'iconUrl'>, {
      startDate: EventStartDateAndEndDate | null;
      endDate: EventStartDateAndEndDate | null;
    }>;
    assignees: PickFromResource<User, 'name' | 'profileImageUrl'>[];
  })[];
};

export type GetEventsIncompleteToDosInput = {
  userId: string;
};

export const getEventsInclompleteToDos = (data: GetEventsIncompleteToDosInput) => {
  return authAxiosRequest<GetEventsIncompleteToDosResponse>('/api/events/incomplete_to_dos', { method: 'POST', data });
};

export type CreateEventToDoInput = {
  title: string;
  description?: string;
  date?: string;
  assignees: string[];
};

export const createEventToDo = (eventId: string, data: CreateEventToDoInput) => {
  return authAxiosRequest(`/api/events/${eventId}/to_do`, { method: 'POST', data });
};

export type UpdateEventToDoInput = Partial<DeepOptionalToNull<CreateEventToDoInput> & { completed: boolean }>;

export const updateEventToDo = (eventId: string, id: string, data: UpdateEventToDoInput) => {
  return authAxiosRequest(`/api/events/${eventId}/to_do/${id}`, { method: 'PUT', data });
};

export const deleteEventToDo = (eventId: string, id: string) => {
  return authAxiosRequest(`/api/events/${eventId}/to_do/${id}`, { method: 'DELETE' });
};

export const deleteEventStaff = (eventId: string, userId: string) => {
  return authAxiosRequest(`/api/events/${eventId}/staff/user/${userId}`, { method: 'DELETE' });
};