import { createTypedDynamicRoute } from 'helpers/typed-dynamic-route/typed-dynamic-route.helpers';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';

const ROUTE_TEMPLATES = {
  dashboard: '/',
  analytics: '/analytics',
  categories: '/categories',
  genres: '/genres',
  productList: '/product-list',

  production: '/production',
  productionSchedule: '/production/schedule',
  inProduction: '/production/in-production',
  inventoryBatches: '/production/inventory-batches',
  productUpdateLogs: '/production/update-logs',

  inventoryAdjustments: '/inventory-adjustments',
  baselines: '/baselines',
  productNew: '/product-list/new',
  events: '/events',
  eventNew: '/events/new',
  eventOrganizers: '/event-organizers',
  eventOrganizerNew: '/event-organizers/new',
  salesLog: '/sales-log',
  sale: '/sales-log/:id',
  orders: '/orders',
  order: '/orders/:id',
  storefronts: '/storefronts',
  logLiveSale: '/point-of-sale',
  loggingPortalBase: '/logging-portal',
  teams: '/teams',
  team: '/teams/:teamId',
  vehicles: '/vehicles',
  vehicle: '/vehicles/:vehicleId',
  retail: '/retail',
  picking: '/picking',

  profile: '/profile',
  users: '/users',
  user: '/users/:userId',
  userNew: '/users/new',
  login: '/login',
};

const inventoryBatch = createTypedDynamicRoute<{ inventoryBatchId: string }>(({ inventoryBatchId }) => `${ROUTE_TEMPLATES.inventoryBatches}/${inventoryBatchId}`);
const product = createTypedDynamicRoute<{ productId: string }>(({ productId }) => `${ROUTE_TEMPLATES.productList}/${productId}`);
const events = createTypedDynamicRoute<{}, { initialNodeId: EventsManagementTreeViewNodeIdEnum }>(() => ROUTE_TEMPLATES.events);
const event = createTypedDynamicRoute<{ eventId: string }>(({ eventId }) => `${ROUTE_TEMPLATES.events}/${eventId}`);
const eventScheduling = createTypedDynamicRoute<{ eventId: string }>(({ eventId }) => `${ROUTE_TEMPLATES.events}/${eventId}/scheduling`);
const eventDates = createTypedDynamicRoute<{ eventId: string }>(({ eventId }) => `${ROUTE_TEMPLATES.events}/${eventId}/dates`);
const eventDate = createTypedDynamicRoute<{ eventId: string; eventDateId: string }>(({ eventId, eventDateId }) => `${ROUTE_TEMPLATES.events}/${eventId}/dates/${eventDateId}`);
const eventFeedback = createTypedDynamicRoute<{ eventId: string }>(({ eventId }) => `${ROUTE_TEMPLATES.events}/${eventId}/feedback`);
const eventSales = createTypedDynamicRoute<{ eventId: string }>(({ eventId }) => `${ROUTE_TEMPLATES.events}/${eventId}/sales`);
const eventOrganizer = createTypedDynamicRoute<{ eventOrganizerId: string }>(({ eventOrganizerId }) => `${ROUTE_TEMPLATES.eventOrganizers}/${eventOrganizerId}`);
const sale = createTypedDynamicRoute<{ saleId: string }>(({ saleId }) => `${ROUTE_TEMPLATES.salesLog}/${saleId}`);
const order = createTypedDynamicRoute<{ orderId: string }>(({ orderId }) => `${ROUTE_TEMPLATES.orders}/${orderId}`);
const loggingPortal = createTypedDynamicRoute<{ resourceType: string; resourceId: string }>(({ resourceType, resourceId }) => `${ROUTE_TEMPLATES.loggingPortalBase}/${resourceType}/${resourceId}`);
const loggingPortalLogSale = createTypedDynamicRoute<{ resourceType: string; resourceId: string }, { user: string}>(({ resourceType, resourceId }) => `${ROUTE_TEMPLATES.loggingPortalBase}/${resourceType}/${resourceId}/log-sale`);
const team = createTypedDynamicRoute<{ teamId: string }>(({ teamId }) => `${ROUTE_TEMPLATES.teams}/${teamId}`);
const vehicle = createTypedDynamicRoute<{ vehicleId: string }>(({ vehicleId }) => `${ROUTE_TEMPLATES.vehicles}/${vehicleId}`);
const user = createTypedDynamicRoute<{ userId: string }>(({ userId }) => `${ROUTE_TEMPLATES.users}/${userId}`);


export const ROUTING_CONFIG = {
  ...ROUTE_TEMPLATES,
  events: events.template(),
  inventoryBatch: inventoryBatch.template(),
  product: product.template(),
  event: event.template(),
  eventScheduling: eventScheduling.template(),
  eventDates: eventDates.template(),
  eventDate: eventDate.template(),
  eventFeedback: eventFeedback.template(),
  eventSales: eventSales.template(),
  eventOrganizer: eventOrganizer.template(),
  sale: sale.template(),
  order: order.template(),
  loggingPortal: loggingPortal.template(),
  loggingPortalLogSale: loggingPortalLogSale.template(),
  team: team.template(),
  vehicle: vehicle.template(),
  user: user.template(),
} as const;

export const DYNAMIC_ROUTES = {
  inventoryBatch,
  product,
  events,
  event,
  eventScheduling,
  eventDates,
  eventDate,
  eventFeedback,
  eventSales,
  eventOrganizer,
  sale,
  order,
  loggingPortal,
  loggingPortalLogSale,
  team,
  vehicle,
  user,
} as const;