import { AlbumTwoTone, PrecisionManufacturingOutlined } from '@mui/icons-material';
import { Box, lighten, Stack, Switch, Typography } from '@mui/material';

export type StockTypeInputProps = {
  stockType: 'production' | 'regular';
  setStockType:  React.Dispatch<React.SetStateAction<'production' | 'regular'>>;
};

export const StockTypeInput: React.FC<StockTypeInputProps> = ({ stockType, setStockType }) => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography>Regular</Typography>
      <Switch
        checked={stockType === 'production'}
        onChange={(_e, checked: boolean) => setStockType(checked ? 'production' : 'regular')}
        icon={
          <Box
            sx={{
              backgroundColor: theme => theme.palette.grey[400],
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: '-2.5px',
            }}
          >
            <AlbumTwoTone fontSize="small" sx={{ color: theme => theme.palette.common.black }} />
          </Box>
        }
        checkedIcon={
          <Box
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: '-2.5px',
            }}
          >
            <PrecisionManufacturingOutlined sx={{ color: theme =>lighten(theme.palette.primary.main, 0.8), fontSize: '18px' }}  />
          </Box>
        }
      />
      <Typography>Production</Typography>
    </Stack>
  );
};