import { inventoryBatchEnumHelpers } from 'helpers';
import { useInventoryBatchOutletContext } from './InventoryBatch.base';
import { InventoryBatchLayout } from './components/InventoryBatchLayout.component';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { CardContentContainer, CardContentGrid, InventoryBatchStatusChip, LoggedAtDateCell, ProductionStockUpdateLogsTable, StockUpdateLogTable, Table } from 'components';
import { LabeledChipBox } from 'components/Chips/LabeledChipBox.component';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, InventoryBatchUpdate } from 'api/resources';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { GridColDef } from '@mui/x-data-grid';
import { useProductsProductionStockUpdateLogs, useProductsStockUpdateLogs } from 'queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateInventoryBatch, UpdateInventoryBatchStatusEnum } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { CancelBatchButton } from './components/CancelBatchButton.component';
import { UpdateAmountCell } from './components/UpdateAmountCell.component';
import { useCallback } from 'react';

const useColumns = (): GridColDef<InventoryBatchUpdate>[] => {
  const { products } = useProductsPageContext();
  const { inventoryBatch } = useInventoryBatchOutletContext();

  const productNameHash = products.reduce((acc: Record<string, string>, product) => ({ ...acc, [product._id]: product.name }), {});

  return [
    {
      field: 'product',
      headerName: 'Product',
      valueGetter: ({ row }) => productNameHash[row.product],
      width: 200,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: ({ row }) => <UpdateAmountCell value={row.quantity} type={inventoryBatch.type}/>
    },
    {
      field: 'reviewQuantity',
      headerName: 'Review Quantity',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: ({ row }) => <UpdateAmountCell value={row.reviewQuantity} type={inventoryBatch.type} isFinal={row.managerReviewQuantity === undefined} />
    },
    {
      field: 'managerReviewQuantity',
      headerName: 'Manager Review Quantity',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: ({ row }) => <UpdateAmountCell value={row.managerReviewQuantity} type={inventoryBatch.type} isFinal />
    }
  ];
};

export const BatchOverview: React.FC = () => {
  const columns = useColumns();
  const { getIsMe } = useCurrentUser();
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const updateStatusMutation = useMutation({
    mutationFn: (status: UpdateInventoryBatchStatusEnum) => updateInventoryBatch(inventoryBatch._id, { status }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
    }
  });

  const isClosed = inventoryBatch.status === InventoryBatchStatusEnum.closed;
  const isManagerReview = inventoryBatch.status === InventoryBatchStatusEnum.managerReview;
  const updateLogsEnabled = [
    InventoryBatchTypeEnum.picking,
    InventoryBatchTypeEnum.overstockPicking,
    InventoryBatchTypeEnum.damaged,
    InventoryBatchTypeEnum.production
  ].includes(inventoryBatch.type);
  const productionUpdateLogsEnabled = [
    InventoryBatchTypeEnum.production,
    InventoryBatchTypeEnum.productionDamaged
  ].includes(inventoryBatch.type);
  const showUpdateLogTableLabel = updateLogsEnabled && productionUpdateLogsEnabled;

  const { data: updateLogs = [], isInitialLoading: updateLogsLoading } = useProductsStockUpdateLogs({ batchId: inventoryBatch._id }, { enabled: isClosed && updateLogsEnabled });
  const { data: productionUpdateLogs = [], isInitialLoading: productionUpdateLogsLoading } = useProductsProductionStockUpdateLogs({ batchId: inventoryBatch._id }, { enabled: isClosed && productionUpdateLogsEnabled });

  const TypeIcon = inventoryBatchEnumHelpers.type.getIcon(inventoryBatch.type);

  const isMyBatch = getIsMe(inventoryBatch.user._id);
  const isDamagedBatch = [ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type);
  const hadManagerReview = inventoryBatch.status === InventoryBatchStatusEnum.closed && inventoryBatch.updates.some(update => update.managerReviewQuantity !== undefined);
  const hadReview = !isDamagedBatch && (inventoryBatch.status === InventoryBatchStatusEnum.closed || inventoryBatch.status === InventoryBatchStatusEnum.managerReview);

  const getMismatchesDisplay = useCallback((managerReview?: boolean) => {
    const mismatchesWithInitial = managerReview
      ? inventoryBatch.updates.filter(update => (update.managerReviewQuantity ?? 0) !== (update.quantity ?? 0)).length
      : inventoryBatch.updates.filter(update => (update.reviewQuantity ?? 0) !== (update.quantity ?? 0)).length;
    const mismatchesWithReview = inventoryBatch.updates.filter(update => (update.managerReviewQuantity ?? 0) !== (update.reviewQuantity ?? 0)).length;

    const mismatchesWithInitialDisplay = (
      <Typography variant="body2" color="text.secondary">
          w/initial qty: <Typography fontWeight={600} component="span">{mismatchesWithInitial}</Typography>
      </Typography>
    );

    const mismatchesWithReviewDisplay = (
      <Typography variant="body2" color="text.secondary">
          w/review qty: <Typography fontWeight={600} component="span">{mismatchesWithReview}</Typography>
      </Typography>
    );

    if (managerReview) {
      return (
        <>
          {mismatchesWithInitialDisplay}
          {hadReview && mismatchesWithReviewDisplay}
        </>
      );
    }

    return mismatchesWithInitialDisplay;
  }, [ hadReview, inventoryBatch.updates ]);

  return (
    <InventoryBatchLayout
      preventReducedMarginTop
      actions={(
        <>
          {isMyBatch && <CancelBatchButton />}
          {inventoryBatch.status === InventoryBatchStatusEnum.review && isMyBatch && <Button variant="outlined" disabled={updateStatusMutation.isLoading} onClick={() => updateStatusMutation.mutate(UpdateInventoryBatchStatusEnum.open)}>Make More Updates</Button>}
        </>
      )}
    >
      <Box display="grid" gridTemplateColumns="minmax(420px, 1fr) 3fr" gap={2}>
        <Box>
          <CardContentContainer title="Batch Information">
            <CardContentGrid
              items={[
                {
                  title: 'Batch ID',
                  value: inventoryBatch.name,
                },
                {
                  title: 'Type',
                  value: (
                    <Box display="flex" gap={1} mt={0.25}>
                      <TypeIcon />
                      <Typography fontWeight={500}>{inventoryBatchEnumHelpers.type.getLabel(inventoryBatch.type)}</Typography>
                    </Box>
                  ),
                },
                {
                  title: 'Team',
                  value: inventoryBatch.team.name
                },
                {
                  title: 'User',
                  value: inventoryBatch.user.name
                },
                {
                  value: <LabeledChipBox title="Batch Status" value={inventoryBatch.status} ChipComponent={InventoryBatchStatusChip} />,
                  type: 'full',
                }
              ]}
            />
            <Divider sx={{ my: 2 }} />
            <CardContentGrid
              items={[
                {
                  title: 'Created At',
                  value: <LoggedAtDateCell value={inventoryBatch.createdAt} fontSize="16px" />,
                },
                {
                  title: 'Updated At',
                  value: <LoggedAtDateCell value={inventoryBatch.updatedAt} fontSize="16px" />,
                },
              ]}
            />
            {hadReview && (
              <>
                <Divider sx={{ my: 2 }} />
                <CardContentGrid
                  items={[
                    {
                      title: 'Reviewer',
                      value: (isClosed || isManagerReview) ? inventoryBatch.reviewUser?.name : '–',
                    },
                    {
                      title: 'Mismatches',
                      value: getMismatchesDisplay(false),
                    }
                  ]}
                />
              </>
            )}
            {hadManagerReview && (
              <>
                <Divider sx={{ my: 2 }} />
                <CardContentGrid
                  items={[
                    {
                      title: 'Manager Reviewer',
                      value: (isClosed || isManagerReview) ? inventoryBatch.managerReviewUser?.name : '–',
                    },
                    {
                      title: 'Mismatches',
                      value: getMismatchesDisplay(true),
                    }
                  ]}
                />
              </>
            )}
            {inventoryBatch.status === InventoryBatchStatusEnum.canceled && (
              <>
                <Divider sx={{ my: 2 }} />
                <CardContentGrid
                  items={[
                    {
                      title: 'Canceller',
                      value: inventoryBatch.cancelUser?.name || '–',
                    },
                  ]}
                />
              </>
            )}
          </CardContentContainer>
        </Box>
        <Box>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box p={2}>
                <Typography variant="h6" fontWeight={500}>Batch Updates</Typography>
              </Box>
              <Box display="flex" flex={1}>
                <Table
                  getRowId={(row) => row._id}
                  rows={inventoryBatch.updates}
                  columns={columns}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        reviewQuantity: !isDamagedBatch,
                      }
                    }
                  }}
                  slotProps={{
                    toolbar: { searchOnly: true }
                  }}
                  emptyMessage="No updates yet"
                  sx={{ border: 'none' }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      {isClosed && (
        <Card sx={{ mt: 2 }} variant="outlined">
          <Box p={2}>
            <Typography variant="h6" fontWeight={500}>Stock Update Logs</Typography>
            <Typography>Once a batch is closed, the inventory is updated. Here are the update logs from this batch:</Typography>
          </Box>
          {showUpdateLogTableLabel && (
            <Box mx={2}>
              <Typography
                variant="subtitle1"
                width="fit-content"
                fontWeight={500}
                sx={{
                  pb: 0,
                  borderBottomColor: (theme) => theme.palette.grey[500],
                  borderBottomStyle: 'dotted',
                  borderBottomWidth: 3
                }}
              >
                Stock Update Logs
              </Typography>
            </Box>
          )}
          {updateLogsEnabled && (
            <StockUpdateLogTable
              loading={updateLogsLoading}
              rows={updateLogs}
              sx={{ border: 'none' }}
            />
          )}
          {showUpdateLogTableLabel && (
            <Box mx={2}>
              <Typography
                variant="subtitle1"
                width="fit-content"
                fontWeight={500}
                sx={{
                  pb: 0,
                  borderBottomColor: (theme) => theme.palette.grey[500],
                  borderBottomStyle: 'dotted',
                  borderBottomWidth: 3
                }}
              >
                Production Stock Update Logs
              </Typography>
            </Box>
          )}
          {productionUpdateLogsEnabled && (
            <ProductionStockUpdateLogsTable
              loading={productionUpdateLogsLoading}
              rows={productionUpdateLogs}
              sx={{ border: 'none' }}
            />
          )}
        </Card>
      )}
    </InventoryBatchLayout>
  );
};