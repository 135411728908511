import React from 'react';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import { AnalyticsChart } from 'components';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const TeamsSalesChart = () => {
  const { eventsSalesLoading } = useAnalyticsPageContext();
  const { config } = useAnalyticsChartDataWithCompare({
    type: 'eventsSales',
    baseConfig: { getValue: () => 1, getLabel: (sale) => sale.order.event.team.name, getId: (sale) => sale.order.event.team._id },
    datasetsConfigs: [ { filter: (sale) => !(sale.order.event.team.name === 'Warehouse') } ]
  });

  return (
    <AnalyticsChart
      header="Team Sales"
      config={config}
      loading={eventsSalesLoading}
      getNavigateTo={(id) => DYNAMIC_ROUTES.team.createLink({ teamId: id })}
    />
  );
};
