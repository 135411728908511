import { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEvents, useTeams } from 'queries';
import { Box, Button } from '@mui/material';
import { EventPreviewContainer, PageContentContainer, PageContentHeader } from 'components';
import { GetEventsResponse, GetTeamsResponse, getEvent } from 'api/actions';
import { EventsManagementTreeItemConfig, EventsManagementTreeViewNodeIdEnum } from './helpers';

import { EventsList, EventsCalendar, EventsDuplicate } from './pages/Events';
import { ApplicationsComingSoon, ApplicationsOpen, ApplicationsClosed, ApplicationsPending, ApplicationsWaitlisted, ApplicationsDenied, ApplicationsMissingData, ApplicationsCommunicationLog } from './pages/Applications';
import { LogisticsEventDates, LogisticsInsurance, LogisticsLodging, LogisticsMissingData, LogisticsPaymentsDue } from './pages/Logistics';
import { LogisticsTeamAssignmentCalendarSwitch, LogisticsTeamAssignmentList } from './pages/Logistics/TeamAssignment';
import { LogisticsStaffScheduleCalendar, LogisticsStaffScheduleList, LogisticsStaffScheduleUserCard } from './pages/Logistics/StaffSchedule';
import { useLocalStorage } from 'hooks';
import { QUERY_KEY } from 'queries/query-keys';
import { User } from 'api/resources';
import { useUsers } from 'queries/user';
import { EventsManagementSidebar } from './components/EventsManagementSidebar.component';
import { EventsMissingDates } from './pages/Events/EventsMissingDates.page';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

type EventsManagementPageContextType = {
  events: GetEventsResponse['data'];
  eventsLoading: boolean;
  users: User[];
  usersLoading: boolean;
  teams: GetTeamsResponse['data'];
  teamsLoading: boolean;
  selectedUser: User | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
};

export const EventsManagementPageContext = createContext<EventsManagementPageContextType>({
  events: [],
  eventsLoading: false,
  users: [],
  usersLoading: false,
  teams: [],
  teamsLoading: false,
  selectedUser: null,
  setSelectedUser: () => {}
});

export const eventsManagementTreeViewConfig: EventsManagementTreeItemConfig[] = [
  {
    nodeId: EventsManagementTreeViewNodeIdEnum.events,
    children: [
      { nodeId: EventsManagementTreeViewNodeIdEnum.eventsProSearch },
      { nodeId: EventsManagementTreeViewNodeIdEnum.eventsCalendar },
      { nodeId: EventsManagementTreeViewNodeIdEnum.eventsDuplicate },
      { nodeId: EventsManagementTreeViewNodeIdEnum.eventsMissingDates },
    ],
  },
  {
    nodeId: EventsManagementTreeViewNodeIdEnum.applications,
    children: [
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsMissingData },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsComingSoon },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsOpen },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsClosed },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsPending },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsWaitListed },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsDenied },
      { nodeId: EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog },
    ],
  },
  {
    nodeId: EventsManagementTreeViewNodeIdEnum.logistics,
    children: [
      { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsMissingData },
      { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsInsurance },
      { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue },
      { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsEventDates },
      {
        nodeId: EventsManagementTreeViewNodeIdEnum.logisticStaffSchedule,
        children: [
          { nodeId: EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList },
          { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar },
          { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards },
        ],
      },
      {
        nodeId: EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignment,
        children: [
          { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList },
          { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar },
        ],
      },
      { nodeId: EventsManagementTreeViewNodeIdEnum.logisticsLodging },
    ],
  },
];

const nodeIdToPageHash: Record<string, React.ReactNode> = {
  [EventsManagementTreeViewNodeIdEnum.eventsProSearch]: <EventsList />,
  [EventsManagementTreeViewNodeIdEnum.eventsCalendar]: <EventsCalendar />,
  [EventsManagementTreeViewNodeIdEnum.eventsDuplicate]: <EventsDuplicate />,
  [EventsManagementTreeViewNodeIdEnum.eventsMissingDates]: <EventsMissingDates />,

  [EventsManagementTreeViewNodeIdEnum.applicationsMissingData]: <ApplicationsMissingData />,
  [EventsManagementTreeViewNodeIdEnum.applicationsComingSoon]: <ApplicationsComingSoon />,
  [EventsManagementTreeViewNodeIdEnum.applicationsOpen]: <ApplicationsOpen />,
  [EventsManagementTreeViewNodeIdEnum.applicationsClosed]: <ApplicationsClosed />,
  [EventsManagementTreeViewNodeIdEnum.applicationsPending  ]: <ApplicationsPending />,
  [EventsManagementTreeViewNodeIdEnum.applicationsWaitListed]: <ApplicationsWaitlisted />,
  [EventsManagementTreeViewNodeIdEnum.applicationsDenied]: <ApplicationsDenied />,
  [EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog]: <ApplicationsCommunicationLog />,

  [EventsManagementTreeViewNodeIdEnum.logisticsMissingData]: <LogisticsMissingData />,
  [EventsManagementTreeViewNodeIdEnum.logisticsInsurance]: <LogisticsInsurance />,
  [EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue]: <LogisticsPaymentsDue />,
  [EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList]: <LogisticsStaffScheduleList />,
  [EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar]: <LogisticsStaffScheduleCalendar />,
  [EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards]: <LogisticsStaffScheduleUserCard/>,
  [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList]: <LogisticsTeamAssignmentList />,
  [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar]: <LogisticsTeamAssignmentCalendarSwitch />,
  [EventsManagementTreeViewNodeIdEnum.logisticsEventDates]: <LogisticsEventDates />,
  [EventsManagementTreeViewNodeIdEnum.logisticsLodging]: <LogisticsLodging />,
};
const nodeIdToPage = (nodeId: EventsManagementTreeViewNodeIdEnum) => {
  if (nodeId in nodeIdToPageHash) {
    return nodeIdToPageHash[nodeId];
  }

  return null;
};

export const EventsManagement = () => {
  const navigate = useNavigate();
  const initialNodeId = DYNAMIC_ROUTES.events.useQueryParam('initialNodeId');
  const localStorage = useLocalStorage<EventsManagementTreeViewNodeIdEnum>('eventsManagementNodeId');
  const [ nodeId, setNodeId ] = useState<EventsManagementTreeViewNodeIdEnum>(initialNodeId || localStorage.getResource(EventsManagementTreeViewNodeIdEnum.eventsProSearch));
  const { data: events = [], isInitialLoading: eventsLoading } = useEvents();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: users = [], isInitialLoading: usersLoading } = useUsers();
  const [ selectedUser, setSelectedUser ] = useState<User | null>(null);

  const onNodeSelect = (nodeId: EventsManagementTreeViewNodeIdEnum) => {
    if (nodeIdToPage(nodeId)) {
      localStorage.setResource(nodeId);
      setNodeId(nodeId);
    };
  };

  const page = useMemo(() => nodeIdToPage(nodeId), [ nodeId ]);

  return (
    <EventPreviewContainer
      events={events}
      userId={selectedUser?._id}
      invalidateQueriesHandler={async (queryClient, eventId) => {
        if (!eventId) {
          return;
        }

        await queryClient.invalidateQueries(QUERY_KEY.EVENT(eventId));

        const event = await queryClient.fetchQuery(QUERY_KEY.EVENT(eventId), async () => {
          const response = await getEvent(eventId);

          return response.data;
        });

        queryClient.setQueryData<GetEventsResponse['data']>(QUERY_KEY.EVENTS, (events) => {
          return events?.map(_event =>
            _event._id === eventId ? event : _event
          );
        });
      }}
    >
      <PageContentContainer
        preventContainer
        header={(
          <PageContentHeader
            title="Events Management"
            rightActions={<Button variant="contained" onClick={() => navigate('new')} size="small">New Event</Button>}
          />
        )}
      >
        <Box mr={2}>
          <EventsManagementPageContext.Provider
            value={{
              events,
              eventsLoading,
              teams,
              teamsLoading,
              users,
              usersLoading,
              selectedUser,
              setSelectedUser
            }}
          >
            <Box display="flex" height="100%">
              <EventsManagementSidebar nodeId={nodeId} onNodeSelect={onNodeSelect} />
              <Box flex={1} minWidth={0} mt={1}>
                {page}
              </Box>
            </Box>
          </EventsManagementPageContext.Provider>
        </Box>
      </PageContentContainer>
    </EventPreviewContainer>
  );
};