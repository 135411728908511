import { ResouceWarningConfig } from '../types';

import { getWarningsForResource } from '../resource-warnings';
import { GetEventResponse, GetEventsResponse } from 'api/actions';
import { EventPageSection, EventPageSectionRowId } from 'components/Event/types';
import { EventAcceptanceStatusEnum, EventParticipationStatusEnum } from 'api/resources';
import { getEventDatesStatus, getEventStaffStatus } from 'helpers/event-dates';
import { EventDatesStatusEnum, EventStaffStatusEnum } from 'types';

export const eventWarningConfigs: ResouceWarningConfig<GetEventsResponse['data'][number], EventPageSection, EventPageSectionRowId>[] = [
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.EVENT_ORGANIZER,
    needsAttention: (event) => {
      if (!event.eventOrganizer) {
        return 'Event Organizer is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.ATTENDANCE,
    needsAttention: (event) => {
      if (!event.attendance) {
        return 'Attendance number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.VENDORS,
    needsAttention: (event) => {
      if (!event.vendors) {
        return 'Vendors number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.INDOOR_OUTDOOR,
    needsAttention: (event) => {
      if (!event.indoorOutdoor) {
        return 'Unknown if event is indoor or outdoor';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.RAIN_OR_SHINE,
    needsAttention: (event) => {
      if (event.rainOrShine === undefined) {
        return 'Unknown if event is \'Rain or Shine\'';
      }

      return false;
    }
  },

  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.EVENT_MANAGER,
    needsAttention: (event) => {
      if (!event.eventManager) {
        return 'Event manager is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.TEAM,
    needsAttention: (event) => {
      if (!event.team) {
        return 'Team is not assigned';
      }

      if (!event.teamManager) {
        return 'Team Manager is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.VEHICLE,
    needsAttention: (event) => {
      if (!event.vehicle) {
        return 'Vehicle is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.SPACE_SIZE,
    needsAttention: (event) => {
      if (!event.spaceSize) {
        return 'Space Size is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.SPACE_NUMBER,
    needsAttention: (event) => {
      if (event.acceptanceStatus === EventAcceptanceStatusEnum.accepted && !event.spaceNumber) {
        return 'Space Number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.BOOTH_REQUIREMENTS,
    needsAttention: (event) => {
      const {
        boothRequirements: {
          hasRequirements,
          areTentsAllowed,
          isWhiteTopRequired,
          isFlameRetardantRequired,
          maximumHeight,
          isTrussAllowed,
        } = {},
      } = event;
      const description: string[] = [];

      if (hasRequirements === undefined) {
        description.push('Unknown if booth has requirements');
      }
      if (hasRequirements) {
        if (areTentsAllowed === undefined) {
          description.push('Unknown if tents are allowed');
        } else {
          if(areTentsAllowed) {
            if (isWhiteTopRequired === undefined) {
              description.push('Unknown if white top is required');
            }
            if (isFlameRetardantRequired === undefined) {
              description.push('Unknown if flame retardant is required');
            }
          } else {
            if (maximumHeight === undefined) {
              description.push('Unknown what is the maximum height');
            }
            if (isTrussAllowed === undefined) {
              description.push('Unknown if truss is allowed');
            }
          }
        }
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.ASSIGNMENTS,
    row: EventPageSectionRowId.ELECTRICITY,
    needsAttention: (event) => {
      const {
        electricGeneratorAllowed,
        electricityAvailable,
        electricityWanted,
        electricityFee,
        electricityChoice,
      } = event;
      const description: string[] = [];

      if (electricityWanted === undefined) {
        description.push('Unknown if electricity is wanted');
      }
      if (electricityWanted) {
        if (electricityWanted && electricityAvailable === undefined) {
          description.push('Unknown if electricity is available');
        }
        if (electricityWanted && electricGeneratorAllowed === undefined) {
          description.push('Unknown if electric generator is allowed');
        }
        if (electricityWanted && electricityFee === undefined) {
          description.push('Unknown if there is anelectricity fee');
        }
        if (electricityWanted && electricityChoice === undefined) {
          description.push('Electricity choice is not made');
        }
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.DATES,
    row: EventPageSectionRowId.DATES_STATUS,
    needsAttention: (event) => {
      if (getEventDatesStatus(event.dates) === EventDatesStatusEnum.attention) {
        return 'Dates need attention';
      }

      return false;
    }
  },
  {
    section: EventPageSection.DATES,
    row: EventPageSectionRowId.STAFF_STATUS,
    needsAttention: (event) => {
      if (getEventStaffStatus(event.dates) === EventStaffStatusEnum.pending) {
        return 'Staff needs attention';
      }

      return false;
    }
  },
  {
    section: EventPageSection.TO_DOS,
    row: EventPageSectionRowId.TO_DO,
    needsAttention: (event) => {
      if (event.toDos.some((toDo) => !toDo.completed)) {
        return 'There are incomplete to dos';
      }

      return false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.APPLICATION_DATE_RANGE,
    needsAttention: (event) => {
      const description: string[] = [];

      if (!event.applicationIsOpen && !event.applicationOpenDate) {
        description.push('Application is neither marked open nor assigned open date');
      }
      if (!event.applicationOpenUntilFull && !event.applicationDeadlineDate) {
        description.push('Application is neither marked open until full nor assigned deadline date');
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.APPLICATION_PLATFORM,
    needsAttention: (event) => {
      if (!event.applicationPlatform) {
        return 'Application platform is not assigned';
      }

      return false;
    }
  },

  {
    section: EventPageSection.LODGING,
    row: EventPageSectionRowId.LODGING_REQUIRED,
    needsAttention: (event) => {
      if (event.lodging?.isRequired === undefined) {
        return 'Unknown if lodging is required';
      }

      return false;
    }
  },

  {
    section: EventPageSection.INSURANCE,
    row: EventPageSectionRowId.INSURANCE,
    needsAttention: (event) => {
      if (event.insurance?.isRequired === undefined) {
        return 'It\'s unknown if insurance is required';
      }

      const description: string[] = [];

      if (event.insurance?.isRequired && !event.insurance?.status) {
        description.push('Insurance Status is \'Unknown\'');
      }
      if (event.insurance?.isRequired && !event.insurance?.shared) {
        description.push('Insurance Shared is \'Unknown\'');
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.PAYMENTS,
    row: EventPageSectionRowId.PAYMENTS,
    needsAttention: (event) => {
      if (event.acceptanceStatus === EventAcceptanceStatusEnum.accepted && event.participationStatus === EventParticipationStatusEnum.participating && event.cost > event.paid) {
        return 'Event is not paid in full';
      }

      return false;
    }
  }
];

export const getWarningsForEvent = (event: GetEventsResponse['data'][number] | GetEventResponse['data']) => getWarningsForResource(event, eventWarningConfigs);