import { SideBarNavItem } from '../../types';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';
import { Many } from 'lodash';
import { navItemsConfig } from './navItems.helpers';

const useMainNavConfig = (): SideBarNavItem[] => {
  const { isAdmin, isAuthorizedEmployee } = useCurrentUser();

  const conditionallyVisible = ({ navItems, adminOnly, nonAdminNavItems, authorizedEmployeeRoles: allowedEmployeeRoles, condition = true }: {
    navItems: Many<SideBarNavItem>;
    adminOnly?: boolean;
    nonAdminNavItems?: Many<SideBarNavItem>;
    authorizedEmployeeRoles?: UserEmployeeRoleEnum[];
    condition?: boolean;
  }): SideBarNavItem[] => {
    const navItemsArray = Array.isArray(navItems) ? navItems : [ navItems ];
    const nonAdminNavItemsArray = Array.isArray(nonAdminNavItems) ? nonAdminNavItems : [ nonAdminNavItems ];

    if (adminOnly && !isAdmin) {
      return nonAdminNavItems ? nonAdminNavItemsArray : [];
    }
    if (!condition || (allowedEmployeeRoles && !isAuthorizedEmployee(allowedEmployeeRoles))) {
      return [];
    }

    return navItemsArray;
  };

  return [
    navItemsConfig.dashboard,
    ...conditionallyVisible({ navItems: navItemsConfig.analytics, authorizedEmployeeRoles: [ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ] }),
    ...conditionallyVisible({
      navItems: navItemsConfig.products,
      adminOnly: true,
      nonAdminNavItems: conditionallyVisible({
        navItems: [
          ...conditionallyVisible({ navItems: [ navItemsConfig.genres, navItemsConfig.productList ], authorizedEmployeeRoles: [ UserEmployeeRoleEnum.wholesaleManager ] }),
          ...conditionallyVisible({ navItems: navItemsConfig.productsNonAdmin, authorizedEmployeeRoles: [ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ] }),
        ],
        authorizedEmployeeRoles: [ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember, UserEmployeeRoleEnum.wholesaleManager ],
      })
    }),
    ...conditionallyVisible({
      navItems: [
        {
          title: 'Events',
          nested: [
            ...conditionallyVisible({
              navItems:[ navItemsConfig.eventsManagement, navItemsConfig.eventOrganizers ],
              authorizedEmployeeRoles: [ UserEmployeeRoleEnum.eventManager ]
            }),
          ],
        }
      ],
      authorizedEmployeeRoles: [ UserEmployeeRoleEnum.teamMember, UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.eventManager ]
    }),
    ...conditionallyVisible({
      navItems: navItemsConfig.sales,
      adminOnly: true,
      nonAdminNavItems: [
        ...conditionallyVisible({ navItems: navItemsConfig.salesNonAdmin, authorizedEmployeeRoles: [ UserEmployeeRoleEnum.teamMember, UserEmployeeRoleEnum.teamLead ] }),
        ...conditionallyVisible({ navItems: [ navItemsConfig.orders ], authorizedEmployeeRoles: [ UserEmployeeRoleEnum.productionManager ] })
      ]
    }),
    ...conditionallyVisible({
      navItems: navItemsConfig.storefronts,
      adminOnly: true,
    }),
    ...conditionallyVisible({
      navItems: [ navItemsConfig.teams, navItemsConfig.vehicles, navItemsConfig.users ],
      authorizedEmployeeRoles: [ UserEmployeeRoleEnum.eventManager ]
    }),
    {
      title: 'Management',
      nested: [ navItemsConfig.profile ]
    },

  ];
};

export const useLayoutConf = () => {
  const mainNavConfig = useMainNavConfig();

  return mainNavConfig;
};
