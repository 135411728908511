import { UpdateProductStocksProductionQuantityInput, UpdateProductStocksQuantityInput } from 'api/actions';
import { ProductStockUpdateLogTransferTypeEnum, ProductStockQuantityUpdateLogTypeEnum, ProductProductionStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { InventoryEdits, InventoryRow, InventoryTableEditableMode, SaveInventoryEditsParams } from './hooks';

type UpdateStock = UpdateProductStocksQuantityInput['stocks'][number];

const getPrepareInventoryTableEditsToSaveHelpers = (products: InventoryRow[]) => {
  const productsHash = products.reduce((hash: Record<string, InventoryRow>, product) => ({ [product._id]: product, ...hash }), {});
  const getProduct = (productId: string) => productsHash[productId];
  const getProductStock = (productId: string, teamId: string) => getProduct(productId).stocks[teamId];

  return { getProductStock };
};

export const prepareInventoryTableEditsToSave = (params: SaveInventoryEditsParams) => {
  const { getProductStock } = getPrepareInventoryTableEditsToSaveHelpers(params.products);
  const transferConfig = params.transferConfig;

  return Object.entries(params.edits).reduce((allUpdates: UpdateStock[], [ productId, teamUpdates ]) => {
    return Object.entries(teamUpdates).reduce((productUpdates, [ teamId, { value: updateAmount } ]) => {
      const stock = getProductStock(productId, teamId);

      if (transferConfig && params.isTransferOperation) {
        const inStock = stock;
        const outStock = getProductStock(productId, transferConfig.out);

        const inUpdate: UpdateStock = {
          _id: inStock._id,
          updateAmount,
          updatedQuantity: inStock.quantity + updateAmount,
          type: ProductStockQuantityUpdateLogTypeEnum.transfer,
          transferType: ProductStockUpdateLogTransferTypeEnum.to,
          transferStock: outStock._id,
        };
        const outUpdate: UpdateStock = {
          _id: outStock._id,
          updateAmount: -updateAmount,
          updatedQuantity: outStock.quantity - updateAmount,
          type: ProductStockQuantityUpdateLogTypeEnum.transfer,
          transferType: ProductStockUpdateLogTransferTypeEnum.from,
          transferStock: inStock._id,
        };

        return [ ...productUpdates, inUpdate, outUpdate ];
      }

      if (params.mode === InventoryTableEditableMode.set) {

        // skip if the stock is already set to the update amount
        if (updateAmount === stock.quantity) {
          return productUpdates;
        }

        const update: UpdateStock = {
          _id: stock._id,
          updateAmount: updateAmount - stock.quantity,
          updatedQuantity: updateAmount,
          type: ProductStockQuantityUpdateLogTypeEnum.set,
        };

        return [ ...productUpdates, update ];
      }

      const update: UpdateStock = {
        _id: stock._id,
        updateAmount,
        updatedQuantity: stock.quantity + updateAmount,
        type: ProductStockQuantityUpdateLogTypeEnum.unary,
      };

      return [ ...productUpdates, update ];
    }, allUpdates);
  }, []);
};

type UpdateProductionStock = UpdateProductStocksProductionQuantityInput['stocks'][number];

export type PrepareInventoryTableProductionStockEditsToSave = {
  edits: InventoryEdits;
  mode: InventoryTableEditableMode.set | InventoryTableEditableMode.unary;
  products: InventoryRow[];
};

export const prepareInventoryTableProductionStockEditsToSave = (params: SaveInventoryEditsParams) => {
  const { getProductStock } = getPrepareInventoryTableEditsToSaveHelpers(params.products);

  return Object.entries(params.edits).reduce((allUpdates: UpdateProductionStock[], [ productId, teamUpdates ]) => {
    return Object.entries(teamUpdates).reduce((productUpdates, [ teamId, { value: updateAmount } ]) => {
      const stock = getProductStock(productId, teamId);

      if (params.mode === InventoryTableEditableMode.set) {

        // skip if the stock is already set to the update amount
        if (updateAmount === stock.productionQuantity) {
          return productUpdates;
        }

        const update: UpdateProductionStock = {
          _id: stock._id,
          updateAmount: updateAmount - stock.productionQuantity,
          updatedQuantity: updateAmount,
          type: ProductProductionStockQuantityUpdateLogTypeEnum.set,
        };

        return [ ...productUpdates, update ];
      }

      const update: UpdateProductionStock = {
        _id: stock._id,
        updateAmount,
        updatedQuantity: stock.productionQuantity + updateAmount,
        type: ProductProductionStockQuantityUpdateLogTypeEnum.unary,
      };

      return [ ...productUpdates, update ];
    }, allUpdates);
  }, []);
};