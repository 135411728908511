import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetProductsResponse, GetTeamsResponse, updateProductStocksProductionQuantity } from 'api/actions';
import { ProductProductionStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { EditableImage, PreviewDrawer, ProductionStockUpdateLogsTable, useAlertSnackbar, UserCredentialsForm, useValidateMutation, validateUserCredentialsSchema } from 'components';
import { useCurrentUser } from 'contexts';
import { Formik } from 'formik';
import { getProductTotalProduceAmount } from 'helpers';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useEffect, useMemo, useState } from 'react';
import { ResourceForm } from 'types';
import { useProductsProductionStockUpdateLogs } from 'queries';

export type LogRunDrawerProps = {
  selectedTeams: GetTeamsResponse['data'];
  product: GetProductsResponse['data'][number] | null;
  onClose: () => void;
  stock?: GetProductsResponse['data'][number]['stocks'][string];
};

export const LogRunDrawer: React.FC<LogRunDrawerProps> = ({ selectedTeams, product, onClose, stock }) => {
  const me = useCurrentUser();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const [ step, setStep ] = useState<'credentials' | 'log'>('credentials');
  const [ userId, setUserId ] = useState<string | null>(null);
  const { data: updateLogs = [], isInitialLoading: updateLogsLoading } = useProductsProductionStockUpdateLogs({ productId: product?._id }, { enabled: !!product });

  const totalProduceAmount = useMemo(() => product ? getProductTotalProduceAmount(selectedTeams, product!) : 0, [ selectedTeams, product ]);

  const [ runAmount, setRunAmount ] = useState(6);

  const runAmountError = useMemo(() => {
    if (runAmount > 8) {
      return 'Maximum run amount is 8';
    }

    if (runAmount < 0) {
      return 'Minimum run amount is 0';
    }
  }, [ runAmount ]);

  const updateProductStocksProductionQuantityMutation = useMutation({
    mutationFn: async () => updateProductStocksProductionQuantity({
      stocks: [ {
        _id: stock!._id,
        updateAmount: runAmount,
        updatedQuantity: stock!.productionQuantity + runAmount,
        type: ProductProductionStockQuantityUpdateLogTypeEnum.production,
      } ],
      userId: userId!,
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.PRODUCTS);
      await queryClient.invalidateQueries(QUERY_KEY.PRODUCTS_PRODUCTION_STOCK_UPDATE_LOGS({ productId: product!._id }));
      snackbar.success(`Logged ${runAmount} units of ${product!.name}`);

      if ((getProductTotalProduceAmount(selectedTeams, product!) - runAmount) <= 0) {
        onClose();
      }

      setRunAmount(6);
    },
  });

  const validateMutation = useValidateMutation({
    onConfirm: (userId: string) => {
      setUserId(userId);
      setStep('log');
    }
  });

  const initialUserCredentialsState: ResourceForm['validateCredentials'] = {
    userId: me._id,
    password: '',
    pin: '',
    credentialType: 'pin',
  };

  useEffect(() => {
    setUserId(null);
    setStep('credentials');
  }, [ product?._id ]);

  return (
    <PreviewDrawer open={!!product}>
      <Box height="100%" display="flex" flexDirection="column">
        {step === 'credentials' && (
          <Box px={2} py={3}>
            <Formik
              initialValues={initialUserCredentialsState}
              onSubmit={values => validateMutation.mutateAsync(values)}
              validationSchema={validateUserCredentialsSchema}
            >
              {formik => (
                <>
                  <UserCredentialsForm firstStepText="Who is logging the run?"/>
                  <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained">Submit</Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        )}
        {(step === 'log' && product) ? (
          <>
            <Box display="flex" mx={2} py={1} gap={2} alignItems="flex-start">
              <Box sx={{ position: 'absolute', top: 8, right: 16 }}>
                <IconButton onClick={onClose}>
                  <CloseOutlined />
                </IconButton>
              </Box>
              <EditableImage
                width="130px"
                height="130px"
                onUpload={() => {}}
                imageUrl={product.imageUrl}
                modalTitle=""
                disabledEditable
              />
              <Box width="100%">
                <Typography variant="h5" mt={1}>
                  {product.name}
                </Typography>
                <Box mt={1} display="flex" alignItems="flex-end" gap={1}>
                  <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                    Total amount to produce:
                  </Typography>
                  <Typography variant="h6">
                    {updateProductStocksProductionQuantityMutation.isLoading ? (
                      <span>
                        <Skeleton width={20} />
                      </span>
                    ) : totalProduceAmount}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box p={2} bgcolor="background.default" flexGrow={1} display="flex" flexDirection="column" gap={2}>
              <Box bgcolor="background.paper" mx={-2}>
                <Divider />
                <Box bgcolor="primary.background">
                  <Box display="flex" flexDirection="column" gap={2} py={3} mx={3}>
                    <Typography>How many pieces are you running?</Typography>
                    <Box display="flex" alignItems="flex-start" gap={1}>
                      <TextField
                        size="small"
                        error={!!runAmountError}
                        helperText={runAmountError}
                        label="Run Amount"
                        type="number"
                        value={runAmount.toString()}
                        onChange={(e) => setRunAmount(Number(e.target.value))}
                        InputProps={{ inputProps: { min: 1, max: 8 }, readOnly: updateProductStocksProductionQuantityMutation.isLoading }}
                        sx={{ minWidth: 100 }}
                      />
                      <LoadingButton
                        disabled={!!runAmountError}
                        loadingPosition="end"
                        loading={updateProductStocksProductionQuantityMutation.isLoading}
                        onClick={() => updateProductStocksProductionQuantityMutation.mutateAsync()}
                        sx={{ pr: 4 }}
                      >
                        Log Run
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
              <Box flexGrow={1} overflow="auto">
                <ProductionStockUpdateLogsTable rows={updateLogs} loading={updateLogsLoading} sx={{ maxHeight: '600px' }} />
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </PreviewDrawer>
  );
};
