import { authAxiosRequest } from 'api/axios';
import { Team, User, InventoryBatch, InventoryBatchUpdate, InventoryBatchTypeEnum, InventoryBatchStatusEnum } from 'api/resources';
import { ApiAxiosError, CreateResourceResponse, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetInventoryBatchesResponse = {
  data: ResourceWithPopulated<InventoryBatch, {
    team: Team;
    user: PickFromResource<User, 'name'>;
    reviewUser?: PickFromResource<User, 'name'>;
    managerReviewUser?: PickFromResource<User, 'name'>;
    cancelUser?: PickFromResource<User, 'name'>;
  }>[];
};

export const getInventoryBatches = () => {
  return authAxiosRequest<GetInventoryBatchesResponse>('/api/inventory-batch');
};

export type GetInventoryBatchResponse = {
  data: GetInventoryBatchesResponse['data'][number];
};

export const getInventoryBatch = (id: string) => {
  return authAxiosRequest<GetInventoryBatchResponse>(`/api/inventory-batch/${id}`);
};

export type CreateInventoryBatchInput = {
  team: string;
  type?: InventoryBatchTypeEnum.overstockPicking;
};

export type CreateInventoryBatchErrorResponse = ApiAxiosError<'sameTeamPickingBatchExists'>;

export const createInventoryBatch = (input: CreateInventoryBatchInput) => {
  return authAxiosRequest<CreateResourceResponse>('/api/inventory-batch', { method: 'POST', data: input });
};

export const enum UpdateInventoryBatchStatusEnum {
  open = 'open',
  review = 'review',
  reviewComplete = 'reviewComplete',
  canceled = 'canceled',
  closed = 'closed',
}

export type UpdateInventoryBatchInput = Partial<{
  updates: InventoryBatchUpdate[];
  status: UpdateInventoryBatchStatusEnum;
}>;

export const updateInventoryBatch = (id: string, input: UpdateInventoryBatchInput) => {
  return authAxiosRequest<{ data: { status: InventoryBatchStatusEnum } }>(`/api/inventory-batch/${id}`, { method: 'PUT', data: input });
};