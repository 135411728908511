import { ApiResource } from './resources.types';

export type InventoryBatch = ApiResource<{
  name: string;
  type: InventoryBatchTypeEnum;
  status: InventoryBatchStatusEnum;

  team: string;
  user: string;
  reviewUser?: string;
  managerReviewUser?: string;
  cancelUser?: string;

  updates: InventoryBatchUpdate[];
  stockUpdatedAt: string;
}>;

export const enum InventoryBatchTypeEnum {
  picking = 'picking',
  overstockPicking = 'overstockPicking',
  production = 'production',
  damaged = 'damaged',
  productionDamaged = 'productionDamaged',
}

export const enum InventoryBatchStatusEnum {
  open = 'open',
  review = 'review',
  managerReview = 'managerReview',
  canceled = 'canceled',
  closed = 'closed',
}

export type InventoryBatchUpdate = {
  product: string;
  quantity: number;
  reviewQuantity?: number;
  managerReviewQuantity?: number;
};