
export type GetLogLiveSaleNoteParams = {
  shippingColorVinylProductNames: string[];
  resourceLabel: string;
  userName: string;
  customerNote?: string;
};

export const getLogLiveSaleNote = ({ shippingColorVinylProductNames, resourceLabel, userName, customerNote }: GetLogLiveSaleNoteParams) => {
  const note = `Sale made on ${resourceLabel} by ${userName}.
    ${shippingColorVinylProductNames.length > 0 ? `Products that require color vinyl: ${shippingColorVinylProductNames.join(', ')}.` : ''}
    ${customerNote ? `Customer note: ${customerNote}.` : ''}`.trim();

  return note;
};