import { SectionCard } from 'components/SectionCard';
import React from 'react';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { Box, Typography } from '@mui/material';
import { updateEvent } from 'api/actions';
import { CardContentLink, CardContentMarkdown } from 'components/CardContent';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { mixed, number, object, string } from 'yup';
import { EventSectionCardRowEditable } from '../components';
import { ElectricityForm, EventManagerForm, SpaceSizeForm, TeamForm, VehicleForm } from '../forms';
import { useEventContext } from '../event.context';
import { MarkdownInput, TextInput } from 'components/Inputs';
import { eventEnumHelpers, processFormValueUpdate, removeUnchanged } from 'helpers';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { EventBoothRequirementsForm } from '../forms/EventBoothRequirements.form';
import { UnknownEnum, yesNoUnknownSchema } from 'types';
import { EventBoothRequirements } from '../components/EventBoothRequirements.component';
import { RadioGroupYesNoBoolean } from 'components';
import { EventElectricityChoiceEnum } from 'api/resources';

export const AssignmentsSection = () => {
  const { event } = useEventContext();

  return (
    <SectionCard title="Assignments" id={EventPageSection.ASSIGNMENTS}>
      <EventSectionCardRowEditable
        title="Event Manager"
        rowId={EventPageSectionRowId.EVENT_MANAGER}
        formikProps={{
          initialValues: { eventManager: event.eventManager?._id ?? null },
          onSubmit: values => updateEvent(event._id, { eventManager: values.eventManager }),
          validationSchema: object({ eventManager: string().nullable().default(null) })
        }}
        form={<EventManagerForm />}
      >
        {event.eventManager?.name}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Team"
        rowId={EventPageSectionRowId.TEAM}
        formikProps={{
          initialValues: { team: event.team?._id ?? null, teamManager: event.teamManager?._id ?? null },
          onSubmit: values => updateEvent(event._id, { team: values.team, teamManager: values.teamManager }),
          validationSchema: object({
            team: string().nullable().default(null).when({
              is: () => Boolean(event?.salesCount),
              then: schema => schema.required('Team Required'),
            }),
            teamManager: string().nullable().default(null),
          })
        }}
        form={<TeamForm />}
      >
        <Box display="flex" alignItems="center">
          {event.team && <CardContentLink title={event.team.name} href={DYNAMIC_ROUTES.team.createLink({ teamId: event.team._id })} />}
          {event.teamManager && <><Typography variant="body2" color="text.secondary">{event.team && ', '}managed by&nbsp;</Typography><CardContentLink title={event.teamManager.name} href={DYNAMIC_ROUTES.user.createLink({ userId: event.teamManager._id })} /></>}
        </Box>
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Vehicle"
        rowId={EventPageSectionRowId.VEHICLE}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { vehicle: values.vehicle }),
          initialValues: { vehicle: event.vehicle?._id ?? '' },
          validationSchema: object({ vehicle: string().nullable().default(null) })
        }}
        form={<VehicleForm />}
      >
        {event.vehicle?.name}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Space Size"
        rowId={EventPageSectionRowId.SPACE_SIZE}
        formikProps={{
          initialValues: { spaceSize: event.spaceSize ?? '' },
          onSubmit: values => updateEvent(event._id, { spaceSize: processFormValueUpdate.string(values.spaceSize) }),
          validationSchema: object({ spaceSize: string().default('') })
        }}
        form={<SpaceSizeForm />}
      >
        {event.spaceSize}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Space Number"
        rowId={EventPageSectionRowId.SPACE_NUMBER}
        formikProps={{
          initialValues: { spaceNumber: event.spaceNumber ?? '' },
          onSubmit: values => updateEvent(event._id, { spaceNumber: processFormValueUpdate.string(values.spaceNumber) }),
          validationSchema: object({ spaceNumber: string().default('') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="spaceNumber"
            name="spaceNumber"
            label="Space Number"
            autoFocus
          />
        )}
      >
        {event.spaceNumber}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Booth requirements"
        rowId={EventPageSectionRowId.BOOTH_REQUIREMENTS}
        formikProps={{
          initialValues: {
            hasRequirements: yesNoEnumHelpers.yesNo.getEnumValue(event.boothRequirements?.hasRequirements),
            areTentsAllowed: yesNoEnumHelpers.yesNo.getEnumValue(event.boothRequirements?.areTentsAllowed),

            isWhiteTopRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.boothRequirements?.isWhiteTopRequired),
            isFlameRetardantRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.boothRequirements?.isFlameRetardantRequired),

            maximumHeight: event.boothRequirements?.maximumHeight ?? 0,
            isTrussAllowed: yesNoEnumHelpers.yesNo.getEnumValue(event.boothRequirements?.isTrussAllowed),

            notes: event.boothRequirements?.notes ?? '',
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              boothRequirements: {
                hasRequirements: updates.hasRequirements && yesNoEnumHelpers.yesNo.getValue(updates.hasRequirements),
                areTentsAllowed: updates.areTentsAllowed && yesNoEnumHelpers.yesNo.getValue(updates.areTentsAllowed),

                isWhiteTopRequired: updates.isWhiteTopRequired && yesNoEnumHelpers.yesNo.getValue(updates.isWhiteTopRequired),
                isFlameRetardantRequired: updates.isFlameRetardantRequired && yesNoEnumHelpers.yesNo.getValue(updates.isFlameRetardantRequired),

                maximumHeight: processFormValueUpdate.number(updates.maximumHeight),
                isTrussAllowed: updates.isTrussAllowed && yesNoEnumHelpers.yesNo.getValue(updates.isTrussAllowed),

                notes: processFormValueUpdate.string(updates.notes),
              }
            });
          },
          validationSchema: object({
            hasRequirements: yesNoUnknownSchema,
            areTentsAllowed: yesNoUnknownSchema,

            isWhiteTopRequired: yesNoUnknownSchema,
            isFlameRetardantRequired: yesNoUnknownSchema,

            maximumHeight: number().default(0),
            isTrussAllowed: yesNoUnknownSchema,

            notes: string().default(''),
          })
        }}
        form={<EventBoothRequirementsForm />}
      >
        <EventBoothRequirements />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Electricity"
        rowId={EventPageSectionRowId.ELECTRICITY}
        formikProps={{
          initialValues: {
            electricityWanted: yesNoEnumHelpers.yesNo.getEnumValue(event.electricityWanted),
            electricityAvailable: yesNoEnumHelpers.yesNo.getEnumValue(event.electricityAvailable),
            electricityFeeRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.electricityFee),
            electricGeneratorAllowed: yesNoEnumHelpers.yesNo.getEnumValue(event.electricGeneratorAllowed),
            electricityChoice: event.electricityChoice ?? UnknownEnum.unknown,
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              electricityWanted: processFormValueUpdate.yesNoUnknown(updates.electricityWanted),
              electricityAvailable: processFormValueUpdate.yesNoUnknown(updates.electricityAvailable),
              electricityFee: processFormValueUpdate.yesNoUnknown(updates.electricityFeeRequired),
              electricGeneratorAllowed: processFormValueUpdate.yesNoUnknown(updates.electricGeneratorAllowed),
              electricityChoice: processFormValueUpdate.enumWithUnknown(updates.electricityChoice),
            });
          },
          validationSchema: object({
            electricityWanted: yesNoUnknownSchema,
            electricityAvailable: yesNoUnknownSchema,
            electricityFeeRequired: yesNoUnknownSchema,
            electricGeneratorAllowed: yesNoUnknownSchema,
            electricityChoice: mixed<EventElectricityChoiceEnum | UnknownEnum>().oneOf(eventEnumHelpers.electricityChoice.enumValues)
          })
        }}
        form={<ElectricityForm />}
      >
        <>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" rowGap={2}>
            <Box>
              <Typography fontSize="small" color="text.secondary">Wanted</Typography>
              <Typography>{yesNoEnumHelpers.yesNo.getLabelForValue(event.electricityWanted)}</Typography>
            </Box>
            {event.electricityWanted && (
              <>
                <Box>
                  <Typography fontSize="small" color="text.secondary">Available</Typography>
                  <Typography>
                    {yesNoEnumHelpers.yesNo.getLabelForValue(event.electricityAvailable)}
                    {event.electricityFee !== undefined && <Typography component="span" variant="body2" color="text.secondary" ml={0.5}>({!event.electricityFee && 'no '}fee)</Typography>}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize="small" color="text.secondary">Generator</Typography>
                  <Typography>{yesNoEnumHelpers.yesNo.getLabelForValue(event.electricGeneratorAllowed)}</Typography>
                </Box>
              </>
            )}
          </Box>
          {event.electricityWanted && (
            <Box mt={1}>
              <Typography fontSize="small" color="text.secondary">Choice</Typography>
              <Typography>{eventEnumHelpers.electricityChoice.getLabel(event.electricityChoice)}</Typography>
            </Box>
          )}
        </>
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Notes"
        rowId={EventPageSectionRowId.NOTES}
        formikProps={{
          initialValues: { notes: event.notes ?? '' },
          onSubmit: values => updateEvent(event._id, { notes: processFormValueUpdate.string(values.notes) }),
          validationSchema: object({ notes: string().default('') })
        }}
        form={<MarkdownInput name="notes" />}
        disableTypography
        formFullWidth
      >
        <CardContentMarkdown markdown={event.notes} />
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="On Website"
        rowId={EventPageSectionRowId.ON_WEBSITE}
        formikProps={{
          initialValues: { onWebsite: yesNoEnumHelpers.yesNo.getEnumValue(event.onWebsite) },
          onSubmit: values => updateEvent(event._id, { onWebsite: processFormValueUpdate.yesNoUnknown(values.onWebsite) }),
          validationSchema: object({ onWebsite: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="onWebsite"
            label="Is Event added to the website?"
            includeUnknown
            row
          />
        )}
      >
        {yesNoEnumHelpers.yesNo.getLabelForValue(event.onWebsite)}
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};
