import { useState } from 'react';
import { InventoryTableEditable } from './components/InventoryTableEditable.component';
import { EditModeToggler, PageContentContainer, PageContentHeader, TableActionsBar } from 'components';
import { InventoryTable } from './components/InventoryTable.component';
import { useCurrentUser } from 'contexts';

export const InventoryAdjustments = () => {
  const me = useCurrentUser();
  const [ isEditMode, setIsEditMode ] = useState(false);

  return (
    <PageContentContainer header={<PageContentHeader title="Inventory Adjustments" />}>
      <TableActionsBar
        editModeToggler={me.isAdmin ? <EditModeToggler isEditMode={isEditMode} setIsEditMode={setIsEditMode} /> : undefined}
      />
      {isEditMode ? <InventoryTableEditable /> : <InventoryTable />}
    </PageContentContainer>
  );
};