import { Box } from '@mui/material';
import { AddressAutocomplete, TextInput } from 'components/Inputs';
import React from 'react';
import { formSx } from 'styles';

export type ShipmentFormProps = {
  size?: 'small' | 'medium';
};
export const ShipmentForm: React.FC<ShipmentFormProps> = ({ size }) => {
  return (
    <Box sx={formSx.formGroup}>
      <Box display="flex" gap={1}>
        <TextInput size={size} label="First Name *" name="firstName" fullWidth trim />
        <TextInput size={size} label="Last Name *" name="lastName" fullWidth trim />
      </Box>

      <AddressAutocomplete name="place" />

      <Box display="flex" gap={1} mb={3}>
        <TextInput size={size} label="Phone" name="phone" fullWidth trim />
        <TextInput size={size} label="Email *" name="email" fullWidth trim />
      </Box>

      <TextInput size={size} label="Notes" name="notes" fullWidth multiline rows={3} />
    </Box>
  );
};
