import { Alert, Autocomplete, Box, Skeleton, Stack, TextField } from '@mui/material';
import dayGridPlugin from '@fullcalendar/daygrid';
import { User, UserEmployeeRoleEnum } from 'api/resources';
import { EventWithUserColorCodeConfigArg, FilterFieldConfiguration, FullCalendar, FullCalendarEventContentContainer, ShowTheseFieldsEventContent, TableActionsBar, eventsCalendarEventAcceptanceStatusFilterConfiguration, eventsCalendarEventEventManagerFilterConfiguration, eventsCalendarEventParticipatioStatusFilterConfiguration, eventsCalendarEventStaffStatusFilterConfiguration, eventsCalendarEventTeamManagerFilterConfiguration, eventsCalendarEventUserStaffStatusFilterConfiguration, eventsShowTheseFieldsGroupEnumHelpers, useColorCode, useEventPreviewDrawerContext, useFilter, useSpanEvents } from 'components';
import { EventsManagementInfoPopper } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React, { useCallback, useContext, useMemo } from 'react';
import { useShowTheseFields } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/useShowTheseFields.hook';
import { EventsManagementPageTitle } from 'pages/EventsManagement/components/EventsManagementPageTitle.component';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { EventsCalendarEventExtendedProps, convertEventsForEventsCalendar } from 'components/FullCalendar/custom/helpers';
import { EventContentArg } from 'types';
import { EventColorCodeOptionType, eventColorCodeConfigHash } from './colorCode';
import { EventInput } from '@fullcalendar/react';
import { eventShowTheseFieldsUserStaffStatusConfig, eventShowTheseFieldsTypeSetupBreakdownProcedureConfig, eventShowTheseFieldsTeamConfig, eventShowTheseFieldsEventManagerConfig, eventShowTheseFieldsTeamManagerConfig, eventShowTheseFieldsSpaceSizeConfig, eventShowTheseFieldsDatesStatusConfig, eventShowTheseFieldsStaffStatusConfig, eventShowTheseFieldsStaffNeededConfig, eventShowTheseFieldsStaffListConfig, eventShowTheseFieldsLodgingRequiredConfig, eventShowTheseFieldsDistanceFromStudioConfig, eventShowTheseFieldsWorkDayLengthConfig } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/custom/show-these-fields-presets';
import { ResetAllActionContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/ResetAllActionContent.component';

const showTheseFieldsConfig = [
  eventShowTheseFieldsUserStaffStatusConfig,
  eventShowTheseFieldsTypeSetupBreakdownProcedureConfig,
  eventShowTheseFieldsTeamConfig,
  eventShowTheseFieldsEventManagerConfig,
  eventShowTheseFieldsTeamManagerConfig,
  eventShowTheseFieldsSpaceSizeConfig,
  eventShowTheseFieldsDatesStatusConfig,
  eventShowTheseFieldsStaffStatusConfig,
  eventShowTheseFieldsStaffNeededConfig,
  eventShowTheseFieldsStaffListConfig,
  eventShowTheseFieldsLodgingRequiredConfig,
  eventShowTheseFieldsDistanceFromStudioConfig,
  eventShowTheseFieldsWorkDayLengthConfig
];

const getFilterConfigurations = (user: User | null, users: User[], userLoading?: boolean): FilterFieldConfiguration<EventInput>[] => {
  return [
    eventsCalendarEventParticipatioStatusFilterConfiguration,
    eventsCalendarEventAcceptanceStatusFilterConfiguration,
    eventsCalendarEventStaffStatusFilterConfiguration,
    eventsCalendarEventEventManagerFilterConfiguration(users, userLoading),
    eventsCalendarEventTeamManagerFilterConfiguration(users, userLoading),
    eventsCalendarEventUserStaffStatusFilterConfiguration(user),
  ];
};

export const LogisticsStaffScheduleCalendar = () => {
  const nodeId = EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar;
  const { events, eventsLoading, users, usersLoading, selectedUser, setSelectedUser } = useContext(EventsManagementPageContext);
  const { modal: showTheseFieldsModal, sidebarActionConfig: showTheseFieldsSidebarActionConfig, showTheseFields, onResetShowTheseFields } = useShowTheseFields({
    id: nodeId,
    config: showTheseFieldsConfig,
    defaultShowTheseFields: [ 'userStaffStatus', 'typeSetupBreakdownProcedure' ],
    groupEnumHelpers: eventsShowTheseFieldsGroupEnumHelpers,
  });
  const userOptions = users.filter(user => user.employeeRoles.some(role => [ UserEmployeeRoleEnum.teamMember, UserEmployeeRoleEnum.juniorTeamMember, UserEmployeeRoleEnum.teamLead ].includes(role)));
  const { setOpenEvent } = useEventPreviewDrawerContext();

  const {
    spanEvents,
    setSpanEvents,
    sidebarActionConfig: spanEventsSidebarActionConfig
  } = useSpanEvents({ id: nodeId });

  const transformedEvents = useMemo(() => convertEventsForEventsCalendar(events, spanEvents), [ events, spanEvents ]);

  const handleChange = (_event: React.SyntheticEvent, newValue: User | null) => {
    setSelectedUser(newValue ?? null);
  };

  const filterFieldConfigurations = useMemo(() => getFilterConfigurations(selectedUser, users, usersLoading), [ selectedUser, users, usersLoading ]);
  const { modal: filterModal, filteredRows: filteredEvents, onResetFilters, sidebarActionConfig: filterSidebarActionConfig } = useFilter({
    rows: transformedEvents,
    localStorageId: EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar,
    filterConfigurations: filterFieldConfigurations,
  });

  const {
    colorCodeOption,
    setColorCodeOption,
    colorCodeModal,
    colorCodeSidebarActionConfig,
  } = useColorCode<EventColorCodeOptionType, EventWithUserColorCodeConfigArg>({
    id: EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar,
    defaultValue: 'userStaffStatus',
    colorCodeConfigHash: eventColorCodeConfigHash,
  });

  const getEventContent = useCallback((arg: EventContentArg<EventsCalendarEventExtendedProps>) => {
    const { event, eventDate, group } = arg.event.extendedProps;
    const backgroundFunc = colorCodeOption ? eventColorCodeConfigHash[colorCodeOption].getColorKeyConfig({ event, eventDate, user: selectedUser }).background : undefined;

    return (
      <FullCalendarEventContentContainer
        isStart={arg.isStart}
        isEnd={arg.isEnd}
        backgroundFunc={backgroundFunc ?? (theme => theme.palette.primary.main)}
      >
        <ShowTheseFieldsEventContent
          showTheseFieldsConfig={showTheseFieldsConfig}
          showTheseFields={showTheseFields}
          title={event.name}
          extra={{ event, user: selectedUser }}
          eventDate={eventDate}
          group={group}
        />
      </FullCalendarEventContentContainer>
    );
  }, [ colorCodeOption, showTheseFields, selectedUser ]);

  const calendarDisplay = useMemo(() => {
    if (eventsLoading) {
      return <Skeleton variant="rectangular" height="100%" width="100%" />;
    }

    const onResetAll = () => {
      setSpanEvents(false);
      onResetFilters();
      setColorCodeOption('userStaffStatus');
      onResetShowTheseFields();
    };

    return (
      <Box>
        <FullCalendar
          calendarsConfig={[
            {
              id: nodeId,
              plugins: [ dayGridPlugin ],
              height: 'auto',
              events: filteredEvents,
              initialView: 'dayGridMonth',
              headerToolbar: false,
              eventClick: (clickInfo) => setOpenEvent( clickInfo.event.extendedProps.event._id, 'scheduling'),
              eventContent: getEventContent,
              eventOrder: 'title'
            }
          ]}
          sidebarActionsConfig={[
            { content: <ResetAllActionContent onResetAll={onResetAll} /> },
            spanEventsSidebarActionConfig,
            filterSidebarActionConfig,
            showTheseFieldsSidebarActionConfig,
            colorCodeSidebarActionConfig,
          ]}
        />
      </Box>
    );
  }, [ eventsLoading, nodeId, filteredEvents, getEventContent, spanEventsSidebarActionConfig, filterSidebarActionConfig, showTheseFieldsSidebarActionConfig, colorCodeSidebarActionConfig, setSpanEvents, onResetFilters, setColorCodeOption, onResetShowTheseFields, setOpenEvent ]);

  return (
    <Stack gap={2}>
      <TableActionsBar
        leftActions={(
          <>
            <EventsManagementPageTitle nodeId={EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar} />
            <Autocomplete
              value={userOptions.find(_user => _user._id === selectedUser?._id)}
              onChange={handleChange}
              options={userOptions}
              loading={usersLoading}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              renderInput={(params) => <TextField {...params} label="Select a user" />}
              sx={{ width: 200 }}
              disableClearable
              size="small"
            />
          </>
        )}
        rightActions={(<EventsManagementInfoPopper tab={EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar} />)}
      />
      {showTheseFieldsModal}
      {colorCodeModal}
      {filterModal}
      <Box>
        {!selectedUser && <Box mb={2}><Alert severity="warning" variant="outlined">User is not selected</Alert></Box>}
        {calendarDisplay}
      </Box>
    </Stack>
  );
};
