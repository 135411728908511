import { Box, ToggleButton } from '@mui/material';
import { ProductsAutocomplete, TextInput, ToggleButtonGroup } from 'components';
import { getSelectOptionsFromEnumHelper, saleEnumHelpers } from 'helpers';
import React, { useEffect, useMemo } from 'react';
import { formSx } from 'styles';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { useFormikContext } from 'formik';
import { FrameEnum } from 'types';
import { EditSaleFormType } from './EditSale.modal';

export const EditSaleForm = () => {
  const { values, setFieldValue, touched } = useFormikContext<EditSaleFormType>();
  const { teamId } = useLoggingPortalContext();
  const frameOptions = useMemo(() => getSelectOptionsFromEnumHelper(saleEnumHelpers.frame), []);

  useEffect(() => {
    if (touched.frame && !touched.salePrice) {
      if (values.frame === FrameEnum.noFrame) {
        setFieldValue('salePrice', values.salePrice - 5);
      } else {
        setFieldValue('salePrice', values.salePrice + 5);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ values.frame ]);

  return (
    <Box sx={formSx.formGroup}>
      {/* teamId is should not be undefined when component renders */}
      {teamId && <ProductsAutocomplete name="product" label="Product" teamId={teamId} />}
      <TextInput name="salePrice" type="number" label="Sale Price" fullWidth />
      <ToggleButtonGroup label="Frame" name="frame" color="primary" exclusive fullWidth>
        {frameOptions.map(({ value, label }) => (
          <ToggleButton value={value} key={value}>{label}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
