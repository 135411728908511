import { styled, TextField, TextFieldProps, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';

export type TextFieldCellProps = Pick<GridRenderEditCellParams, 'id' | 'value' | 'field'> & Omit<TextFieldProps, 'id'>;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    mt: -10,
  },
}));

export const TextFieldCell: React.FC<TextFieldCellProps> = (props) => {
  const { id, value, field, error } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <StyledTooltip open={!!error} title={error}>
      <TextField
        error={!!error}
        value={value || ''}
        onChange={(e) => handleValueChange(e.target.value)}
        onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
          const inputElement = e.currentTarget.querySelector('input');

          if (inputElement) {
            inputElement.blur();
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: error ? (theme) => theme.palette.error.main : undefined,
            },
            '&:hover fieldset': {
              borderColor: error ? (theme) => theme.palette.error.dark : undefined,
            },
            '&.Mui-focused fieldset': {
              borderColor: error ? (theme) => theme.palette.error.dark : undefined,
            },
          },
          bgcolor: error ? (theme) => theme.palette.error.background : undefined,
          color: error ? (theme) => theme.palette.error.main : undefined,
        }}
      />
    </StyledTooltip>
  );
};
