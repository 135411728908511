import { Theme } from '@emotion/react';
import { Box, SxProps, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GetProductsProductionStockUpdateLogsResponse } from 'api/actions';
import { LoggedAtDateCell, TeamCell } from 'components/Table/Cells';
import { Table } from 'components/Table/Table.component';
import { getProductionStockUpdateLogDetails } from './helpers';
import { productStockUpdateLogEnumHelpers } from 'helpers/enums/stock-update-log-enums.helpers';

export type ProductionStockUpdateLogsTableProps = {
  rows: GetProductsProductionStockUpdateLogsResponse['data'];
  loading: boolean;
  sx?: SxProps<Theme>;
};

const columns: GridColDef<GetProductsProductionStockUpdateLogsResponse['data'][number]>[] = [
  {
    field: 'createdAt',
    headerName: 'Date',
    valueGetter: ({ value }) => new Date(value),
    type: 'date',
    renderCell: LoggedAtDateCell,
    width: 200
  },
  {
    field: 'user',
    headerName: 'User',
    renderCell: ({ row }) => row.user.name,
    width: 115,
  },
  {
    field: 'product',
    headerName: 'Product',
    valueGetter: ({ row }) => row.stock.product.name,
    width: 200
  },
  {
    field: 'team',
    headerName: 'Team',
    valueGetter: ({ row }) => row.stock.team.name,
    renderCell: ({ row }) => <TeamCell team={row.stock.team} />,
  },
  {
    field: 'previousQuantity',
    headerName: 'Previous Quantity',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'newQuantity',
    headerName: 'New Quantity',
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'updateAmount',
    headerName: 'Update Amount',
    valueGetter: (params) => params.row.newQuantity - params.row.previousQuantity,
    renderCell: (params) => {
      const isPositive = params.value > 0;

      return (
        <Typography
          sx={{
            color: (theme) => theme.palette[isPositive ? 'success' : 'error'].main
          }}
        >
          {isPositive ? '+' : '-'}{Math.abs(params.value)}
        </Typography>
      );
    },
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'type',
    headerName: 'Update Type',
    valueFormatter: ({ value }) => productStockUpdateLogEnumHelpers.productionStockQuantityUpdateLogType.getLabel(value),
    width: 150
  },
];

export const ProductionStockUpdateLogsTable: React.FC<ProductionStockUpdateLogsTableProps> = ({ rows, loading, sx }) => {
  return (
    <Table
      getDetailPanelContent={({ row }: GridRowParams<GetProductsProductionStockUpdateLogsResponse['data'][number]>) => {

        return (
          <Box
            sx={(theme) => ({ background: theme.palette.grey[50] })}
            p={2}
            pl="58px"
          >
            <Typography variant="body2">{getProductionStockUpdateLogDetails(row)}</Typography>
          </Box>
        );
      }}
      getDetailPanelHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'createdAt', sort: 'desc' }
          ],
        },
      }}
      slotProps={{
        toolbar: {
          searchOnly: true
        }
      }}
      getRowId={(x) => x._id}
      columns={columns}
      rows={rows}
      loading={loading}
      emptyMessage="No Update Logs Were Found"
      disableRowSelectionOnClick
      sx={sx}
    />
  );
};