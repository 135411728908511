import { useMemo, useState } from 'react';
import { InventoryEdits, InventoryRow, InventoryTableEditableMode, InventoryTableTransferConfig, StandardInventoryTableFilters } from './types';
import { UnknownEnum } from 'types';
import { GetTeamsResponse } from 'api/actions';
import { ProductStock } from 'api/resources';
import { getProductTeamStockQuantity } from './helpers';
import { getProductTeamBaseline } from 'helpers';

export type UseInventoryTableSettingsArgsBase = {
  initialTeamIds?: string[];
  initialSettingsModalOpen?: boolean;
  initialShowStockMeta?: boolean;
  initialShowStockProduction?: boolean;
  initialMode?: InventoryTableEditableMode;
  initialStandardFilters?: StandardInventoryTableFilters[];
  initialTransferConfig?: InventoryTableTransferConfig | null;
  getDisplayStandardFilters?: (mode: InventoryTableEditableMode | UnknownEnum) => StandardInventoryTableFilters[];
  rowFilter?: (row: InventoryRow) => boolean;
};
export type UseInventoryTableSettingsArgs = UseInventoryTableSettingsArgsBase & {
  products: InventoryRow[];
  teams: GetTeamsResponse['data'];
  inventoryEdits: InventoryEdits;
  getStockQuantity: (stocl: ProductStock) => number;
};

export const useInventoryTableSettings = ({
  products,
  teams,
  inventoryEdits,
  getStockQuantity,
  initialTeamIds = [],
  initialSettingsModalOpen = false,
  initialShowStockMeta = true,
  initialShowStockProduction = false,
  initialTransferConfig = null,
  initialMode,
  initialStandardFilters = [],
  getDisplayStandardFilters,
  rowFilter,
}: UseInventoryTableSettingsArgs) => {
  const [ teamIds, setTeamIds ] = useState<string[]>(initialTeamIds ?? []);
  const [ settingsModalOpen, setSettingsModalOpen ] = useState(initialSettingsModalOpen);
  const [ showStockMeta, setShowStockMeta ] = useState(initialShowStockMeta);
  const [ showStockProduction, setShowStockProduction ] = useState(initialShowStockProduction);
  const [ transferConfig, setTransferConfig ] = useState<InventoryTableTransferConfig | null>(initialTransferConfig ?? null);
  const [ mode, setMode ] = useState<InventoryTableEditableMode | UnknownEnum>(initialMode ?? UnknownEnum.unknown);
  const isSetOperation = mode === InventoryTableEditableMode.set;
  const isTransferOperation = mode === InventoryTableEditableMode.transfer;

  const [ standardFilters, setStandardFilters ] = useState<StandardInventoryTableFilters[]>(initialStandardFilters ?? []);
  const displayStandardFilters = useMemo(() => getDisplayStandardFilters?.(mode) ?? [], [ mode, getDisplayStandardFilters ]);

  const filteredRows = useMemo(() => {
    return products.filter(product => {
      // never hide products with edits
      if(product._id in inventoryEdits) {
        return true;
      }

      if (displayStandardFilters.includes('onlyShowWithProduction') && standardFilters.includes('onlyShowWithProduction')) {
        if (!teamIds.some(teamId => !!product.stocks[teamId].productionQuantity)) {
          return false;
        }
      }

      if (displayStandardFilters.includes('showNotAvailableAtEvents') && (!standardFilters.includes('showNotAvailableAtEvents') || isTransferOperation)) {
        if (!product.availability?.events) {
          return false;
        }
      }

      if (displayStandardFilters.includes('onlyShowEdits') && standardFilters.includes('onlyShowEdits')) {
        if (!inventoryEdits[product._id]) {
          return false;
        }
      }

      if (transferConfig && isTransferOperation) {
        const outTeam = teams.filter(team => team._id === transferConfig.out)[0];
        const inTeam = teams.filter(team => team._id === transferConfig.in)[0];
        const outStockQuantity = getProductTeamStockQuantity(product, transferConfig.out, getStockQuantity);
        const inStockQuantity = getProductTeamStockQuantity(product, transferConfig.in, getStockQuantity);

        if (transferConfig && displayStandardFilters.includes('showNoOutStock') && !standardFilters.includes('showNoOutStock')) {
          if (outStockQuantity === 0) {
            return false;
          }
        }

        if (displayStandardFilters.includes('showOutNotOverstocked') && !standardFilters.includes('showOutNotOverstocked')) {
          const baseline = getProductTeamBaseline(product, outTeam);

          if (baseline >= outStockQuantity) {
            return false;
          }
        }

        if (displayStandardFilters.includes('showInNotNeeded') && !standardFilters.includes('showInNotNeeded')) {
          const baseline = getProductTeamBaseline(product, inTeam);

          if (baseline <= inStockQuantity) {
            return false;
          }
        }
      }

      if (rowFilter) {
        return rowFilter(product);
      }

      return true;
    });
  }, [ products, inventoryEdits, displayStandardFilters, standardFilters, isTransferOperation, transferConfig, rowFilter, teamIds, teams, getStockQuantity ]);


  return {
    settingsModalOpen,
    setSettingsModalOpen,
    mode,
    setMode,
    isSetOperation,
    isTransferOperation,
    showStockMeta,
    setShowStockMeta,
    showStockProduction,
    setShowStockProduction,
    transferConfig,
    setTransferConfig,
    teamIds,
    setTeamIds,
    standardFilters,
    setStandardFilters,
    displayStandardFilters,
    filteredRows,
  };
};