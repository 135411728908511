import { Tab, Tabs } from '@mui/material';
import { PageContentContainer, PageContentHeader } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { ProductsPageContextProvider } from 'contexts';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const Production = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <ProductsPageContextProvider waitForTeams waitForProducts>
      <PageContentContainer
        header={(
          <PageContentHeader
            title="Production"
            tabs={(
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={pathname}
                onChange={(_e, value) => navigate(value)}
                allowScrollButtonsMobile
              >
                <Tab value={ROUTING_CONFIG.productionSchedule} label="Schedule" />
                <Tab value={ROUTING_CONFIG.inProduction} label="In Production" />
                <Tab value={ROUTING_CONFIG.inventoryBatches} label="Batches" />
                <Tab value={ROUTING_CONFIG.productUpdateLogs} label="Logs" />
              </Tabs>
            )}
          />
        )}
      >
        <Outlet />
      </PageContentContainer>
    </ProductsPageContextProvider>
  );
};